import { model } from "cosmonaut";
import { url } from "../common/url";
import { getIex } from "./get-iex";

export type EquityListItem = {
  symbol: string;
  name: string;
};

export const EquityListModel = model<EquityListItem[]>(
  () => getIex(url`/ref-data/symbols`),
  {
    key: "EquityList",
    maxAge: 0,
    refreshAge: "1w",
  }
);
