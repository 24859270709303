import React, { FC, useRef, useLayoutEffect, useState, useEffect } from "react";

export const ExpandIn: FC<{ isOpen: boolean; className?: string }> = ({
  isOpen,
  className,
  children,
}) => {
  const el = useRef<HTMLDivElement>(null);
  const [isRendered, setIsRendered] = useState(isOpen);

  useLayoutEffect(() => {
    if (!el.current) {
      return;
    }
    if (!isOpen) {
      el.current.style.height = "0";
      return;
    }
    el.current.style.height = "auto";
    const targetHeight = el.current.getBoundingClientRect().height;
    el.current.style.height = "0";
    setTimeout(() => {
      if (el.current) {
        el.current.style.height = targetHeight + "px";
      }
    });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isRendered) {
      setIsRendered(true);
    }
    if (!isOpen && isRendered) {
      setTimeout(() => {
        setIsRendered(false);
      }, 200);
    }
  }, [isOpen, isRendered]);

  return (
    <div
      ref={el}
      className={className}
      style={{
        overflow: "hidden",
        transition: "200ms",
        transitionTimingFunction: isOpen ? "ease-out" : "ease-in",
      }}
    >
      {isOpen || isRendered ? children : null}
    </div>
  );
};
