import { url } from "../common/url";
import { model } from "cosmonaut";
import { getIex } from "./get-iex";

type ApiIncomeStatement = {
  reportDate: string; // YYYY-MM-DD
  fiscalDate: string; // YYYY-MM-DD, last day of reporting period
  fiscalQuarter: number;
  fiscalYear: number;
  currency: string;
  totalRevenue: number;
  netIncome: number;
  netIncomeBasic: number; // as used in EPS
};

export type IncomeStatement = ApiIncomeStatement & { symbol: string };

export const IncomeStatementsModel = model<
  IncomeStatement[],
  { symbol: string }
>(
  async ({ symbol }) => {
    const statements = await getIex<{ income: ApiIncomeStatement[] }>(
      url`/stock/${symbol}/income?period=quarter&last=8`
    );
    return statements.income?.map((x) => ({ ...x, symbol })) ?? [];
  },
  {
    key: "QuarterlyIncomeStatements",
    refreshAge: "4w",
  }
);
