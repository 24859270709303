import { Transaction } from "./transaction";
import { Instrument } from "./instrument";
import { pickBy, memoize, Dictionary, groupBy, mapValues } from "lodash";
import { Holding, getHoldingMap } from "./holding";
import { AccountData, AccountRecord, Account } from "./account";
import {
  AccountId,
  AssetId,
  InstrumentId,
  isInstrument,
  StockspotId,
  toIdString,
  toNewIdString,
} from "../market/asset-id";

export class Portfolio {
  data: PortfolioData;
  holdingMap: Dictionary<Holding>;
  accounts: Dictionary<Account>;

  constructor(data: PortfolioData) {
    this.data = data;
    this.holdingMap = getHoldingMap(data.transactions);
    const accountRecordsMap = groupBy(
      data.accountRecords,
      (record) => record.accountId
    );
    this.accounts = mapValues(
      data.accounts,
      (account) => new Account(account, accountRecordsMap[account.id])
    );
  }

  get id() {
    return this.data.id;
  }

  get instrumentIdStrings(): string[] {
    return Array.from(new Set(this.getHoldings().map(({ symbol }) => symbol)));
  }

  get instrumentIds(): InstrumentId[] {
    return this.instrumentIdStrings
      .map((idString) => AssetId(idString) as InstrumentId)
      .filter(isInstrument);
  }

  get instrumentIdsInclStockspot(): (InstrumentId | StockspotId)[] {
    return this.instrumentIdStrings.map(
      (idString) => AssetId(idString) as InstrumentId | StockspotId
    );
  }

  get accountIds(): AccountId[] {
    return Object.keys(this.accounts).map((idString) => AccountId(idString));
  }

  getAsset(id: AccountId): Account | null;
  getAsset(id: InstrumentId | StockspotId): Instrument | null;
  getAsset(id: string | AssetId): Account | Instrument | null;
  getAsset(id: string | AssetId) {
    const _id = typeof id === "string" ? AssetId(id) : id;
    return isInstrument(_id) || _id.type === "stockspot"
      ? this._getInstrument(_id)
      : this.getAccount(_id.symbol);
  }

  _getInstrument = memoize(
    (id: InstrumentId | StockspotId): Instrument | null => {
      const idString = toNewIdString(id);
      const assetHoldings = this.getHoldings().filter(
        (holding) => holding.symbol === idString || holding.symbol === id.symbol
      );
      const assetTransactions = this.getTransactions().filter(
        (t) => t.symbol === idString || t.symbol === id.symbol
      );
      if (assetHoldings.length === 0) {
        return null;
      }
      return new Instrument(assetHoldings, assetTransactions);
    },
    (instrumentId) => toNewIdString(instrumentId)
  );

  getTransaction(transactionId: string): Transaction {
    return this.data.transactions[transactionId];
  }

  getTransactions(): Transaction[] {
    return Object.values(this.data.transactions);
  }

  getHolding(transactionId: string): Holding {
    const t = this.getTransaction(transactionId);
    return t.type === "buy" ? this.holdingMap[t.id] : this.holdingMap[t.refId];
  }

  getHoldings = memoize(() => {
    return Object.values(this.holdingMap);
  });

  getEarliestHolding = memoize(() => {
    return this.getHoldings().reduce((earliest, holding) =>
      earliest.buy.date < holding.buy.date ? earliest : holding
    );
  });

  getAccount(accountId: string): Account {
    return this.accounts[accountId];
  }

  filterByTags(tags: Set<string>, exclude: boolean = false): Portfolio {
    if (tags.size === 0) {
      return this;
    }
    const transactions = this.data.transactions;
    const accounts = this.data.accounts;
    return new Portfolio({
      ...this.data,
      accounts: pickBy(accounts, (account) => {
        // Accounts don't have tags for now
        return exclude;
      }),
      transactions: pickBy(transactions, (t) => {
        const root = t.type === "buy" ? t : transactions[t.refId];
        const hasTag = root.tags.some((tag) => tags.has(tag));
        return exclude ? !hasTag : hasTag;
      }),
    });
  }

  filterByAsset = memoize((id: AssetId | string): Portfolio => {
    const info = typeof id === "string" ? AssetId(id) : id;
    return new Portfolio({
      ...this.data,
      accounts:
        info.type === "account"
          ? { [info.symbol]: this.data.accounts[info.symbol] }
          : {},
      transactions:
        info.type === "stock" || info.type === "crypto"
          ? pickBy(this.data.transactions, (t) => {
              return (
                t.symbol === toIdString(info) ||
                t.symbol === toNewIdString(info)
              );
            })
          : {},
    });
  });
}

export type PortfolioData = {
  id: string;
  roles: Dictionary<Role>;
  transactions: Dictionary<Transaction>;
  accounts: Dictionary<AccountData>;
  accountRecords: Dictionary<AccountRecord>;
};

export type Role = "owner";
