import { useUid } from "./authenticate";
import { Portfolio } from "../portfolio/portfolio";
import { useObservable } from "../common/use-observable";
import { portfolioCache } from "../portfolio/portfolio-cache";
import { useContainer } from "unstated-next";
import { FiltersContainer } from "./filters";
import { useMemo } from "react";

export const usePortfolio = (): Portfolio => {
  const uid = useUid();
  const portfolio = useObservable(portfolioCache.get$(uid));
  const { filters, shouldExclude } = useContainer(FiltersContainer);
  return useMemo(() => {
    return portfolio.filterByTags(filters, shouldExclude);
  }, [portfolio, filters, shouldExclude]);
};
