/* eslint-disable @typescript-eslint/no-redeclare */

export type AssetId = InstrumentId | AccountId | StockspotId;

export type InstrumentId = StockId | CryptoId;

export type StockId = {
  type: "stock";
  symbol: string;
  key: string;
};

export type CryptoId = {
  type: "crypto";
  symbol: string;
  key: string;
};

export type AccountId = {
  type: "account";
  symbol: string;
  key: string;
};

export type StockspotId = {
  type: "stockspot";
  symbol: string;
  key: string;
};

export function AssetId(s: string): AssetId {
  const parts = s.split(":");
  if (parts.length === 1) {
    return {
      type: "stock",
      symbol: parts[0],
      key: `stock:${parts[0]}`,
    };
  }
  if (
    parts.length === 2 &&
    ["stock", "crypto", "account", "stockspot"].includes(parts[0])
  ) {
    return {
      type: parts[0] as AssetId["type"],
      symbol: parts[1],
      key: `${parts[0]}:${parts[1]}`,
    };
  }
  throw new Error(`Unrecognized asset id: ${s}`);
}

export function StockId(symbol: string): StockId {
  return {
    type: "stock",
    symbol,
    key: `stock:${symbol}`,
  };
}

export function CryptoId(symbol: string): CryptoId {
  return {
    type: "crypto",
    symbol,
    key: `crypto:${symbol}`,
  };
}

export function AccountId(symbol: string): AccountId {
  return {
    type: "account",
    symbol,
    key: `account:${symbol}`,
  };
}

export function toIdString(info: AssetId): string {
  if (info.type === "stock") {
    return info.symbol;
  }
  return `${info.type}:${info.symbol}`;
}

export function toNewIdString(info: AssetId): string {
  return `${info.type}:${info.symbol}`;
}

export function isInstrument(id: AssetId): id is InstrumentId {
  return id.type === "stock" || id.type === "crypto";
}

export function isSameAsset(id1: AssetId, id2: AssetId): boolean {
  return id1.type === id2.type && id1.symbol === id2.symbol;
}
