import React from "react";
import { BreakdownView } from "./breakdown";
import { SaleSummary } from "./sale-receipt";
import { getCapitalGain } from "./us-tax";

export function CapitalGainBreakdown({
  saleSummaries,
}: {
  saleSummaries: SaleSummary[];
}) {
  const capitalGain = getCapitalGain(saleSummaries);

  return (
    <BreakdownView
      breakdown={[
        { name: "Net Capital Gain", value: capitalGain.netCapitalGain },
        { name: "Net Short Term", value: capitalGain.netShortTerm },
        { name: "Net Long Term", value: capitalGain.netLongTerm },
      ]}
    />
  );
}
