import React, { ReactNode } from "react";
import { extent } from "d3";
import { scaleLinear } from "d3";
import { line } from "d3";
import { Size } from "./size";
import { flatten } from "lodash";

export type Point = {
  x: number;
  y: number;
};

export type TPadding = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

function Padding(padding: number): TPadding {
  return {
    top: padding,
    bottom: padding,
    left: padding,
    right: padding,
  };
}

export function Chart<T extends Array<Point | Point[]>>({
  data,
  padding = 0,
  spans = [],
  children = () => null,
}: {
  data: T;
  padding?: number | Partial<TPadding>;
  spans?: Partial<Point>[];
  children?: (args: {
    scaled: T;
    xScale: (x: number) => number;
    yScale: (y: number) => number;
    line: (data: Point[]) => string | undefined;
    height: number;
    width: number;
  }) => ReactNode;
}) {
  const xExtent = extent(spans.concat(flatten(data)), (d) => d.x);
  const yExtent = extent(spans.concat(flatten(data)), (d) => d.y);
  const _padding: TPadding =
    typeof padding === "number"
      ? Padding(padding)
      : Object.assign({ top: 0, bottom: 0, left: 0, right: 0 }, padding);

  return (
    <Size>
      {({ height, width }) => {
        const xScale = scaleLinear()
          .domain([xExtent[0] ?? 0, xExtent[1] ?? 1])
          .range([_padding.left, width - _padding.right]);
        const yScale = scaleLinear()
          .domain([yExtent[0] ?? 0, yExtent[1] ?? 1])
          .range([height - _padding.bottom, _padding.top]);
        const _line = line<Point>()
          .x((d) => d.x)
          .y((d) => d.y);
        const rescale = (point: Point) => ({
          ...point,
          x: xScale(point.x) ?? 0,
          y: yScale(point.y) ?? 0,
        });

        return (
          <svg height={height} width={width}>
            {children({
              scaled: data.map((item) =>
                Array.isArray(item) ? item.map(rescale) : rescale(item)
              ) as T,
              xScale: (x) => xScale(x) ?? 0,
              yScale: (y) => yScale(y) ?? 0,
              line: (data) => _line(data) ?? undefined,
              height,
              width,
            })}
          </svg>
        );
      }}
    </Size>
  );
}
