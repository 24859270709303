export type SplitData = { date: string; from: number; to: number };

export const SPLITS: Record<string, SplitData[]> = {
  "stock:TSLA": [
    { date: "2020-08-31", from: 1, to: 5 },
    { date: "2022-08-25", from: 1, to: 3 },
  ],
  "stockspot:GOLD": [{ date: "2022-06-08", from: 1, to: 10 }],
  "stockspot:IOO": [{ date: "2018-05-01", from: 1, to: 2 }],
};
