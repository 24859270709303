// Tax rates assume max tax bracket

// Applies on income >$1m
export const CA_MENTAL_HEALTH_TAX = 0.01;

export const CA_TAX_RATE = 0.123;

// Net income investment tax, applies to investment income >$250k
// (capital gains, dividends, rental)
// Cannot be offset by foreign tax credits.
export const FED_NIIT_RATE = 0.038;

export const FED_TAX_RATE = {
  SHORT: 0.37,
  LONG: 0.2,
};

export const AU_TAX_RATE = 0.45;
