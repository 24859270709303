import { css, cx } from "emotion";
import React, { ReactNode } from "react";

const styles = css`
  background: #515965;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 5px 30px 10px;
  overflow: hidden;

  > header {
    background: #404958;
    border-radius: 12px 12px 0 0;
  }
`;

export type Props = {
  className?: string;
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
};

export function Card({ className, header, children, footer }: Props) {
  return (
    <section className={cx(styles, className)}>
      {header != null && <header>{header}</header>}
      <main>{children}</main>
      {footer != null && <footer>{footer}</footer>}
    </section>
  );
}
