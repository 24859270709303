import { AccountId } from "../market/asset-id";
import { Currency } from "../market/money";

export type AccountData = {
  id: string;
  name: string;
  currency: Currency;
  tags: string[];
};

export type AccountRecord = {
  id: string;
  accountId: string;
  date: string;
  value: number;
};

export class Account {
  _id: string;
  name: string;
  currency: Currency;
  value: number;
  tags: string[];
  records: AccountRecord[];

  constructor(info: AccountData, records: AccountRecord[] = []) {
    const sortedRecords = records.sort((a, b) => {
      return b.date.localeCompare(a.date);
    });
    this._id = info.id;
    this.name = info.name;
    this.currency = info.currency;
    this.value = sortedRecords.length ? sortedRecords[0].value : 0;
    this.tags = info.tags;
    this.records = sortedRecords;
  }

  get id(): AccountId {
    return AccountId(this._id);
  }
}
