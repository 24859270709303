import { withRetries } from "./with-retries";

export const jsonFetch = <T>(...args: Parameters<typeof fetch>): Promise<T> =>
  withRetries(() =>
    fetch(...args)
      .then((value) => {
        if (value.status !== 200) {
          throw value;
        }
        return value;
      })
      .then((value) => value.json())
  );
