import { css } from "emotion";
import React, { SetStateAction } from "react";
import { Dispatch } from "react";
import { ToggleList, ToggleButton } from "../../toggle-button";
import { getTaxYear, TaxCountry, TaxPeriod } from "./tax-period";

const styles = css`
  padding: 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px 24px;
  align-items: center;
  font-size: 18px;
`;

export function TaxPeriodSelector({
  taxPeriod,
  setTaxPeriod,
}: {
  taxPeriod: TaxPeriod;
  setTaxPeriod: Dispatch<SetStateAction<TaxPeriod>>;
}) {
  return (
    <div className={styles}>
      <label>Country</label>
      <ToggleList>
        {["US", "AU"].map((country) => {
          return (
            <ToggleButton
              key={country}
              isActive={taxPeriod.taxCountry === country}
              onClick={() =>
                setTaxPeriod((prev) => ({
                  ...prev,
                  taxCountry: country as TaxCountry,
                }))
              }
            >
              {country}
            </ToggleButton>
          );
        })}
      </ToggleList>
      <label>Tax Year</label>
      <ToggleList>
        {[0, -1, -2, -3].map((offset) => {
          return (
            <ToggleButton
              key={offset}
              isActive={taxPeriod.taxYearOffset === offset}
              onClick={() =>
                setTaxPeriod((prev) => ({ ...prev, taxYearOffset: offset }))
              }
            >
              {String(
                getTaxYear({ ...taxPeriod, taxYearOffset: offset })
              ).substring(2)}
            </ToggleButton>
          );
        })}
      </ToggleList>
    </div>
  );
}
