import React, { useMemo } from "react";
import { Chart, Point } from "../../common/chart";
import { withSuspense } from "../../common/with-suspense";
import {
  HistoricalQuote,
  HistoricalQuotes,
} from "../../market/historical-quotes";
import { useModel } from "cosmonaut";
import { useDelay } from "../use-delay";
import { InstrumentId, StockId } from "../../market/asset-id";
import { sub } from "date-fns";
import { formatNumber } from "toolbelt";

const CHART_HEIGHT = 200;

const placeholder = (
  <div className="v-grid-small" style={{ marginTop: "var(--padding)" }}>
    <div
      style={{
        height: CHART_HEIGHT,
      }}
    />
  </div>
);

export type PriceChartRange = "1d" | "1m" | "1y";

export const PriceChart = withSuspense(
  placeholder,
  function PriceChart({
    id,
    range,
  }: {
    id: InstrumentId;
    range: PriceChartRange;
  }) {
    const symbol = id.key;
    const [allHistoricalQuotes, allBenchmarkHistoricalQuotes] = useModel([
      HistoricalQuotes({ id, range: "1y" }),
      HistoricalQuotes({ id: StockId("SPY"), range: "1y" }),
    ]);
    const startDate = useMemo(() => {
      const latestDate =
        allHistoricalQuotes[allHistoricalQuotes.length - 1].date;
      return range === "1m" ? sub(latestDate, { months: 1 }) : null;
    }, [allHistoricalQuotes, range]);
    const endDate = useMemo(() => {
      const lastQuote = allHistoricalQuotes[allHistoricalQuotes.length - 1];
      const lastBenchmarkQuote =
        allBenchmarkHistoricalQuotes[allBenchmarkHistoricalQuotes.length - 1];
      return lastQuote.date > lastBenchmarkQuote.date
        ? lastQuote.date
        : lastBenchmarkQuote.date;
    }, [allBenchmarkHistoricalQuotes, allHistoricalQuotes]);
    const historicalQuotes = useMemo(() => {
      return applyRange(allHistoricalQuotes, startDate, endDate);
    }, [allHistoricalQuotes, startDate, endDate]);
    const benchmarkHistoricalQuotes = useMemo(() => {
      return applyRange(allBenchmarkHistoricalQuotes, startDate, endDate);
    }, [allBenchmarkHistoricalQuotes, startDate, endDate]);

    const points = useMemo(() => {
      return historicalQuotes.map((quote) => ({
        x: new Date(quote.date).getTime(),
        y: quote.close,
      }));
    }, [historicalQuotes]);
    const minPoint = useMemo(() => {
      return points.reduce((minPoint, point) => {
        return minPoint.y < point.y ? minPoint : point;
      });
    }, [points]);
    const maxPoint = useMemo(() => {
      return points.reduce((maxPoint, point) => {
        return maxPoint.y > point.y ? maxPoint : point;
      });
    }, [points]);
    const benchmarkPoints = useMemo(() => {
      const multiplier =
        historicalQuotes[0].close / benchmarkHistoricalQuotes[0].close;
      return benchmarkHistoricalQuotes.map((quote) => ({
        x: new Date(quote.date).getTime(),
        y: quote.close * multiplier,
      }));
    }, [historicalQuotes, benchmarkHistoricalQuotes]);

    if (useDelay(symbol, 250)) {
      return placeholder;
    }

    return (
      <div className="v-grid-small" style={{ marginTop: "var(--padding)" }}>
        <div
          className="animate-in"
          style={{
            height: CHART_HEIGHT,
          }}
        >
          <Chart
            data={[benchmarkPoints, points]}
            padding={{ top: 24, bottom: 24 }}
          >
            {({ scaled, line, yScale, width }) => {
              function renderHighLowMarker(type: "high" | "low", point: Point) {
                return (
                  <>
                    <line
                      x1={0}
                      y1={yScale(point.y)}
                      x2={width}
                      y2={yScale(point.y)}
                      stroke="var(--color-3)"
                      strokeDasharray="4px 8px"
                    />
                    <text
                      x={width - 24}
                      y={yScale(point.y)}
                      dy={type === "high" ? -12 : 24}
                      fontSize="var(--font-size-xsmall)"
                      fill="rgba(255, 255, 255, 0.5)"
                      textAnchor="end"
                    >
                      {formatNumber(point.y, { prefix: "$" })}
                    </text>
                  </>
                );
              }

              return (
                <>
                  {/* Benchmark */}
                  <path
                    d={line(scaled[0])}
                    fill="none"
                    stroke="var(--color-2)"
                    strokeWidth={1}
                  />
                  {/* Price */}
                  <path
                    d={line(scaled[1])}
                    fill="none"
                    stroke="var(--color-brand)"
                    strokeWidth={1}
                  />
                  {/* High and low lines */}
                  {minPoint && renderHighLowMarker("low", minPoint)}
                  {maxPoint && renderHighLowMarker("high", maxPoint)}
                </>
              );
            }}
          </Chart>
        </div>
      </div>
    );
  }
);

function applyRange(
  historicalQuotes: HistoricalQuote[],
  startDate: Date | null,
  endDate: Date
): HistoricalQuote[] {
  const withLimit = startDate
    ? historicalQuotes.filter((historical) => historical.date > startDate)
    : historicalQuotes;
  const lastQuote = withLimit[withLimit.length - 1];
  return lastQuote.date.getTime() === endDate.getTime()
    ? withLimit
    : withLimit.concat({
        date: endDate,
        close: lastQuote.close,
      });
}
