import React, { FC } from "react";
import { FiSliders } from "react-icons/fi";
import { useDialog } from "./use-dialog";
import { SettingsForm } from "./settings-form";

export const SettingsButton: FC = () => {
  const [dialog, openDialog] = useDialog(SettingsForm, { position: "bottom" });

  return (
    <>
      <button className="icon-button" onClick={() => openDialog()}>
        <FiSliders />
      </button>
      {dialog}
    </>
  );
};
