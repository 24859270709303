import { getPortfolio } from "../firebase/get-portfolio";
import { Portfolio } from "../portfolio/portfolio";
import { ObservableCache } from "../common/observable-cache";
import { storage } from "../common/storage";

export const portfolioCache = new ObservableCache<Portfolio>(
  uid => getPortfolio(uid),
  {
    write: (key, portfolio) => {
      if (portfolio) {
        storage.set("portfolio:" + key, portfolio.data);
      }
    },
    read: key => {
      const value = storage.get("portfolio:" + key);
      return value ? new Portfolio(value) : undefined;
    }
  }
);
