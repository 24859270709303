"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.History = void 0;
/**
 * Allows listening to history events, because pushState and replaceState
 * don't trigger browser events.
 */
class History {
    constructor() {
        this.changeListeners = new Set();
        this.onPopState = () => {
            this.emitChange("pop");
        };
        this.emitChange = (action) => {
            this.changeListeners.forEach((listener) => {
                listener(action);
            });
        };
        this.onChange = (listener) => {
            this.changeListeners.add(listener);
            return () => {
                this.changeListeners.delete(listener);
            };
        };
        this.push = (href) => {
            window.history.pushState({}, "", href);
            this.emitChange("push");
        };
        this.replace = (href) => {
            window.history.replaceState({}, "", href);
            this.emitChange("replace");
        };
        this.back = () => {
            window.history.back();
        };
        this.dispose = () => {
            window.removeEventListener("popstate", this.onPopState);
        };
        window.addEventListener("popstate", this.onPopState);
    }
}
exports.History = History;
