import { getCaTax } from "./ca-tax";
import { Taxables } from "./taxables";
import { getUsTax } from "./us-tax";

export function getUsTaxUnderpayment({
  saleSummaries,
  incomeHoldings,
}: Taxables) {
  const usTax = getUsTax(saleSummaries, incomeHoldings);
  const caTax = getCaTax(saleSummaries, incomeHoldings);
  const totalTax = usTax.total + caTax.total;
  const taxWithheld = saleSummaries.reduce(
    (total, summary) => total + (summary.isWithholding ? summary.proceeds : 0),
    0
  );
  return {
    underpayment: totalTax - taxWithheld,
    totalTax,
    taxWithheld,
  };
}
