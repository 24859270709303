"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchIn = void 0;
const to_url_object_1 = require("./to-url-object");
const matchIn = (routes, urlLike) => {
    const url = to_url_object_1.toUrlObject(urlLike);
    for (const [key, route] of Object.entries(routes)) {
        const match = route.match(url);
        if (match) {
            return key;
        }
    }
    return null;
};
exports.matchIn = matchIn;
