"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUseModel = void 0;
const wait_for_1 = require("./wait-for");
/**
 * Helper function to simplify useModel overloading
 */
function createUseModel(useModelImpl) {
    return (queries, options = {}) => {
        // Normalize all overloads of the queries arg to a dict
        const queriesDict = queries == null || isQuery(queries) ? { "0": queries } : toDict(queries);
        // Filter out null queries
        const nonNullQueriesDict = {};
        for (const [key, query] of Object.entries(queriesDict)) {
            if (query != null) {
                nonNullQueriesDict[key] = query;
            }
        }
        // Convert dict into an array, since arrays are easier for implementations
        // to work with. However, we need to maintain a reverse mapping from array
        // indices to dict keys.
        const indexToKey = Object.fromEntries(Object.keys(nonNullQueriesDict).map((key, index) => [index, key]));
        const asyncValueArray = useModelImpl(Object.values(nonNullQueriesDict));
        const valueArray = options.async
            ? asyncValueArray
            : wait_for_1.waitFor(asyncValueArray);
        const valueDict = Object.fromEntries(valueArray.map((data, i) => [indexToKey[i], data]));
        // Convert the output to the form that matches the input
        return queries == null || isQuery(queries)
            ? valueDict["0"]
            : Array.isArray(queries)
                ? toArray(valueDict)
                : valueDict;
    };
}
exports.createUseModel = createUseModel;
function isQuery(value) {
    return (value != null &&
        value.hasOwnProperty("model") &&
        value.hasOwnProperty("params"));
}
function toDict(queries) {
    if (Array.isArray(queries)) {
        const object = {};
        queries.forEach((value, i) => {
            object[i] = value;
        });
        return object;
    }
    return queries;
}
function toArray(dict) {
    const array = [];
    for (const [i, value] of Object.entries(dict)) {
        array[Number(i)] = value;
    }
    return array;
}
