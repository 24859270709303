import React from "react";
import { FiPlus } from "react-icons/fi";
import { AddInstrumentForm } from "./add-instrument-form";
import { useDialog } from "./use-dialog";
import { AddAssetForm } from "./add-asset-form";
import { useRouter } from "router";
import { ROUTES } from "../routes";
import { AddAccountRecordForm } from "./add-account-record-form";
import { AssetId } from "../market/asset-id";

export const AddAssetButton = () => {
  const router = useRouter();
  const match = router.match(ROUTES.ASSET);
  const [dialog, openDialog] = useDialog(({ onClose }) => {
    const assetId = match?.assetId;
    if (!assetId) {
      return <AddAssetForm onClose={onClose} />;
    }
    const info = AssetId(assetId);
    if (info.type === "stock" || info.type === "crypto") {
      return <AddInstrumentForm assetId={info} onClose={onClose} />;
    }
    return <AddAccountRecordForm accountId={info.symbol} onClose={onClose} />;
  });

  return (
    <>
      <button className="icon-button" onClick={() => openDialog()}>
        <FiPlus size="1.2em" />
      </button>
      {dialog}
    </>
  );
};
