"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createModel = exports.ModelRegistry = void 0;
const query_key_1 = require("./query-key");
exports.ModelRegistry = {};
function createModel(config) {
    var _a;
    const key = (_a = config.key) !== null && _a !== void 0 ? _a : getDefaultModelKey();
    const Model = ((params, conditions = {}) => (Object.assign({ get key() {
            return query_key_1.getQueryKey(this);
        }, model: Model, params }, conditions)));
    function toString() {
        return `[Model ${key}]`;
    }
    Object.defineProperties(Model, {
        read: {
            enumerable: false,
            value: config.read,
        },
        load: {
            enumerable: false,
            value: config.load,
        },
        write: {
            enumerable: false,
            value: config.write,
        },
        invalidate: {
            enumerable: false,
            value: config.invalidate,
        },
        key: {
            enumerable: false,
            value: key,
        },
        schema: {
            enumerable: false,
            value: config.schema,
        },
        maxAge: {
            enumerable: false,
            value: config.maxAge,
        },
        refreshAge: {
            enumerable: false,
            value: config.refreshAge,
        },
        // The following properties exist to improve debuggability
        toJSON: {
            enumerable: false,
            value: toString,
        },
        toString: {
            enumerable: false,
            value: toString,
        },
        name: {
            enumerable: false,
            value: key,
        },
    });
    if (exports.ModelRegistry[key]) {
        throw new Error(`Model with key "${key}" is registered twice. Each model must have a unique key.`);
    }
    exports.ModelRegistry[key] = Model;
    return Model;
}
exports.createModel = createModel;
let defaultModelKeyCounter = 0;
function getDefaultModelKey() {
    return `__Model-${defaultModelKeyCounter++}__`;
}
