import React, { FC } from "react";
import { css } from "emotion";
import { AppHeader } from "./app-header";
import { AppFooter } from "./app-footer";

const styles = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto 1fr 52px;
  height: 100%;

  > * {
    width: 100%;
  }

  > main {
    max-width: 550px;
    justify-self: center;
  }
`;

export const AppLayout: FC = ({ children }) => {
  return (
    <div className={styles}>
      <AppHeader />
      <main>{children}</main>
      <AppFooter />
    </div>
  );
};
