import React, { FC, useState } from "react";
import { SubmitButton } from "./submit-button";
import { addAccount } from "../firebase/accounts";
import { usePortfolio } from "./use-portfolio";
import { Currency } from "../market/money";

const DEFAULT_CURRENCY: Currency = "USD";

export const AddAccountForm: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const portfolio = usePortfolio();
  const [state, setState] = useState(() => ({
    name: "",
    currency: DEFAULT_CURRENCY,
  }));
  const getOnChange = (
    name: string,
    transform: (value: string) => string = (x) => x
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = transform(e.currentTarget.value);
    setState((state) => ({ ...state, [name]: value }));
  };

  return (
    <form className="v-grid">
      <h1>Add Account</h1>
      <div className="v-grid-small">
        <label>
          Name
          <input value={state.name} onChange={getOnChange("name")} />
        </label>
        <label>
          Currency
          <input
            value={state.currency}
            onChange={getOnChange("currency", (x) => x.toUpperCase())}
          />
        </label>
      </div>
      <SubmitButton
        onClick={(e) => {
          e.preventDefault();
          return addAccount({
            portfolioId: portfolio.id,
            account: {
              name: state.name,
              currency: state.currency,
              tags: [],
            },
          }).then(onClose);
        }}
      >
        Submit
      </SubmitButton>
    </form>
  );
};
