import { format } from "date-fns";
import { css } from "emotion";
import { sum } from "lodash";
import React from "react";
import { Holding } from "../../../portfolio/holding";
import { usePortfolio } from "../../use-portfolio";
import { usePortfolioValue } from "../../use-portfolio-value";
import { useQuotes } from "../../use-quotes";
import { getAuTax } from "./au-tax";
import { BreakdownView } from "./breakdown";
import { getCaTax } from "./ca-tax";
import { getTaxYear, TaxPeriod } from "./tax-period";
import { getTaxablesForPeriod } from "./taxables";
import { getUsTax } from "./us-tax";
import { SellTransaction } from "../../../portfolio/transaction";
import { getSaleSummaryFromReceipt, SaleSummary } from "./sale-receipt";
import { getUsTaxUnderpayment } from "./us-tax-underpayment";

const style = css`
  background: #353c45;
  padding: var(--padding);
`;

export function NetLiquidationValue({ holdings }: { holdings: Holding[] }) {
  const portfolioValue = usePortfolioValue();
  const portfolio = usePortfolio();
  const quotes = useQuotes(portfolio.instrumentIds);
  const currentDate = new Date();
  const unpaidTaxPeriods: TaxPeriod[] = [
    { taxCountry: "US", taxYearOffset: 0, currentDate },
    { taxCountry: "AU", taxYearOffset: 0, currentDate },
    { taxCountry: "AU", taxYearOffset: -1, currentDate },
  ];
  const unpaidTaxes = unpaidTaxPeriods.map((taxPeriod) => {
    const taxables = getTaxablesForPeriod(taxPeriod, holdings);
    if (taxPeriod.taxCountry === "US") {
      return getUsTaxUnderpayment(taxables).underpayment;
    }
    return getAuTax(taxables.saleSummaries).total;
  });
  const totalUnpaidTaxes = sum(unpaidTaxes);

  // Projected sales if portfolio is liquidated
  let sellId = 0;
  const projectedSaleSummaries: SaleSummary[] = [];
  holdings.forEach((holding) => {
    if (holding.quantity <= 0) {
      return;
    }
    const sell: SellTransaction = {
      id: `projected-${sellId++}`,
      type: "sell",
      refId: holding.id,
      date: format(currentDate, "yyyy-MM-dd"),
      symbol: holding.instrumentId.key,
      quantity: holding.quantity,
      price: quotes[holding.instrumentId.key]?.latestPrice ?? 0,
      tags: [],
    };
    projectedSaleSummaries.push(getSaleSummaryFromReceipt({ sell, holding }));
  });
  const projectedUsTax = getUsTax(projectedSaleSummaries, []).total;
  const projectedCaTax = getCaTax(projectedSaleSummaries, []).total;
  const projectedAuTax = getAuTax(projectedSaleSummaries).total;

  const netLiquidationValue =
    portfolioValue -
    totalUnpaidTaxes -
    projectedUsTax -
    projectedCaTax -
    projectedAuTax;

  return (
    <div className={style}>
      <BreakdownView
        breakdown={[
          { name: "Net Liq Value", value: netLiquidationValue },
          ...unpaidTaxPeriods.map((taxPeriod, i) => ({
            name: `Unpaid ${taxPeriod.taxCountry}${String(
              getTaxYear(taxPeriod)
            ).substring(2)} Tax`,
            value: unpaidTaxes[i],
          })),
          { name: "Projected US Tax", value: projectedUsTax },
          { name: "Projected CA Tax", value: projectedCaTax },
          { name: "Projected AU Tax", value: projectedAuTax },
        ]}
      />
    </div>
  );
}
