import mapValues from "lodash/mapValues";
import { url } from "../common/url";
import { jsonFetch } from "../common/json-fetch";
import { openExchangeRatesAppId } from "./api-tokens";
import { model } from "cosmonaut";
import { Currency, Money } from "./money";
import BigNumber from "bignumber.js";

type ApiResponse = {
  rates: {
    [currency: string]: number;
  };
};

export type ExchangeRates = {
  [currency: string]: number;
};

export const getExchangeRates = (
  baseCurrency: Currency
): Promise<ExchangeRates> =>
  jsonFetch<ApiResponse>(
    url`https://openexchangerates.org/api/latest.json?app_id=${openExchangeRatesAppId}`
  ).then((json) => {
    const baseRate = json.rates[baseCurrency];
    return mapValues(json.rates, (rate: number) => rate / baseRate);
  });

export const ExchangeRatesModel = model<
  ExchangeRates,
  { baseCurrency: Currency }
>(({ baseCurrency }) => getExchangeRates(baseCurrency), {
  key: "ExchangeRates",
});

export function exchangeMoney(
  rates: ExchangeRates,
  money: Money,
  toCurrency: Currency
): Money {
  const newValue = new BigNumber(money.value)
    .dividedBy(rates[money.currency])
    .times(rates[toCurrency]);
  return {
    value: newValue.toFixed(8),
    currency: toCurrency,
  };
}
