import React, { FC } from "react";
import { Currency } from "../market/money";
import { DisplayLength, MoneyView } from "./money-view";

export const CurrencyValue: FC<{
  value: number;
  currency?: Currency;
  displayOriginalCurrency?: boolean;
  plusSign?: boolean;
  displayLength?: DisplayLength;
}> = ({
  value,
  currency,
  displayOriginalCurrency,
  plusSign,
  displayLength,
}) => {
  return (
    <MoneyView
      money={{ value: String(value), currency: currency ?? "USD" }}
      displayOriginalCurrency={displayOriginalCurrency}
      plusSign={plusSign}
      displayLength={displayLength}
    />
  );
};
