import React, { FC } from "react";
import { Account, AccountRecord } from "../../portfolio/account";
import { CurrencyValue } from "../currency-value";

export const AccountRecordRow: FC<{
  record: AccountRecord;
  asset: Account;
}> = ({ record, asset }) => {
  return (
    <div>
      <div className="row font-size-small color-3">
        <span>{record.date}</span>
      </div>
      <div className="row margin-top-xsmall">
        <span>Value:</span>
        <span>
          <CurrencyValue
            displayOriginalCurrency
            currency={asset.currency}
            value={record.value}
          />
        </span>
      </div>
    </div>
  );
};
