import React from "react";
import { AssetId } from "../market/asset-id";
import { SetAllocationForm } from "./set-allocation-form";
import { useDialog } from "./use-dialog";

export function SetAllocationButton({ assetId }: { assetId: AssetId }) {
  const [dialog, openDialog] = useDialog(({ onClose }) => (
    <SetAllocationForm onClose={onClose} assetId={assetId} />
  ));

  return (
    <>
      <button className="font-size-small" onClick={() => openDialog()}>
        Set target allocation
      </button>
      {dialog}
    </>
  );
}
