import React from "react";
import { FiSearch } from "react-icons/fi";
import { useDialog } from "./use-dialog";
import { InstrumentSearchView } from "./instrument-search-view";

export const SearchEquityButton = () => {
  const [dialog, openDialog] = useDialog(
    ({ onClose }) => <InstrumentSearchView onSelect={onClose} />,
    { fullHeight: true }
  );

  return (
    <>
      <button
        type="button"
        className="icon-button"
        onClick={() => openDialog()}
      >
        <FiSearch />
      </button>
      {dialog}
    </>
  );
};
