import React, { FC, useState } from "react";
import { SubmitButton } from "./submit-button";
import { addTransaction } from "../firebase/transactions";
import { usePortfolio } from "./use-portfolio";
import { useContainer } from "unstated-next";
import { FiltersContainer } from "./filters";
import { format } from "date-fns";
import { AssetId, CryptoId, StockId } from "../market/asset-id";

export const AddInstrumentForm: FC<{
  assetId?: AssetId;
  type?: "stock" | "crypto";
  onClose: () => void;
}> = ({ type = "stock", assetId, onClose }) => {
  const portfolio = usePortfolio();
  const { filters } = useContainer(FiltersContainer);
  const [state, setState] = useState(() => ({
    symbol: "",
    date: format(new Date(), "yyyy-MM-dd"),
    quantity: "",
    price: "",
    tags: filters.size === 1 ? Array.from(filters)[0] : "",
  }));
  const getOnChange = (
    name: string,
    transform: (value: string) => string = (x) => x
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = transform(e.currentTarget.value);
    setState((state) => ({ ...state, [name]: value }));
  };

  return (
    <form className="v-grid">
      <h1>
        {assetId
          ? `Add ${assetId.symbol} Holding`
          : type === "crypto"
          ? "Add Crypto"
          : "Add Stock"}
      </h1>
      <div className="v-grid-small">
        {!assetId ? (
          <label>
            Symbol
            <input
              value={state.symbol}
              onChange={getOnChange("symbol", (x) => x.toLocaleUpperCase())}
            />
          </label>
        ) : null}
        <label>
          Date
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            value={state.date}
            onChange={getOnChange("date")}
          />
        </label>
        <label>
          Quantity
          <input
            type="number"
            value={state.quantity}
            onChange={getOnChange("quantity")}
          />
        </label>
        <label>
          Price
          <input
            type="number"
            value={state.price}
            onChange={getOnChange("price")}
          />
        </label>
        <label>
          Tags
          <input
            placeholder="list, of, tags"
            value={state.tags}
            onChange={getOnChange("tags", (x) => x.toLocaleLowerCase())}
          />
        </label>
      </div>
      <SubmitButton
        onClick={(e) => {
          e.preventDefault();

          return addTransaction({
            portfolioId: portfolio.id,
            transaction: {
              type: "buy",
              symbol: assetId
                ? assetId.key
                : (type === "stock"
                    ? StockId(state.symbol)
                    : CryptoId(state.symbol)
                  ).key,
              date: state.date,
              quantity: parseFloat(state.quantity),
              price: parseFloat(state.price),
              tags: state.tags.split(/\s*,\s*/),
            },
          }).then(onClose);
        }}
      >
        Submit
      </SubmitButton>
    </form>
  );
};
