import React, { FC } from "react";
import { SignOutButton } from "./sign-in-button";
import { FiltersSelector } from "./filters";
import { CurrencySelector } from "./currency-selector";

export const SettingsForm: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <div className="v-grid">
      <FiltersSelector />
      <CurrencySelector />
      <h2>Account</h2>
      <SignOutButton />
    </div>
  );
};
