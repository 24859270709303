"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPromise = exports.getResult = exports.isResultValue = exports.isErrorValue = exports.ErrorValue = exports.isDataValue = exports.DataValue = exports.isLoadingValue = exports.LoadingValue = exports.isLoadableValue = exports.LoadableValue = void 0;
function LoadableValue(load) {
    return { load };
}
exports.LoadableValue = LoadableValue;
function isLoadableValue(value) {
    return value != null && value.hasOwnProperty("load");
}
exports.isLoadableValue = isLoadableValue;
function LoadingValue(loading, abort) {
    return { loading, abort };
}
exports.LoadingValue = LoadingValue;
function isLoadingValue(value) {
    return value != null && value.hasOwnProperty("loading");
}
exports.isLoadingValue = isLoadingValue;
function DataValue(data, updatedAt = Date.now()) {
    return { data, updatedAt };
}
exports.DataValue = DataValue;
function isDataValue(value) {
    return value != null && value.hasOwnProperty("data");
}
exports.isDataValue = isDataValue;
function ErrorValue(error, updatedAt = Date.now()) {
    return { error, updatedAt };
}
exports.ErrorValue = ErrorValue;
function isErrorValue(value) {
    return value != null && value.hasOwnProperty("error");
}
exports.isErrorValue = isErrorValue;
function isResultValue(value) {
    return isErrorValue(value) || isDataValue(value);
}
exports.isResultValue = isResultValue;
function getResult(value) {
    return isErrorValue(value)
        ? value.error
        : isDataValue(value)
            ? value.data
            : undefined;
}
exports.getResult = getResult;
function toPromise(value) {
    if (isLoadableValue(value)) {
        return toPromise(value.load());
    }
    if (isLoadingValue(value)) {
        return value.loading;
    }
    if (isErrorValue(value)) {
        return Promise.reject(value.error);
    }
    return Promise.resolve(value.data);
}
exports.toPromise = toPromise;
