import React from "react";
import { downloadCSV } from "./download-csv";
import { SaleSummary } from "./sale-receipt";

export function DownloadCapitalGainsButton({
  saleSummaries,
}: {
  saleSummaries: SaleSummary[];
}) {
  return (
    <button
      onClick={() => {
        downloadCSV(
          "capital-gains.csv",
          [
            `"Disposal Date"`,
            "Asset",
            "Quantity",
            "Proceeds",
            `"Cost Basis"`,
            "Capital Gain/Loss",
            `"Acquisition Date"`,
            "Term",
            "Tags",
          ],
          saleSummaries.map((item) => {
            return [
              item.disposalDate,
              item.asset,
              item.quantity,
              item.proceeds.toFixed(2),
              item.costBasis.toFixed(2),
              item.capitalGainLoss.toFixed(2),
              item.acquisitionDate,
              item.term,
              item.tags.join(", "),
            ];
          })
        );
      }}
    >
      Download Capital Gains
    </button>
  );
}
