import { SubmitButton } from "./submit-button";
import React from "react";
import { signIn, signOut } from "../firebase/current-user";

export const SignInButton = () => {
  return <SubmitButton onClick={signIn}>Sign in</SubmitButton>;
};

export const SignOutButton = () => {
  return <SubmitButton onClick={signOut}>Sign out</SubmitButton>;
};
