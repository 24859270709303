/**
 * Tagged template for automatically encoding dynamic URL components:
 *
 * url`https://www.example.com/${var}`
 */
export const url = (strings: TemplateStringsArray, ...values: any[]): string =>
  strings.reduce(
    (result, str, index) =>
      result +
      str +
      (values.length > index ? encodeURIComponent(values[index]) : ""),
    ""
  );
