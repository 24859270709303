"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteIn = exports.setIn = exports.getIn = exports.Cache = void 0;
const immer_1 = __importStar(require("immer"));
const cache_denormalize_1 = require("./cache-denormalize");
const cache_normalize_1 = require("./cache-normalize");
const observers_1 = require("./observers");
const query_key_1 = require("./query-key");
const value_1 = require("./value");
immer_1.setAutoFreeze(false);
class Cache {
    constructor(cache = { error: {}, data: {} }) {
        this.observers = new observers_1.Observers();
        this.operations = [];
        this.batchStack = [];
        this.cache = cache;
    }
    getData(query) {
        const rootValue = getIn(this.cache.data, query);
        const data = cache_denormalize_1.denormalize((query) => { var _a; return (_a = getIn(this.cache.data, query)) === null || _a === void 0 ? void 0 : _a.data; }, query);
        return data === undefined
            ? undefined
            : value_1.DataValue(data, rootValue === null || rootValue === void 0 ? void 0 : rootValue.updatedAt);
    }
    getError(query) {
        return getIn(this.cache.error, query);
    }
    set(query, value) {
        this.write((writer) => {
            writer.set(query, value);
        });
    }
    delete(query, sectionName) {
        this.write((writer) => {
            writer.delete(query, sectionName);
        });
    }
    /**
     * Write to the cache in a single "transaction".
     * This means that observers will only be notified once.
     */
    write(fn) {
        const operations = [];
        this.cache = immer_1.default(this.cache, (draft) => {
            const writer = {
                set(query, value) {
                    const section = getSection(draft, value);
                    if (value_1.isDataValue(value)) {
                        cache_normalize_1.normalize((query, data) => {
                            const value = value_1.DataValue(data);
                            setIn(draft.data, query, value);
                            operations.push({ type: "set", query, value });
                        }, query, value.data);
                    }
                    else {
                        setIn(section, query, value);
                        operations.push({ type: "set", query, value });
                    }
                },
                delete(query, sectionName) {
                    if (sectionName) {
                        const deleted = deleteIn(draft[sectionName], query);
                        if (deleted) {
                            operations.push({ type: "delete", query, value: deleted });
                        }
                    }
                    else {
                        writer.delete(query, "error");
                        writer.delete(query, "data");
                    }
                },
            };
            fn(writer);
        });
        const queuedOperations = this.operations;
        this.operations = this.operations.concat(operations);
        if (queuedOperations.length === 0 && operations.length > 0) {
            new Promise((resolve) => resolve()).then(() => {
                const operations = this.operations;
                this.operations = [];
                this.observers.notify(operations);
            });
        }
    }
    writeInBatch(fn) {
        this.batchStack.unshift(null);
        fn();
        this.batchStack.shift();
        if (this.batchStack.length === 0) {
            this.observers.notify([]);
        }
    }
    /**
     * Observe all changes to the cache.
     */
    subscribe(observer) {
        return this.observers.subscribe(observer);
    }
    /**
     * Get a JSON-serializable snapshot of the cache.
     *
     * Ignores models that have generated keys, since they cannot be
     * determistically rehydrated.
     */
    getSnapshot() {
        const filtered = {};
        for (const [key, value] of Object.entries(this.cache.data)) {
            if (!key.startsWith("__Model-")) {
                filtered[key] = value;
            }
        }
        return { data: filtered };
    }
    /**
     * Reset the cache contents to the snapshot.
     */
    setFromSnapshot(snapshot) {
        // TODO validate the snapshot
        this.cache = {
            error: {},
            data: snapshot.data,
        };
        this.observers.notify([]);
    }
}
exports.Cache = Cache;
function getIn(section, query) {
    var _a;
    return (_a = section[query_key_1.getModelKey(query)]) === null || _a === void 0 ? void 0 : _a[query_key_1.getQueryKey(query)];
}
exports.getIn = getIn;
function setIn(section, query, value) {
    const { model } = query;
    if (!section[model.key]) {
        section[model.key] = {};
    }
    section[model.key][query_key_1.getQueryKey(query)] = value;
}
exports.setIn = setIn;
function deleteIn(section, query) {
    const { model } = query;
    if (!section[model.key])
        return null;
    const queryKey = query_key_1.getQueryKey(query);
    if (!section[model.key][queryKey])
        return null;
    const queryKeys = Object.keys(section[model.key]);
    const deleted = section[model.key][queryKey];
    if (queryKeys.length === 1) {
        delete section[model.key];
    }
    else {
        delete section[model.key][queryKey];
    }
    return deleted;
}
exports.deleteIn = deleteIn;
function getSection(data, value) {
    return value_1.isErrorValue(value) ? data.error : data.data;
}
