import React, { ReactNode } from "react";
import { AssetId } from "../../market/asset-id";

export type AssetType = AssetId["type"] | null;

export type Props = {
  selected: AssetType;
  onSelect: (type: AssetType) => void;
};

export function AssetTypeTabs(props: Props) {
  return (
    <div
      style={{
        color: "rgba(255, 255, 255, 0.5)",
        display: "flex",
        fontSize: 18,
        fontWeight: 100,
        justifyContent: "space-between",
        paddingBottom: 24,
        textAlign: "center",
      }}
    >
      <Tab type={null} {...props}>
        All
      </Tab>
      <Tab type="crypto" {...props}>
        Crypto
      </Tab>
      <Tab type="stock" {...props}>
        Stocks
      </Tab>
      <Tab type="account" {...props}>
        Accts
      </Tab>
    </div>
  );
}

function Tab({
  type,
  children,
  selected,
  onSelect,
}: { type: AssetType; children: ReactNode } & Props) {
  return (
    <div
      onClick={() => onSelect(type)}
      style={{
        background: selected === type ? "rgba(0, 0, 0, 0.3)" : undefined,
        borderRadius: 20,
        color: selected === type ? `rgba(255, 255, 255, 1)` : undefined,
        minWidth: 60,
        padding: "8px 18px",
      }}
    >
      {children}
    </div>
  );
}
