import { useModel } from "cosmonaut";
import React, { FC } from "react";
import { InstrumentId } from "../market/asset-id";
import { QuoteModel } from "../model/quote-model";
import { deepMemo } from "./deep-memo";
import { DisplayLength, MoneyView } from "./money-view";

export const QuoteView: FC<{
  instrumentId: InstrumentId;
  displayLength?: DisplayLength;
}> = deepMemo(({ instrumentId, displayLength }) => {
  const quote = useModel(QuoteModel({ id: instrumentId }));
  return <MoneyView money={quote.latest} displayLength={displayLength} />;
});
