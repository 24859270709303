"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchParam = exports.param = exports.Param = void 0;
const DEFAULT_CONDITION = (s) => s;
class Param {
    constructor(name, required, condition) {
        this._name = name;
        this._required = required;
        this._condition = condition;
    }
    name(name) {
        return new Param(name, this._required, this._condition);
    }
    required(required) {
        return new Param(this._name, required, this._condition);
    }
    optional() {
        return this.required(false);
    }
    condition(cond) {
        return new Param(this._name, this._required, (value) => {
            // conditions are additive
            return cond(this._condition(value));
        });
    }
    oneOf(options) {
        return this.condition((s) => {
            if (options.includes(s)) {
                return s;
            }
            throw new Error(`Value ${s} not in ${options.join(",")}`);
        });
    }
    /**
     * String representation of the param, used for debugging.
     */
    toString() {
        let flags = "";
        if (!this._required) {
            flags += "?";
        }
        if (this._condition !== DEFAULT_CONDITION) {
            flags += "^";
        }
        return `:${this._name}${flags ? `[${flags}]` : ""}`;
    }
}
exports.Param = Param;
function param(name) {
    return new Param(name, true, DEFAULT_CONDITION);
}
exports.param = param;
function matchParam(param, value) {
    try {
        return param._condition(value);
    }
    catch (e) {
        return;
    }
}
exports.matchParam = matchParam;
