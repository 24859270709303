import { model } from "cosmonaut";
import { InstrumentId } from "../market/asset-id";
import { getCryptoQuotes, getQuotes, Quote } from "../market/get-quote";

export const QuoteModel = model<Quote, { id: InstrumentId }>(
  ({ id }, { batch }) => {
    const { type } = id;
    if (type === "crypto") {
      return batch({
        key: { type },
        getBatch: (paramsList) =>
          getCryptoQuotes(paramsList.map((params) => params.id.symbol)),
      });
    }

    return batch({
      key: { type },
      getBatch: async (paramsList) => {
        const quoteMap = await getQuotes(
          paramsList.map((params) => params.id.symbol)
        );
        return paramsList.map((params) => quoteMap[params.id.symbol]);
      },
    });
  },
  {
    key: "Quote:2",
    refreshAge: "5s",
  }
);
