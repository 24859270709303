import React, { ReactNode } from "react";
import { CurrencyValue } from "../../currency-value";
import { Expandable } from "../../expandable";

export type BreakdownItem = {
  name: string;
  value: number;
};

export function BreakdownView({
  breakdown,
  footer,
}: {
  breakdown: BreakdownItem[];
  footer?: ReactNode;
}) {
  if (breakdown.length === 0) {
    return null;
  }

  return (
    <Expandable
      summary={
        <div className="row font-size-small color-1">
          <div className="color-2">{breakdown[0].name}</div>
          <div>
            <CurrencyValue value={breakdown[0].value} displayLength="full" />
          </div>
        </div>
      }
      detail={
        <>
          {breakdown.slice(1).map((row) => (
            <div
              key={row.name}
              className="row margin-top-small font-size-small"
            >
              <div>{row.name}</div>
              <CurrencyValue value={row.value} displayLength="full" />
            </div>
          ))}
          {footer}
        </>
      }
    />
  );
}
