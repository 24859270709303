import { useUid } from "./authenticate";
import { useObservable } from "../common/use-observable";
import { portfolioCache } from "../portfolio/portfolio-cache";
import { useMemo } from "react";

export const usePortfolioTags = (): Set<string> => {
  const uid = useUid();
  const portfolio = useObservable(portfolioCache.get$(uid));
  return useMemo(() => {
    const tags = new Set<string>();
    Object.values(portfolio.data.transactions).forEach((t) => {
      t.tags.forEach((tag) => {
        tags.add(tag);
      });
    });
    return tags;
  }, [portfolio]);
};
