"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scheduler = void 0;
const smart_timer_1 = require("./smart-timer");
/**
 * Schedules timeouts with a minimum resolution so that timeouts
 * that are scheduled for approximately the same point in time are
 * executed in the same execution frame.
 *
 * This improves the ability to batch operations together.
 */
class Scheduler {
    constructor(resolution) {
        this.queue = [];
        this.resolution = resolution;
    }
    setTimeout(callback, ms) {
        const task = {
            time: toResolution(Date.now() + ms, this.resolution),
            callback,
        };
        this.add(task);
        return () => {
            this.remove(task);
        };
    }
    add(task) {
        // Insert into queue sorted asc. by time
        const i = this.queue.findIndex((t) => task.time < t.time);
        if (i < 0) {
            this.queue.push(task);
        }
        else {
            this.queue.splice(i, 0, task);
        }
        this.reschedule();
    }
    remove(task) {
        const i = this.queue.findIndex((t) => t === task);
        if (i >= 0) {
            this.queue.splice(i, 1);
        }
        this.reschedule();
    }
    reschedule() {
        var _a;
        if (this.queue.length === 0) {
            if (this.timer) {
                this.timer.cancel();
                delete this.timer;
            }
        }
        else {
            const nextTask = this.queue[0];
            if (!this.timer || nextTask.time < this.timer.time) {
                (_a = this.timer) === null || _a === void 0 ? void 0 : _a.cancel();
                this.timer = {
                    time: nextTask.time,
                    cancel: smart_timer_1.setSmartTimeout(() => {
                        delete this.timer;
                        this.runExpiredTasks();
                    }, nextTask.time - Date.now()),
                };
            }
        }
    }
    runExpiredTasks() {
        const now = Date.now();
        let tasksRun = 0;
        for (const task of this.queue) {
            if (task.time > now) {
                break;
            }
            tasksRun++;
            task.callback();
        }
        this.queue.splice(0, tasksRun);
        this.reschedule();
    }
}
exports.Scheduler = Scheduler;
function toResolution(n, resolution) {
    // Use ceil instead of round so that setTimeout(..., n) guarantees that
    // AT LEAST n milliseconds will have elapsed.
    return Math.ceil(n / resolution) * resolution;
}
