"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.denormalize = void 0;
const cache_normalize_1 = require("./cache-normalize");
const query_key_1 = require("./query-key");
const create_model_1 = require("./create-model");
function denormalize(getEntity, query) {
    function getEntityOrThrow(query) {
        const value = getEntity(query);
        if (value === undefined) {
            throw new NotFoundError();
        }
        return value;
    }
    try {
        return _denormalize(getEntityOrThrow, query_key_1.getQueryKey(query), () => query);
    }
    catch (e) {
        if (e instanceof NotFoundError) {
            return undefined;
        }
        const error = new Error(`Error while denormalizing ${query_key_1.getQueryKey(query)}: `);
        error.stack = e.stack;
        throw error;
    }
}
exports.denormalize = denormalize;
function _denormalize(getEntity, entity, schema) {
    if (!schema) {
        return entity;
    }
    if (entity == null) {
        return entity;
    }
    if (cache_normalize_1.isQuerySchema(schema)) {
        if (typeof entity !== "string") {
            return entity;
        }
        const nestedEntity = getEntity(entity);
        const modelKey = query_key_1.getModelKey(entity);
        const model = create_model_1.ModelRegistry[modelKey];
        if (!model) {
            throw new Error(`No model exists with key ${modelKey}`);
        }
        const nestedSchema = model.schema;
        return _denormalize(getEntity, nestedEntity, nestedSchema);
    }
    if (typeof entity !== "object") {
        return entity;
    }
    if (cache_normalize_1.isArraySchema(schema)) {
        if (!Array.isArray(entity)) {
            throw new Error(`Expected an array but received: ${entity}`);
        }
        return entity.map((item) => _denormalize(getEntity, item, schema[0]));
    }
    if (Array.isArray(entity)) {
        throw new Error(`Expected an object but received: ${entity}`);
    }
    const data = Object.assign({}, entity);
    Object.entries(schema).forEach(([key, nestedSchema]) => {
        data[key] = _denormalize(getEntity, data[key], nestedSchema);
    });
    return data;
}
class NotFoundError extends Error {
    constructor() {
        super(...arguments);
        this.message = "Entity not found";
    }
}
