import React, { useEffect, useState } from "react";
import { usePortfolio } from "../use-portfolio";
import { useRouter } from "router";
import { ROUTES } from "../../routes";
import { DeleteTransactionButton } from "./delete-transaction-button";
import { TransactionForm } from "./transaction-form";
import { useDialog } from "../use-dialog";
import { SellHoldingForm } from "../sell-holding-form";
import { TransactionRow } from "../asset-screen/transaction-row";
import { css } from "emotion";

export const TransactionScreen = () => {
  const portfolio = usePortfolio();
  const router = useRouter();
  const match = router.match(ROUTES.TRANSACTION);
  const transactionId = match?.transactionId;
  const transaction = transactionId
    ? portfolio.getTransaction(transactionId)
    : null;
  const [isEditing, setIsEditing] = useState(false);
  const [sellDialog, openSellDialog] = useDialog(({ onClose }) =>
    transactionId ? (
      <SellHoldingForm transactionId={transactionId} onClose={onClose} />
    ) : null
  );

  useEffect(() => {
    if (match && !transaction) {
      router.replace(ROUTES.HOME.toHref());
    }
  }, [match, transaction, router]);

  if (!transaction) {
    return null;
  }

  return (
    <div className="padding v-grid" style={{ paddingTop: 0 }}>
      <h1>
        {transaction.symbol} {transaction.type}
      </h1>
      <TransactionForm
        transactionId={transaction.id}
        isEditing={isEditing}
        onSubmit={() => setIsEditing(false)}
      />
      {!isEditing && (
        <div className="v-grid-small">
          {transaction.type === "buy" &&
          portfolio.getHolding(transaction.id).quantity > 0 ? (
            <button onClick={() => openSellDialog()}>Sell</button>
          ) : null}
          <button onClick={() => setIsEditing(true)}>Edit</button>
          <DeleteTransactionButton
            portfolioId={portfolio.id}
            transactionId={transaction.id}
          />
        </div>
      )}
      {transaction.type === "buy" ? (
        <HoldingHistory buyId={transaction.id} />
      ) : null}
      {sellDialog}
    </div>
  );
};

const holdingHistoryListStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
`;

function HoldingHistory({ buyId }: { buyId: string }) {
  const portfolio = usePortfolio();
  const holding = portfolio.getHolding(buyId);

  return (
    <div>
      <h2>History</h2>
      <div className={holdingHistoryListStyles}>
        {holding.sells
          .slice(0)
          .sort((a, b) => b.date.localeCompare(a.date))
          .map((sell) => (
            <TransactionRow key={sell.id} transaction={sell} />
          ))}
      </div>
    </div>
  );
}
