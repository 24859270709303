import React from "react";
import { usePortfolio } from "../use-portfolio";
import { TransactionRow } from "./transaction-row";
import { TaxAnalysis } from "../tax-analysis";
import { PerformanceAnalysis } from "../performance-analysis";
import { KeyStatsView } from "./key-stats-view";
import { InstrumentId, toNewIdString } from "../../market/asset-id";
import { PositionAnalysis } from "../position-analysis";
import { CoinInfoView } from "./coin-info-view";
import { SetAllocationButton } from "../set-allocation-button";
import { useModel } from "cosmonaut";
import { AllocationsModel } from "../../portfolio/allocations";
import { InstrumentSummaryCard } from "./instrument-summary-card";
import { InstrumentPositionCard } from "./instrument-position-card";
import { formatNumber } from "toolbelt";

export function InstrumentScreen({ id }: { id: InstrumentId }) {
  const portfolio = usePortfolio();
  const asset = portfolio.getAsset(id);
  const assetPortfolio = portfolio.filterByAsset(id);
  const allocations = useModel(AllocationsModel({ portfolioId: portfolio.id }));
  const allocation = allocations[toNewIdString(id)] ?? 0;

  return (
    <div style={{ padding: "0 24px 24px" }}>
      <div className="v-grid">
        <InstrumentSummaryCard instrumentId={id} />
        <InstrumentPositionCard instrumentId={id} />
      </div>
      <div className="v-grid">
        <div className="margin-top v-grid-small">
          {asset ? (
            <>
              <PositionAnalysis portfolio={assetPortfolio} />
              <PerformanceAnalysis portfolio={assetPortfolio} />
              <TaxAnalysis portfolio={assetPortfolio} />
            </>
          ) : allocation ? (
            <div className="row font-size-small">
              {formatNumber(allocation, { asPercent: true, significance: 2 }) +
                " target"}
              <SetAllocationButton assetId={id} />
            </div>
          ) : (
            <SetAllocationButton assetId={id} />
          )}
        </div>
      </div>
      {id.type === "stock" ? <KeyStatsView id={id} /> : null}
      {id.type === "crypto" ? <CoinInfoView id={id} /> : null}
      {asset && (
        <div>
          <h2>History</h2>
          <div className="v-grid margin-top ">
            {asset.transactions
              .slice(0)
              .sort((a, b) => b.date.localeCompare(a.date))
              .map((transaction) => (
                <TransactionRow
                  key={transaction.id}
                  transaction={transaction}
                />
              ))}
          </div>
        </div>
      )}
      <div style={{ height: 6 }}></div>
    </div>
  );
}
