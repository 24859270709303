import React from "react";
import { css } from "emotion";
import { ROUTES } from "../routes";
import { FiArrowLeft } from "react-icons/fi";
import { useRouter } from "router";
import { usePortfolioValue } from "./use-portfolio-value";
import { MoneyView } from "./money-view";
import { usePrivacy } from "./privacy-context";
import Logo from "../logo.svg";

const styles = css`
  color: var(--color-brand);
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  place-items: center;
  align-items: center;
  height: 60px;
  font-weight: 100;
`;

const controlsStyles = css`
  color: #888;
  line-height: 0;
  justify-self: start;
  padding-left: 12px;
`;

const portfolioValueStyles = css`
  letter-spacing: 2px;
`;

export const AppHeader = () => {
  const router = useRouter();
  const { isShielded, shield } = usePrivacy();

  const routeKey = router.matchIn(ROUTES) ?? "HOME";

  const portfolioValue = usePortfolioValue();

  return (
    <header className={styles}>
      <div className={controlsStyles}>
        {routeKey === "HOME" ? null : (
          <button className="icon-button" onClick={router.back}>
            <FiArrowLeft />
          </button>
        )}
      </div>
      <div onClick={() => shield(!isShielded)} style={{ lineHeight: 0 }}>
        <MoneyView
          className={portfolioValueStyles}
          whenPrivate={<img alt="hidden" src={Logo} width="32" />}
          money={{ value: String(portfolioValue), currency: "USD" }}
          displayLength="short"
        />
      </div>
    </header>
  );
};
