import React, { FC, useMemo, useState, useCallback } from "react";
import produce from "immer";
import { createContainer, useContainer } from "unstated-next";
import { usePortfolioTags } from "./use-portfolio-tags";
import { Checkbox } from "./checkbox";
import { ToggleButton, ToggleList } from "./toggle-button";

const useFilters = () => {
  const tags = new Set<string>();
  const [filters, setFilters] = useState(tags);
  const [shouldExclude, setShouldExclude] = useState(false);
  const toggleFilter = useCallback((filter: string) => {
    setFilters((set) =>
      produce(set, (draft) => {
        if (draft.has(filter)) {
          draft.delete(filter);
        } else {
          draft.add(filter);
        }
      })
    );
  }, []);
  const toggleExclude = useCallback(() => {
    setShouldExclude((x) => !x);
  }, []);

  return useMemo(
    () => ({ filters, shouldExclude, toggleFilter, toggleExclude }),
    [filters, shouldExclude, toggleFilter, toggleExclude]
  );
};

export const FiltersContainer = createContainer(useFilters);

export const FiltersSelector: FC = () => {
  const tags = usePortfolioTags();
  const { filters, shouldExclude, toggleFilter, toggleExclude } =
    useContainer(FiltersContainer);

  return (
    <>
      <h2 className="row">
        Filter
        <Checkbox
          label="Exclude selected"
          checked={shouldExclude}
          onChange={toggleExclude}
        />
      </h2>
      <ToggleList>
        {Array.from(tags).map((tag) => {
          const isActive = filters.has(tag);
          return (
            <ToggleButton
              key={tag}
              isActive={isActive}
              onClick={() => toggleFilter(tag)}
            >
              {tag}
            </ToggleButton>
          );
        })}
      </ToggleList>
    </>
  );
};
