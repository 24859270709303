import { isAuSource, getPercentAuSource } from "./au-source";
import { SaleSummary } from "./sale-receipt";
import { AU_TAX_RATE } from "./tax-rates";

export function getAuTax(saleSummaries: SaleSummary[]) {
  const auSourceSummaries = saleSummaries.filter((summary) =>
    isAuSource(summary.acquisitionDate)
  );
  const proceeds = auSourceSummaries.reduce(
    (total, summary) => total + summary.proceeds,
    0
  );
  const capitalGainLoss = auSourceSummaries.reduce(
    (total, summary) => total + summary.capitalGainLoss,
    0
  );
  const discountedCapitalGainLoss = auSourceSummaries.reduce(
    (total, summary) => {
      const discount =
        summary.term === "LONG" ? getPercentAuSource(summary) * 0.5 : 0;
      return total + (1 - discount) * summary.capitalGainLoss;
    },
    0
  );
  const total = discountedCapitalGainLoss * AU_TAX_RATE;

  return {
    total,
    proceeds,
    capitalGainLoss,
    discountedCapitalGainLoss,
  };
}
