"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = void 0;
const defaultNumberFormatOptions = {
    plusSign: false,
    shorten: false,
    prefix: "",
    suffix: "",
    nullifyZero: false,
    asPercent: false,
};
function formatNumber(_value, options = {}) {
    let value = typeof _value === "number" ? _value : Number(_value);
    if (_value == null ||
        isNaN(value) ||
        value === Infinity ||
        value === -Infinity) {
        return "-";
    }
    const { decimals, significance, plusSign, shorten, prefix, suffix, nullifyZero, asPercent, } = Object.assign({}, defaultNumberFormatOptions, options);
    if (asPercent) {
        value = value * 100;
    }
    if (value === 0 && nullifyZero) {
        return "-";
    }
    const parts = {
        sign: value < 0 ? "-" : plusSign ? "+" : "",
        prefix,
        value: Math.abs(value),
        suffix,
    };
    if (asPercent) {
        parts.suffix = "%" + parts.suffix;
    }
    if (shorten) {
        const shortened = getShortened(value);
        parts.value = shortened.value;
        parts.suffix = shortened.suffix + parts.suffix;
    }
    const formattedValue = parts.value.toLocaleString("en-US", {
        minimumSignificantDigits: significance,
        maximumSignificantDigits: significance,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
    return `${parts.sign}${parts.prefix}${formattedValue}${parts.suffix}`;
}
exports.formatNumber = formatNumber;
const THOUSAND = 1000;
const MILLION = THOUSAND * THOUSAND;
const BILLION = MILLION * THOUSAND;
const TRILLION = BILLION * THOUSAND;
function getShortened(value) {
    const _value = Math.abs(value);
    if (_value / TRILLION > 1) {
        return { value: _value / TRILLION, suffix: "T" };
    }
    if (_value / BILLION > 1) {
        return { value: _value / BILLION, suffix: "B" };
    }
    if (_value / MILLION > 1) {
        return { value: _value / MILLION, suffix: "M" };
    }
    if (_value / THOUSAND > 1) {
        return { value: _value / THOUSAND, suffix: "K" };
    }
    return { value: _value * (value >= 0 ? 1 : -1), suffix: "" };
}
