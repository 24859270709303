import { css } from "emotion";
import React from "react";
import { CurrencyValue } from "../../currency-value";
import { TagList } from "../../tag-list";
import { isAuSource } from "./au-source";
import { SaleSummary } from "./sale-receipt";

const styles = css``;

export function SaleSummaryRow({ saleSummary }: { saleSummary: SaleSummary }) {
  return (
    <div className={styles}>
      <div>{saleSummary.disposalDate}</div>
      <div className="row">
        {saleSummary.asset}
        {isAuSource(saleSummary.acquisitionDate) ? (
          <TagList>
            <div>AU Source</div>
          </TagList>
        ) : null}
      </div>
      <div>
        <label>Proceeds: </label>
        <CurrencyValue value={saleSummary.proceeds} />
      </div>
      <div>
        <label>Gain/Loss: </label>
        <CurrencyValue
          plusSign
          value={saleSummary.proceeds - saleSummary.costBasis}
        />
      </div>
    </div>
  );
}
