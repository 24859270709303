import React, { FC } from "react";
import { useDialog } from "./use-dialog";
import { AddInstrumentForm } from "./add-instrument-form";
import { AddAccountForm } from "./add-account-form";

export const AddAssetForm: FC<{
  onClose: () => void;
}> = () => {
  const [addStockForm, openAddStockDialog] = useDialog(({ onClose }) => (
    <AddInstrumentForm type="stock" onClose={onClose} />
  ));
  const [addCryptoForm, openAddCryptoDialog] = useDialog(({ onClose }) => (
    <AddInstrumentForm type="crypto" onClose={onClose} />
  ));
  const [addAccountForm, openAddAccountDialog] = useDialog(({ onClose }) => (
    <AddAccountForm onClose={onClose} />
  ));

  return (
    <div className="v-grid">
      <button onClick={() => openAddStockDialog()}>Add Stock</button>
      <button onClick={() => openAddCryptoDialog()}>Add Crypto</button>
      <button onClick={() => openAddAccountDialog()}>Add Account</button>
      {addStockForm}
      {addCryptoForm}
      {addAccountForm}
    </div>
  );
};
