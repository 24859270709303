import { Holding } from "../../../portfolio/holding";
import { SaleSummary } from "./sale-receipt";
import { CA_TAX_RATE, CA_MENTAL_HEALTH_TAX } from "./tax-rates";

export function getCaTax(summaries: SaleSummary[], incomeHoldings: Holding[]) {
  const taxableGains = summaries.reduce(
    (total, summary) => total + summary.capitalGainLoss,
    0
  );

  const taxableInvestmentIncome = incomeHoldings.reduce(
    (total, holding) => total + holding.getCostBasis(),
    0
  );

  const taxableIncome = Math.max(0, taxableGains) + taxableInvestmentIncome;
  const incomeTax = taxableIncome * CA_TAX_RATE;
  const mentalHealthTax = taxableIncome * CA_MENTAL_HEALTH_TAX;

  return {
    total: incomeTax + mentalHealthTax,
    incomeTax,
    mentalHealthTax,
  };
}
