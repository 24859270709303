import React from "react";
import { getAuTax } from "./au-tax";
import { BreakdownView } from "./breakdown";
import { SaleSummary } from "./sale-receipt";

export function AuTaxBreakdown({
  saleSummaries,
}: {
  saleSummaries: SaleSummary[];
}) {
  const auTax = getAuTax(saleSummaries);

  return (
    <BreakdownView
      breakdown={[
        { name: "AU Tax", value: auTax.total },
        { name: "Proceeds", value: auTax.proceeds },
        { name: "Capital Gain", value: auTax.capitalGainLoss },
        {
          name: "Discounted Gain",
          value: auTax.discountedCapitalGainLoss,
        },
      ]}
    />
  );
}
