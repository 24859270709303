import { useModel } from "cosmonaut";
import React, { useMemo, useState } from "react";
import { formatNumber } from "toolbelt";
import { QuoteModel } from "../model/quote-model";
import { getTaxByTerm } from "../portfolio/get-tax-by-term";
import { Portfolio } from "../portfolio/portfolio";
import { CurrencyValue } from "./currency-value";
import { useDialog } from "./use-dialog";

export function SimulateTaxButton({ portfolio }: { portfolio: Portfolio }) {
  const [dialog, openDialog] = useDialog(() => {
    return <SimulateTaxForm portfolio={portfolio} />;
  });

  return (
    <>
      <button style={{ width: "100%" }} onClick={() => openDialog()}>
        Simulate tax
      </button>
      {dialog}
    </>
  );
}

function parseNumber(value: string, defaultValue: number): number {
  const n = Number(value);
  return isNaN(n) ? defaultValue : n;
}

function SimulateTaxForm({ portfolio }: { portfolio: Portfolio }) {
  const [saleValue, setSaleValue] = useState("");
  const saleValueNumber = parseNumber(saleValue, 0);
  const quotes = useModel(
    Object.fromEntries(
      portfolio.instrumentIds.map((id) => [id.key, QuoteModel({ id })])
    )
  );
  const availableValue = portfolio
    .getHoldings()
    .reduce(
      (sum, holding) =>
        sum +
        holding.getUnrealizedValue(
          quotes[holding.instrumentId.key].latestPrice
        ),
      0
    );
  const taxLiability = useMemo(() => {
    const taxLots = portfolio
      .getHoldings()
      .map((holding) => {
        const taxByTerm = getTaxByTerm([holding], quotes);
        const availableValue = holding.getUnrealizedValue(
          quotes[holding.instrumentId.key].latestPrice
        );
        return {
          availableValue,
          effectiveTaxRate:
            (taxByTerm.longTermTax + taxByTerm.shortTermTax) / availableValue,
        };
      })
      .filter((taxLot) => taxLot.availableValue > 0)
      .sort((a, b) => a.effectiveTaxRate - b.effectiveTaxRate);
    let taxLiability = 0;
    let unaccountedValue = saleValueNumber;
    for (const taxLot of taxLots) {
      if (unaccountedValue <= 0) {
        break;
      }
      if (taxLot.availableValue >= unaccountedValue) {
        taxLiability += unaccountedValue * taxLot.effectiveTaxRate;
        unaccountedValue = 0;
      } else {
        taxLiability += taxLot.availableValue * taxLot.effectiveTaxRate;
        unaccountedValue -= taxLot.availableValue;
      }
    }
    return taxLiability;
  }, [portfolio, quotes, saleValueNumber]);

  return (
    <div className="v-grid-small">
      <div className="row">
        <span className="color-2">Available value</span>
        <span>
          <CurrencyValue value={availableValue} />
        </span>
      </div>
      <div className="row">
        <span className="color-2">Sale value</span>
        <input
          style={{ width: "50%" }}
          value={saleValue}
          onChange={(e) => {
            setSaleValue(e.target.value);
          }}
        />
      </div>
      <div style={{ borderTop: "1px solid var(--color-5)" }} />
      <div className="row">
        <span className="color-2">Tax liability</span>
        <span>
          <CurrencyValue value={taxLiability} />
        </span>
      </div>
      <div className="row">
        <span className="color-2">% tax</span>
        <span>
          {saleValueNumber
            ? formatNumber(taxLiability / saleValueNumber, {
                asPercent: true,
                significance: 2,
              })
            : "-"}
        </span>
      </div>
      <div className="row">
        <span className="color-2">Net value</span>
        <span>
          <CurrencyValue value={saleValueNumber - taxLiability} />
        </span>
      </div>
    </div>
  );
}
