import React, { FC } from "react";
import { Expandable } from "./expandable";
import { Portfolio } from "../portfolio/portfolio";
import { CurrencyAndPercentValue } from "./currency-and-percent-value";
import { getRateOfReturnForDisplay } from "../portfolio/get-rate-of-return";
import { useQuotes } from "./use-quotes";

export const PerformanceAnalysis: FC<{ portfolio: Portfolio }> = ({
  portfolio,
}) => {
  const quotes = useQuotes(portfolio.instrumentIds);

  const unrealized = portfolio
    .getHoldings()
    .reduce(
      (unrealized, holding) =>
        unrealized +
        (quotes[holding.instrumentId.key].latestPrice - holding.buy.price) *
          holding.quantity,
      0
    );
  const unrealizedCost = portfolio
    .getHoldings()
    .reduce((cost, holding) => cost + holding.getCost(), 0);
  const realized = portfolio
    .getHoldings()
    .reduce(
      (realized, holding) =>
        realized +
        holding.sells.reduce(
          (realized, sell) =>
            realized + (sell.price - holding.buy.price) * sell.quantity,
          0
        ),
      0
    );
  const realizedCost = portfolio
    .getHoldings()
    .reduce(
      (total, holding) =>
        total + (holding.buy.quantity - holding.quantity) * holding.buy.price,
      0
    );
  const income = portfolio
    .getHoldings()
    .filter((holding) => holding.isIncome())
    .reduce((income, holding) => income + holding.getCostBasis(), 0);

  return (
    <Expandable
      summary={
        <div className="row font-size-small color-1">
          <div className="color-2">Return</div>
          <div>
            {getRateOfReturnForDisplay(portfolio.getHoldings(), quotes)}
          </div>
        </div>
      }
      detail={
        <>
          <div className="row margin-top-small font-size-small">
            <div>Total</div>
            <div>
              <CurrencyAndPercentValue
                value={unrealized + realized + income}
                total={unrealizedCost + realizedCost}
                plusSign
              />
            </div>
          </div>
          <div className="row margin-top-small font-size-small">
            <div>Realized</div>
            <div>
              <CurrencyAndPercentValue
                value={realized}
                total={realizedCost}
                plusSign
              />
            </div>
          </div>
          <div className="row margin-top-small font-size-small">
            <div>Unrealized</div>
            <div>
              <CurrencyAndPercentValue
                value={unrealized}
                total={unrealizedCost}
                plusSign
              />
            </div>
          </div>
          <div className="row margin-top-small font-size-small">
            <div>Income</div>
            <div>
              <CurrencyAndPercentValue
                value={income}
                total={unrealizedCost + realizedCost}
                plusSign
              />
            </div>
          </div>
        </>
      }
    />
  );
};
