import { Observable, ReplaySubject } from "rxjs";
import { getLatestObservableValue } from "./get-latest-observable-value";

export type CacheLoader<T, K extends string> = (key: K) => Observable<T>;

export type ObservableCacheOptions<T, K extends string> = {
  write?: (key: K, value: T) => void;
  read?: (key: K) => T | undefined;
};

export class ObservableCache<T, K extends string = string> {
  private data: { [key: string]: ReplaySubject<T> } = {};

  constructor(
    private loader: CacheLoader<T, K>,
    private options: ObservableCacheOptions<T, K> = {}
  ) {}

  get$(key: K): Observable<T> {
    if (!(key in this.data)) {
      const subject = new ReplaySubject<T>(1);

      if (this.options.read) {
        const persisted = this.options.read(key);
        if (persisted !== undefined) {
          subject.next(persisted);
        }
      }

      this.loader(key).subscribe(subject);
      this.data[key] = subject;

      const write = this.options.write;
      if (write) {
        subject.subscribe((value) => {
          write(key, value);
        });
      }
    }

    return this.data[key];
  }

  get(key: K): T {
    return getLatestObservableValue(this.get$(key));
  }
}
