import { css } from "emotion";
import React from "react";
import { AssetId, isInstrument } from "../../market/asset-id";
import { AssetName } from "../asset-name";
import { QuoteChange } from "../quote-change";
import { QuoteView } from "../quote-view";
import { AssetLink } from "./asset-link";

export type Props = {
  assetId: AssetId;
};

const styles = css`
  display: grid;
  font-size: 18px;
  font-weight: 100;
  grid-template-columns: auto 1fr 60px;
  grid-gap: 24px;
  justify-items: end;
  padding: 10px 24px;

  > :first-child {
    font-weight: normal;
  }
`;

export function PortfolioPriceRow({ assetId }: Props) {
  if (!isInstrument(assetId)) {
    return null;
  }

  return (
    <AssetLink assetId={assetId} className={styles}>
      <div>
        <AssetName assetId={assetId} />
      </div>
      <div>
        <QuoteView instrumentId={assetId} displayLength="short" />
      </div>
      <div>
        <QuoteChange id={assetId} />
      </div>
    </AssetLink>
  );
}
