import React, { FC, useState } from "react";
import { SubmitButton } from "./submit-button";
import { addTransaction } from "../firebase/transactions";
import { usePortfolio } from "./use-portfolio";
import { format } from "date-fns";

export const SellHoldingForm: FC<{
  transactionId: string;
  onClose: () => void;
}> = ({ transactionId, onClose }) => {
  const portfolio = usePortfolio();
  const { symbol, quantity } = portfolio.getHolding(transactionId);
  const [state, setState] = useState(() => ({
    date: format(new Date(), "yyyy-MM-dd"),
    quantity: String(quantity),
    price: "",
    tags: "",
  }));
  const getOnChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setState((state) => ({ ...state, [name]: value }));
    };

  return (
    <form className="v-grid">
      <h1>Sell {symbol}</h1>
      <div className="v-grid-small">
        <label>
          Date
          <input
            placeholder="YYYY-MM-DD"
            value={state.date}
            onChange={getOnChange("date")}
          />
        </label>
        <label>
          Quantity
          <input value={state.quantity} onChange={getOnChange("quantity")} />
        </label>
        <label>
          Price
          <input value={state.price} onChange={getOnChange("price")} />
        </label>
        <label>
          Tags
          <input value={state.tags} onChange={getOnChange("tags")} />
        </label>
      </div>
      <SubmitButton
        onClick={(e) => {
          e.preventDefault();
          return addTransaction({
            portfolioId: portfolio.id,
            transaction: {
              type: "sell",
              refId: transactionId,
              symbol: symbol,
              date: state.date,
              quantity: parseFloat(state.quantity),
              price: parseFloat(state.price),
              tags: state.tags.trim() ? state.tags.trim().split(/\s*,\s*/) : [],
            },
          }).then(onClose);
        }}
      >
        Submit
      </SubmitButton>
    </form>
  );
};
