import { Holding } from "../../../portfolio/holding";
import { SellTransaction } from "../../../portfolio/transaction";

export type SaleReceipt = {
  sell: SellTransaction;
  holding: Holding;
};

export type SaleTerm = "LONG" | "SHORT";

export type SaleSummary = {
  id: string;
  disposalDate: string;
  asset: string;
  quantity: number;
  proceeds: number;
  costBasis: number;
  acquisitionDate: string;
  term: SaleTerm;
  capitalGainLoss: number;
  isWithholding: boolean;
  tags: string[];
};

export function getSaleSummaryFromReceipt({
  sell,
  holding,
}: SaleReceipt): SaleSummary {
  const proceeds = sell.quantity * sell.price;
  const costBasis = sell.quantity * holding.buy.price;

  return {
    id: sell.id,
    disposalDate: sell.date,
    asset: holding.instrumentId.symbol,
    quantity: sell.quantity,
    proceeds,
    costBasis,
    acquisitionDate: holding.buy.date,
    term: holding.isLongTerm(new Date(sell.date)) ? "LONG" : "SHORT",
    // Convert to USD
    capitalGainLoss: (proceeds - costBasis) * (sell.toUsdRate ?? 1),
    isWithholding: sell.tags.includes("withholding"),
    tags: holding.buy.tags,
  };
}
