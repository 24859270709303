import React, { CSSProperties, ReactNode } from "react";
import { AssetId } from "../../market/asset-id";
import { ROUTES } from "../../routes";

export function AssetLink({
  assetId,
  children,
  className,
  style,
}: {
  assetId: AssetId;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <a
      className={className}
      href={ROUTES.ASSET.toHref({ assetId: assetId.key })}
      style={style}
    >
      {children}
    </a>
  );
}
