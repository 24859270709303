import React, { FC } from "react";
import { Transaction } from "../../portfolio/transaction";
import { CurrencyValue } from "../currency-value";
import { css } from "emotion";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import differenceInYears from "date-fns/differenceInYears";
import { ROUTES } from "../../routes";
import { getSplitAdjusted } from "../../portfolio/get-split-adjusted";
import { getRateOfReturnForDisplay } from "../../portfolio/get-rate-of-return";
import { usePortfolio } from "../use-portfolio";
import { useModel } from "cosmonaut";
import { QuoteModel } from "../../model/quote-model";
import { AssetId, InstrumentId } from "../../market/asset-id";
import { MoneyView } from "../money-view";

const tagsStyles = css`
  display: flex;
  justify-items: start;
  margin: -4px;
  margin-top: var(--padding-xsmall);

  > * {
    border: 1px solid var(--color-4);
    border-radius: 3px;
    color: var(--color-2);
    font-size: var(--font-size-tiny);
    padding: 2px 4px;
    text-transform: uppercase;
    margin: 4px;
  }
`;

const TRANSACTION_TYPE_ICON = {
  buy: <FiPlusCircle className="color-brand" />,
  sell: <FiMinusCircle className="color-3" />,
};

const getTerm = (date: Date): string => {
  const diff = differenceInYears(new Date(), date);
  return diff === 0 ? "short term" : "long term";
};

export const TransactionRow: FC<{ transaction: Transaction }> = ({
  transaction,
}) => {
  const { type, date, quantity, price, tags } = getSplitAdjusted(transaction);
  const portfolio = usePortfolio();
  const remainingQuantity = portfolio.getHolding(transaction.id).quantity;
  const assetId = AssetId(transaction.symbol) as InstrumentId;
  const quote = useModel(QuoteModel({ id: assetId }));

  const tagsToDisplay =
    type === "buy"
      ? tags.filter((tag) => tag !== "").concat([getTerm(new Date(date))])
      : tags;

  return (
    <a href={ROUTES.TRANSACTION.toHref({ transactionId: transaction.id })}>
      <div className="row font-size-small color-3">
        <span>{date}</span>
      </div>
      <div className="row margin-top-xsmall">
        <span className="v-center">
          {TRANSACTION_TYPE_ICON[type]}
          <span>
            &nbsp;
            {quantity.toLocaleString(undefined, {
              maximumFractionDigits: 10,
            })}
            <span className="font-size-small color-3"> {assetId.symbol}</span>
          </span>
        </span>
        <span>
          <span className="font-size-small color-3">at </span>
          <MoneyView
            money={{ value: String(price), currency: "USD" }}
            displayLength="full"
            displayOriginalCurrency
          />
        </span>
      </div>
      <div className="row font-size-xsmall margin-top-xsmall">
        {type === "buy" ? (
          <div>
            Cost: <CurrencyValue value={price * quantity} />
          </div>
        ) : (
          <div>
            Realized: <CurrencyValue value={price * quantity} />
          </div>
        )}
        <div>
          {getRateOfReturnForDisplay([portfolio.getHolding(transaction.id)], {
            [AssetId(transaction.symbol).key]: quote,
          })}
        </div>
      </div>
      {type === "buy" && (
        <div
          className="font-size-xsmall margin-top-xsmall"
          style={
            remainingQuantity === 0
              ? {
                  textDecoration: "line-through",
                  color: "var(--color-2)",
                }
              : undefined
          }
        >
          Remaining:{" "}
          {remainingQuantity.toLocaleString(undefined, {
            maximumFractionDigits: 10,
          })}
        </div>
      )}
      {tags && (
        <div className={tagsStyles}>
          {tagsToDisplay.map((tag) => (
            <span key={tag}>{tag}</span>
          ))}
        </div>
      )}
    </a>
  );
};
