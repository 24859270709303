"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Route_pathParts, _Route_queryParts;
Object.defineProperty(exports, "__esModule", { value: true });
exports.route = exports.Route = void 0;
const param_1 = require("./param");
const to_url_object_1 = require("./to-url-object");
class Route {
    constructor(pathParts, queryParams) {
        _Route_pathParts.set(this, void 0);
        _Route_queryParts.set(this, void 0);
        __classPrivateFieldSet(this, _Route_pathParts, pathParts, "f");
        __classPrivateFieldSet(this, _Route_queryParts, queryParams, "f");
    }
    path(part) {
        if (typeof part === "string") {
            const staticPathParts = part
                .toLowerCase()
                .split("/")
                .filter(Boolean);
            return new Route(__classPrivateFieldGet(this, _Route_pathParts, "f").concat(staticPathParts), __classPrivateFieldGet(this, _Route_queryParts, "f"));
        }
        return new Route(__classPrivateFieldGet(this, _Route_pathParts, "f").concat([part]), __classPrivateFieldGet(this, _Route_queryParts, "f"));
    }
    query(part) {
        return new Route(__classPrivateFieldGet(this, _Route_pathParts, "f"), Object.assign(Object.assign({}, __classPrivateFieldGet(this, _Route_queryParts, "f")), { [part._name]: part }));
    }
    toHref(params) {
        // Pathname
        let pathname = "";
        for (const part of __classPrivateFieldGet(this, _Route_pathParts, "f")) {
            if (typeof part === "string") {
                pathname += "/" + encodeURIComponent(part);
            }
            else {
                const value = params[part._name];
                if (value) {
                    pathname += "/" + encodeURIComponent(value);
                }
            }
        }
        if (pathname === "") {
            pathname = "/";
        }
        // Query
        let query = "";
        for (const queryParam of Object.keys(__classPrivateFieldGet(this, _Route_queryParts, "f"))) {
            const part = __classPrivateFieldGet(this, _Route_queryParts, "f")[queryParam];
            const value = params[part._name];
            if (value) {
                query +=
                    (query ? "&" : "?") +
                        encodeURIComponent(queryParam) +
                        "=" +
                        encodeURIComponent(value);
            }
        }
        return pathname + query;
    }
    match(href) {
        const url = to_url_object_1.toUrlObject(href);
        if (url.origin !== window.location.origin) {
            return null;
        }
        // Pathname
        const subPaths = url.pathname.split("/").filter(Boolean);
        const pathMatch = matchPath(__classPrivateFieldGet(this, _Route_pathParts, "f"), subPaths);
        if (!pathMatch) {
            return null;
        }
        // Query
        const queryMatch = {};
        for (const [queryParam, part] of Object.entries(__classPrivateFieldGet(this, _Route_queryParts, "f"))) {
            const rawValue = url.searchParams.get(queryParam);
            const value = rawValue == null ? undefined : param_1.matchParam(part, rawValue);
            if (value == null) {
                if (part._required) {
                    return null;
                }
            }
            else {
                queryMatch[part._name] = value;
            }
        }
        return Object.assign(Object.assign({}, pathMatch), queryMatch);
    }
    /**
     * String representation of the route, used for debugging.
     */
    toString() {
        let s = "/" + __classPrivateFieldGet(this, _Route_pathParts, "f").map((part) => part.toString()).join("/");
        const querystring = Object.entries(__classPrivateFieldGet(this, _Route_queryParts, "f"))
            .map(([name, param]) => {
            return `${name}=${param.toString()}`;
        })
            .join("&");
        if (querystring) {
            s += "?" + querystring;
        }
        return s;
    }
}
exports.Route = Route;
_Route_pathParts = new WeakMap(), _Route_queryParts = new WeakMap();
function route(path = "") {
    return new Route([], {}).path(path);
}
exports.route = route;
function matchPath(parts, path) {
    const params = {};
    let iPart = 0;
    let iPath = 0;
    for (; iPart < parts.length && iPath < path.length; iPart++, iPath++) {
        const part = parts[iPart];
        const subPath = path[iPath];
        if (typeof part === "string") {
            if (part === subPath.toLowerCase()) {
                continue;
            }
            else {
                return null;
            }
        }
        else {
            const value = param_1.matchParam(part, subPath);
            if (value != null) {
                if (!part._required) {
                    // Not a required part, so may have to backtrack
                    const lookaheadMatch = matchPath(parts.slice(iPart + 1), path.slice(iPath + 1));
                    if (lookaheadMatch) {
                        params[part._name] = decodeURIComponent(subPath);
                        Object.assign(params, lookaheadMatch);
                    }
                    else {
                        // Skip this optional part and repeat current subPath
                        iPath--;
                    }
                }
                else {
                    params[part._name] = decodeURIComponent(value);
                }
            }
            else {
                if (part._required) {
                    return null;
                }
                else {
                    // Skip this optional part
                    continue;
                }
            }
        }
    }
    if (iPart < parts.length) {
        // Check if remaining parts are all optional
        if (parts
            .slice(iPart)
            .every((part) => typeof part !== "string" && !part._required)) {
            return params;
        }
        else {
            return null;
        }
    }
    if (iPath < path.length) {
        return null;
    }
    return params;
}
