import { currentUser$ } from "../firebase/current-user";
import { SplashScreen } from "./splash-screen";
import React, { FC } from "react";
import { SignInButton } from "./sign-in-button";
import { useObservable } from "../common/use-observable";

export const useUser = () => {
  return useObservable(currentUser$);
};

export const useUid = () => {
  const user = useObservable(currentUser$);
  if (user === "anonymous") {
    throw new Error("Not authenticated");
  }
  return user.uid;
};

export const Authenticate: FC = ({ children }) => {
  const user = useUser();
  console.log("Rendering <Authenticate />", user);

  if (user === "anonymous") {
    return (
      <SplashScreen>
        <SignInButton />
      </SplashScreen>
    );
  }

  return <>{children}</>;
};
