import { css } from "emotion";
import React, { Fragment } from "react";
import { formatNumber } from "toolbelt";
import { AssetId, isInstrument } from "../../market/asset-id";
import { Instrument } from "../../portfolio/instrument";
import { AssetName } from "../asset-name";
import { MoneyView } from "../money-view";
import { usePortfolio } from "../use-portfolio";
import { useQuotes } from "../use-quotes";
import { AssetLink } from "./asset-link";
import { PortfolioView } from "./portfolio-view";

const gridStyles = css`
  display: grid;
  font-size: 18px;
  font-weight: 100;
  grid-template-columns: 1fr auto auto;
  padding: 12px;

  > * {
    padding: 10px 12px;
  }

  > :nth-child(3n + 1) {
    font-weight: normal;
  }

  > :nth-child(3n) {
    text-align: right;
  }

  > :nth-child(3n + 2) {
    text-align: right;
  }
`;

export const PortfolioPerformanceView: PortfolioView = ({ assetIds }) => {
  const instrumentIds = assetIds.filter(isInstrument);
  const quotes = useQuotes(instrumentIds);
  const portfolio = usePortfolio();
  const rows = instrumentIds
    .map((instrumentId) => portfolio.getAsset(instrumentId))
    .filter((instrument): instrument is Instrument => instrument != null)
    .map((instrument) => {
      const value = instrument.holdings.reduce((total, holding) => {
        return (
          total +
          holding.getRealizedValue() +
          holding.getUnrealizedValue(
            quotes[AssetId(holding.symbol).key].latestPrice
          )
        );
      }, 0);
      const cost = instrument.holdings.reduce((total, holding) => {
        return total + holding.getCost();
      }, 0);
      const returnValue = value - cost;
      return {
        instrument,
        returnValue,
        returnPercent: returnValue / cost,
      };
    });

  return (
    <div className={gridStyles}>
      {rows
        .sort((a, b) => Math.abs(b.returnValue) - Math.abs(a.returnValue))
        .map((row) => (
          <Fragment key={row.instrument.id.key}>
            <AssetLink assetId={row.instrument.id}>
              <AssetName assetId={row.instrument.id} />
            </AssetLink>
            <div className={row.returnValue >= 0 ? "color-up" : "color-down"}>
              <MoneyView
                plusSign
                money={{ value: String(row.returnValue), currency: "USD" }}
              />
            </div>
            <div className={row.returnValue >= 0 ? "color-up" : "color-down"}>
              {formatNumber(row.returnPercent, {
                asPercent: true,
                plusSign: true,
                decimals: 1,
                significance: 2,
              })}
            </div>
          </Fragment>
        ))}
    </div>
  );
};
