import React from "react";
import { usePortfolio } from "../use-portfolio";
import { useFormState } from "../use-form-state";
import { updateTransaction } from "../../firebase/transactions";

export const TransactionForm = ({
  transactionId,
  isEditing,
  onSubmit,
}: {
  transactionId: string;
  isEditing: boolean;
  onSubmit: () => void;
}) => {
  const portfolio = usePortfolio();
  const transaction = portfolio.getTransaction(transactionId);
  const formState = useFormState(() => {
    return {
      date: transaction.date,
      quantity: String(transaction.quantity),
      price: String(transaction.price),
      tags: transaction.tags.join(", "),
    };
  }, [transactionId]);

  if (!transaction) {
    return null;
  }

  return (
    <form className="v-grid">
      <div className="v-grid-small">
        <label>
          Date
          <input
            {...formState.date}
            placeholder="YYYY-MM-DD"
            disabled={!isEditing}
          />
        </label>
        <label>
          Quantity
          <input {...formState.quantity} disabled={!isEditing} />
        </label>
        <label>
          Price
          <input {...formState.price} disabled={!isEditing} />
        </label>
        <label>
          Tags
          <input {...formState.tags} disabled={!isEditing} />
        </label>
      </div>
      {isEditing && (
        <div className="v-grid-small">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              const tags = formState.tags.value.trim();
              updateTransaction({
                portfolioId: portfolio.id,
                transaction: {
                  ...transaction,
                  date: formState.date.value,
                  quantity: Number(formState.quantity.value),
                  price: Number(formState.price.value),
                  tags: tags ? tags.split(/\s*,\s*/) : [],
                },
              });
              onSubmit();
            }}
          >
            Save
          </button>
          <button type="button" onClick={onSubmit}>
            Cancel
          </button>
        </div>
      )}
    </form>
  );
};
