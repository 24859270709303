import React, { FC } from "react";
import { Expandable } from "./expandable";
import { Portfolio } from "../portfolio/portfolio";
import { AssetId, toNewIdString } from "../market/asset-id";
import { CurrencyValue } from "./currency-value";
import { AllocationDescription } from "./allocation-description";
import { SetAllocationButton } from "./set-allocation-button";
import { useQuotes } from "./use-quotes";
import { formatNumber } from "toolbelt";

export const PositionAnalysis: FC<{ portfolio: Portfolio }> = ({
  portfolio,
}) => {
  const quotes = useQuotes(portfolio.instrumentIds);
  const holdings = portfolio.getHoldings();
  const value = holdings.reduce(
    (sum, holding) =>
      sum +
      holding.getUnrealizedValue(
        quotes[toNewIdString(holding.instrumentId)].latestPrice
      ),
    0
  );
  const units = holdings.reduce((sum, holding) => sum + holding.quantity, 0);
  const assetInfo = AssetId(holdings[0].symbol);
  const unrealizedCost = portfolio
    .getHoldings()
    .reduce((cost, holding) => cost + holding.getCost(), 0);

  return (
    <Expandable
      summary={
        <div className="row font-size-small color-1">
          <div className="color-2">Position</div>
          <div>
            <CurrencyValue value={value} />
          </div>
        </div>
      }
      detail={
        <>
          <div className="row margin-top-small font-size-small">
            <div>Units</div>
            <div>
              {formatNumber(units)}{" "}
              {assetInfo.type === "stock" ? "shares" : assetInfo.symbol}
            </div>
          </div>
          <div className="row margin-top-small font-size-small">
            <div>Cost</div>
            <div>
              <CurrencyValue value={unrealizedCost} />
            </div>
          </div>
          <div className="row margin-top-small font-size-small">
            <div>Allocation</div>
            <div>
              <AllocationDescription assetId={assetInfo} />
            </div>
          </div>
          <div className="v-grid margin-top-small">
            <SetAllocationButton assetId={assetInfo} />
          </div>
        </>
      }
    />
  );
};
