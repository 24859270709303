import React from "react";
import { Holding } from "../../../portfolio/holding";
import { BreakdownView } from "./breakdown";
import { SaleSummary } from "./sale-receipt";
import { getUsTaxUnderpayment } from "./us-tax-underpayment";

export function TaxUnderpaymentBreakdown({
  saleSummaries,
  incomeHoldings,
}: {
  saleSummaries: SaleSummary[];
  incomeHoldings: Holding[];
}) {
  const underpayment = getUsTaxUnderpayment({ saleSummaries, incomeHoldings });

  return (
    <BreakdownView
      breakdown={[
        { name: "Tax Underpayment", value: underpayment.underpayment },
        { name: "Total Tax", value: underpayment.totalTax },
        { name: "Tax Withheld", value: underpayment.taxWithheld },
      ]}
    />
  );
}
