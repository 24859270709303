export function downloadCSV(
  filename: string,
  headings: string[],
  rows: (string | number)[][]
) {
  const prefix = "data:text/csv;charset=utf-8,";
  const content =
    prefix +
    [headings as (string | number)[]]
      .concat(rows)
      .map((row) => row.join(","))
      .join("\n");
  download(filename, content);
}

function download(filename: string, content: string) {
  const encodedUri = encodeURI(content);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link); // Required for FF
  link.click();
}
