import React, { FC } from "react";
import { css } from "emotion";

const styles = css`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  position: relative;
`;

const barStyles = css`
  background: #808a99;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 0;
`;

const labelStyles = css`
  position: relative;
`;

export const ProgressBar: FC<{ percent: number; barClassName?: string }> = ({
  percent,
  barClassName,
  children,
}) => {
  return (
    <div className={styles}>
      <div
        className={barStyles + (barClassName ? " " + barClassName : "")}
        style={{ width: percent * 100 + "%" }}
      />
      <div className={labelStyles}>{children}</div>
    </div>
  );
};
