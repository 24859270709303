import React, {
  FC,
  ButtonHTMLAttributes,
  SyntheticEvent,
  useState,
} from "react";
import { useIsMounted } from "../common/use-is-mounted";

const wait = (time: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => Promise<unknown> | void;
  minTime?: number;
};

export const SubmitButton: FC<Props> = (props) => {
  const { onClick, children, disabled, minTime = 500, ...otherProps } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMounted = useIsMounted();

  return (
    <button
      {...otherProps}
      type="submit"
      disabled={isSubmitting || disabled}
      onClick={(e) => {
        if (!onClick) return;
        const ret = onClick(e);
        if (ret instanceof Promise) {
          setIsSubmitting(true);
          Promise.all([ret.then(() => {}), wait(minTime)]).finally(() => {
            isMounted.current && setIsSubmitting(false);
          });
        }
      }}
    >
      {children}
    </button>
  );
};
