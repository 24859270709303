import { firestore } from "firebase/app";
import { take } from "rxjs/operators";
import { CosmonautClient, model } from "cosmonaut";
import { currentUser$ } from "./current-user";
import { Currency } from "../market/money";

export type User = {
  id: string;
  recents: string[];
  displayCurrency?: Currency;
};

export const UserModel = model(
  async (): Promise<User> => {
    const user = await currentUser$.pipe(take(1)).toPromise();
    if (user === "anonymous") {
      throw new Error("Not signed in");
    }
    const snapshot = await firestore().collection("users").doc(user.uid).get();
    const data = snapshot.data() ?? {
      id: user.uid,
      recents: [],
    };
    return {
      id: snapshot.id,
      recents: data.recents ?? [],
      displayCurrency: data.displayCurrency,
    };
  },
  {
    key: "User",
    maxAge: "1h",
  }
);

const MAX_RECENTS = 50;

export const addToRecents = async (
  idString: string,
  client: CosmonautClient
) => {
  const user = await client.get(UserModel());
  const recents = user.recents
    .filter((recent) => recent !== idString)
    .slice(0, MAX_RECENTS);
  recents.unshift(idString);
  client.write(UserModel(), { ...user, recents });
  firestore()
    .collection("users")
    .doc(user.id)
    .set({ recents }, { merge: true });
};

export async function setDisplayCurrency(
  displayCurrency: Currency,
  client: CosmonautClient
) {
  const user = await client.get(UserModel());
  client.write(UserModel(), { ...user, displayCurrency });
  firestore()
    .collection("users")
    .doc(user.id)
    .set({ displayCurrency }, { merge: true });
}
