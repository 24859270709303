import { register } from "./service-worker-registration";
import { BehaviorSubject } from "rxjs";

export const getAppVersion = () => process.env.REACT_APP_VERSION || "dev";

export const updateAvailable$ = new BehaviorSubject(false);

export const install = (): void => {
  register();
};
