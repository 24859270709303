import { model, mutation } from "cosmonaut";
import { firestore } from "firebase/app";
import { AssetId, toNewIdString } from "../market/asset-id";

export type Allocations = Record<string, number>;

export const AllocationsModel = model(
  async ({ portfolioId }: { portfolioId: string }): Promise<Allocations> => {
    const doc = await firestore()
      .collection("portfolios")
      .doc(portfolioId)
      .get();
    return doc.data()?.allocations ?? {};
  },
  {
    key: "AllocationsModel",
    refreshAge: "1m",
  }
);

export const SetAllocation = mutation<{
  portfolioId: string;
  assetId: AssetId;
  targetPercent: number;
}>(async ({ portfolioId, assetId, targetPercent }, client) => {
  const value = client.read(AllocationsModel({ portfolioId }));
  const assetIdString = toNewIdString(assetId);
  const prev: Allocations = value.data ?? {};
  client.write(AllocationsModel({ portfolioId }), {
    ...prev,
    [assetIdString]: targetPercent,
  });

  await firestore()
    .collection("portfolios")
    .doc(portfolioId)
    .set(
      {
        allocations: {
          [assetIdString]: targetPercent,
        },
      },
      { merge: true }
    );

  console.log("Updated allocation with id", assetIdString);
});
