import { useCosmonaut, useModel } from "cosmonaut";
import React from "react";
import { formatNumber } from "toolbelt";
import { setDisplayCurrency, UserModel } from "../firebase/user";
import {
  exchangeMoney,
  ExchangeRatesModel,
} from "../market/exchange-rates-model";
import { CURRENCIES } from "../market/money";
import { ToggleButton, ToggleList } from "./toggle-button";

export function CurrencySelector() {
  const {
    user: { displayCurrency: _displayCurrency },
    exchangeRates,
  } = useModel({
    user: UserModel(),
    exchangeRates: ExchangeRatesModel({ baseCurrency: "USD" }),
  });
  const client = useCosmonaut();
  const displayCurrency = _displayCurrency ?? "USD";

  return (
    <>
      <h2>Display currency</h2>
      <ToggleList>
        {CURRENCIES.map((currency) => {
          return (
            <ToggleButton
              key={currency}
              isActive={currency === displayCurrency}
              onClick={() => setDisplayCurrency(currency, client)}
            >
              {currency}
            </ToggleButton>
          );
        })}
      </ToggleList>
      <div className="v-grid-small">
        {CURRENCIES.map((currency) => {
          if (currency === displayCurrency) {
            return null;
          }
          const exchanged = exchangeMoney(
            exchangeRates,
            { value: "1", currency: displayCurrency },
            currency
          );
          return (
            <div key={currency}>
              1 {displayCurrency} ={" "}
              {formatNumber(exchanged.value, { decimals: 3 })} {currency}
            </div>
          );
        })}
      </div>
    </>
  );
}
