import { get, selectModel } from "cosmonaut";
import { CryptoId, InstrumentId, StockId } from "./asset-id";
import { CoinInfoModel } from "./coin-info-model";
import { EquityListModel } from "./equity-list-model";

export type InstrumentSearchItem = {
  instrumentId: InstrumentId;
  name: string;
};

export type InstrumentSearch = Record<string, InstrumentSearchItem>;

export const InstrumentSearchModel = selectModel(() => {
  const equityList = get(EquityListModel());
  const coinInfo = get(CoinInfoModel());
  const instrumentSearch: InstrumentSearch = {};
  equityList.forEach((equity) => {
    const id = StockId(equity.symbol);
    instrumentSearch[id.key] = {
      instrumentId: id,
      name: equity.name,
    };
  });
  for (const coin of Object.values(coinInfo)) {
    const id = CryptoId(coin.symbol);
    instrumentSearch[id.key] = {
      instrumentId: id,
      name: coin.name,
    };
  }
  return instrumentSearch;
});
