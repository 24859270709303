import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firebaseConfig } from "./firebase-config";
import { currentUser$ } from "./current-user";

export const firebaseInit = (): void => {
  firebase.initializeApp(firebaseConfig);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      currentUser$.next({ uid: user.uid });
    } else {
      currentUser$.next("anonymous");
    }
  });
};
