import React, { FC } from "react";
import { css, cx } from "emotion";
import { createPortal } from "react-dom";
import { FiArrowDown } from "react-icons/fi";

const styles = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 200;
  transition: 0.2s all;

  > div {
    background: var(--color-7);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.2s all;
    transform: translate(0, 0);

    > main {
      padding: var(--padding);
      overflow: auto;
    }

    > footer {
      background: linear-gradient(90deg, var(--color-6), var(--color-7));
      height: 52px;
      display: flex;
      align-items: center;

      button {
        width: 100%;
      }
    }
  }
`;

const closedStyles = css`
  opacity: 0;
  pointer-events: none;

  > div {
    transform: translate(0, -100%);
  }
`;

const bottomStyles = css`
  > div {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
`;

const bottomClosedStyles = css`
  > div {
    transform: translate(0, 100%);
  }
`;

export type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  position?: "top" | "bottom";
  fullHeight?: boolean;
};

export const Dialog: FC<DialogProps> = ({
  isOpen,
  onClose,
  position = "bottom",
  fullHeight,
  children,
}) => {
  return createPortal(
    <div
      className={cx(styles, {
        [closedStyles]: !isOpen,
        [bottomStyles]: position === "bottom",
        [bottomClosedStyles]: !isOpen && position === "bottom",
      })}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div style={fullHeight ? { height: "100%" } : undefined}>
        {isOpen ? (
          <>
            <main>{children}</main>
            <footer>
              <button type="button" className="icon-button" onClick={onClose}>
                <FiArrowDown size="1.1em" />
              </button>
            </footer>
          </>
        ) : null}
      </div>
    </div>,
    document.body
  );
};
