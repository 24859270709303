import { useModel } from "cosmonaut";
import React from "react";
import {
  CryptoId,
  InstrumentId,
  isInstrument,
  StockId,
  toNewIdString,
} from "../../market/asset-id";
import { QuoteModel } from "../../model/quote-model";
import { MoneyView } from "../money-view";
import { QuoteChange } from "../quote-change";
import { useQuotes } from "../use-quotes";
import { AssetLink } from "./asset-link";
import { PortfolioPriceRow } from "./portfolio-price-row";
import { PortfolioView } from "./portfolio-view";

export const PortfolioPriceView: PortfolioView = ({ assetIds }) => {
  const instrumentIds = assetIds.filter(isInstrument);
  const quotes = useQuotes(instrumentIds);

  return (
    <>
      <div
        style={{
          background: "#353c45",
          display: "flex",
          fontWeight: 100,
          justifyContent: "space-between",
          padding: 24,
        }}
      >
        <FeaturedQuote id={CryptoId("BTC")} />
        <FeaturedQuote id={CryptoId("ETH")} />
        <FeaturedQuote id={StockId("SPY")} />
      </div>

      <div style={{ padding: "12px 0" }}>
        {instrumentIds
          .sort(
            (a, b) =>
              Math.abs(quotes[toNewIdString(b)].percentChange) -
              Math.abs(quotes[toNewIdString(a)].percentChange)
          )
          .map((assetId) => (
            <PortfolioPriceRow key={toNewIdString(assetId)} assetId={assetId} />
          ))}
      </div>
    </>
  );
};

function FeaturedQuote({ id }: { id: InstrumentId }) {
  const quote = useModel(QuoteModel({ id }));
  return (
    <AssetLink
      assetId={id}
      style={{
        display: "grid",
        fontSize: 18,
        gridAutoFlow: "row",
        gridGap: 8,
        justifyItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          gridGap: 4,
          fontSize: 16,
          fontWeight: "normal",
        }}
      >
        {id.symbol}
        <QuoteChange id={id} colorize />
      </div>
      <MoneyView money={quote.latest} />
    </AssetLink>
  );
}
