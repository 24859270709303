import { useModel } from "cosmonaut";
import { useMemo } from "react";
import { AssetId, InstrumentId, isSameAsset } from "../../market/asset-id";
import { AllocationsModel } from "../../portfolio/allocations";
import { usePortfolio } from "../use-portfolio";

export function usePortfolioAssets(showDisposedAssets = false) {
  const portfolio = usePortfolio();
  let instrumentsInPortfolio = portfolio.instrumentIdsInclStockspot;
  if (!showDisposedAssets) {
    instrumentsInPortfolio = instrumentsInPortfolio.filter(
      (instrumentId) => (portfolio.getAsset(instrumentId)?.quantity ?? 0) > 0
    );
  }
  const allocations = useModel(AllocationsModel({ portfolioId: portfolio.id }));
  const instrumentsNotInPortfolio = useMemo(() => {
    return Object.keys(allocations)
      .filter((idString) => allocations[idString] > 0)
      .map((idString) => AssetId(idString))
      .filter(
        (assetId) =>
          assetId.type !== "account" &&
          !instrumentsInPortfolio.some((currentId) =>
            isSameAsset(currentId, assetId)
          )
      ) as InstrumentId[];
  }, [allocations, instrumentsInPortfolio]);

  return (instrumentsInPortfolio as AssetId[])
    .concat(portfolio.accountIds)
    .concat(instrumentsNotInPortfolio);
}
