import { useModel } from "cosmonaut";
import React from "react";
import { AssetId, toNewIdString } from "../market/asset-id";
import { AllocationsModel } from "../portfolio/allocations";
import { usePortfolio } from "./use-portfolio";
import { useAssetValue } from "./use-asset-value";
import { usePortfolioValue } from "./use-portfolio-value";
import { formatNumber } from "toolbelt";

export function AllocationDescription({ assetId }: { assetId: AssetId }) {
  const portfolio = usePortfolio();
  const allocations = useModel(AllocationsModel({ portfolioId: portfolio.id }));
  const targetPercent = allocations[toNewIdString(assetId)];
  const portfolioValue = usePortfolioValue();
  const currentValue = useAssetValue(assetId);
  const currentPercent = currentValue / portfolioValue;

  if (!targetPercent) {
    return (
      <div>
        {formatNumber(currentPercent, {
          asPercent: true,
          significance: 2,
        })}{" "}
        of portfolio
      </div>
    );
  }

  const allocationRatio = currentPercent / targetPercent;
  const color =
    allocationRatio > 1.2
      ? "var(--color-up)"
      : allocationRatio < 0.8
      ? "var(--color-down)"
      : "inherit";

  return (
    <div>
      <span style={{ color }}>
        {formatNumber(currentPercent, {
          asPercent: true,
          significance: 2,
        })}
      </span>
      {" / "}
      {formatNumber(targetPercent, { asPercent: true, significance: 2 })} target
    </div>
  );
}
