import { useModel } from "cosmonaut";
import { css } from "emotion";
import React, { Fragment } from "react";
import { formatNumber } from "toolbelt";
import { AssetId, isInstrument, toNewIdString } from "../../market/asset-id";
import { ExchangeRatesModel } from "../../market/exchange-rates-model";
import { Account } from "../../portfolio/account";
import { AllocationsModel } from "../../portfolio/allocations";
import { AssetName } from "../asset-name";
import { MoneyView } from "../money-view";
import { ProgressBar } from "../progress-bar";
import { SetAllocationForm } from "../set-allocation-form";
import { useDialog } from "../use-dialog";
import { usePortfolio } from "../use-portfolio";
import { usePortfolioValue } from "../use-portfolio-value";
import { useQuotes } from "../use-quotes";
import { AssetLink } from "./asset-link";
import { PortfolioView } from "./portfolio-view";

const gridStyles = css`
  align-items: center;
  display: grid;
  font-size: 18px;
  font-weight: 100;
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 12px;

  > * {
    overflow: hidden;
    padding: 4px 12px;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PortfolioAllocationView: PortfolioView = ({ assetIds }) => {
  const instrumentIds = assetIds.filter(isInstrument);
  const quotes = useQuotes(instrumentIds);
  const portfolio = usePortfolio();
  const portfolioValue = usePortfolioValue();
  const exchangeRates = useModel(ExchangeRatesModel({ baseCurrency: "USD" }));
  const allocations = useModel(AllocationsModel({ portfolioId: portfolio.id }));
  const rows = assetIds
    .filter((assetId) => assetId.type !== "stockspot")
    .map((assetId) => {
      const asset = portfolio.getAsset(assetId);
      const value = !asset
        ? 0
        : asset instanceof Account
        ? asset.value / exchangeRates[asset.currency]
        : asset.getValue(quotes[toNewIdString(asset.id)].latestPrice);
      return {
        assetId,
        target: allocations[toNewIdString(assetId)] ?? 0,
        current: value / portfolioValue,
        value,
      };
    });
  const [allocationDialog, showAllocationDialog] = useDialog<AssetId>(
    ({ onClose, params }) => (
      <SetAllocationForm onClose={onClose} assetId={params} />
    )
  );

  const totalCurrent = rows.reduce((total, row) => total + row.current, 0);
  const max = rows.reduce((memo, row) => Math.max(memo, row.current), 0);

  function renderCurrentCell(assetId: AssetId | null, value: number) {
    return (
      <div onClick={() => assetId && showAllocationDialog(assetId)}>
        {formatNumber(value, {
          asPercent: true,
          nullifyZero: true,
          decimals: 1,
          significance: 2,
        })}
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          fontSize: 18,
          justifyContent: "center",
          padding: "18px 24px",
        }}
      >
        <div
          style={{
            marginRight: 24,
            opacity: 0.5,
            gridColumnEnd: "span 2",
            textAlign: "right",
          }}
        >
          Total
        </div>
        {renderCurrentCell(null, totalCurrent)}
      </div>
      <div className={gridStyles}>
        {rows
          .sort((a, b) => {
            return b.current - a.current;
          })
          .map((row) => (
            <Fragment key={toNewIdString(row.assetId)}>
              <AssetLink
                assetId={row.assetId}
                style={{
                  fontWeight: "normal",
                  textAlign: "left",
                }}
              >
                <AssetName assetId={row.assetId} />
              </AssetLink>
              {renderCurrentCell(row.assetId, row.current)}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  gridColumnEnd: "span 3",
                  height: 28,
                  padding: "0 12px 12px",
                }}
              >
                <MoneyView
                  className={css({
                    fontSize: 16,
                    opacity: 0.8,
                    textAlign: "left",
                    width: 60,
                    marginRight: 8,
                  })}
                  money={{ value: String(row.value), currency: "USD" }}
                  whenPrivate={null}
                />
                {row.target > 0 ? (
                  <div style={{ height: 12, flexGrow: 1 }}>
                    <ProgressBar percent={Math.min(1, row.current / max)} />
                  </div>
                ) : null}
              </div>
            </Fragment>
          ))}
      </div>
      {allocationDialog}
    </>
  );
};
