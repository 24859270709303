import { css } from "emotion";
import React, { useState } from "react";
import { addToRecents, UserModel } from "../firebase/user";
import { useModel, useCosmonaut } from "cosmonaut";
import { ROUTES } from "../routes";
import {
  InstrumentSearch,
  InstrumentSearchItem,
  InstrumentSearchModel,
} from "../market/instrument-search-model";

const inputContainerStyles = css`
  background: var(--color-7);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: sticky;
  top: calc(-1 * var(--padding));
  margin-top: calc(-1 * var(--padding));
  padding: var(--padding) 0;
`;

export function InstrumentSearchView({ onSelect }: { onSelect?: () => void }) {
  const asyncInstrumentSearch = useModel(InstrumentSearchModel(), {
    async: true,
  });
  const user = useModel(UserModel());
  const [searchTerms, setSearchTerms] = useState("");
  const client = useCosmonaut();

  if (asyncInstrumentSearch.error) {
    return <div>Error loading instruments</div>;
  }

  return (
    <>
      <div className={inputContainerStyles}>
        <input
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.currentTarget.value)}
          placeholder="Search..."
        />
      </div>
      <div className="v-grid">
        {asyncInstrumentSearch.data
          ? search(asyncInstrumentSearch.data, user.recents, searchTerms).map(
              ({ instrumentId, name }) => {
                return (
                  <a
                    key={instrumentId.key}
                    className="v-grid-xsmall"
                    href={ROUTES.ASSET.toHref({ assetId: instrumentId.key })}
                    onClick={() => {
                      onSelect && onSelect();
                      addToRecents(instrumentId.key, client);
                    }}
                  >
                    <div>{instrumentId.symbol}</div>
                    <div className="font-size-xsmall">{name}</div>
                  </a>
                );
              }
            )
          : "Loading..."}
      </div>
    </>
  );
}

function search(
  instrumentSearch: InstrumentSearch,
  recents: string[],
  terms: string
): InstrumentSearchItem[] {
  const s = terms.trim().toLocaleUpperCase();
  if (!s) {
    return recents
      .map((idKey) => instrumentSearch[idKey])
      .filter((idKey) => !!idKey);
  }
  const exacts: InstrumentSearchItem[] = [];
  const startsWiths: InstrumentSearchItem[] = [];
  const contains: InstrumentSearchItem[] = [];
  Object.values(instrumentSearch).forEach((item) => {
    const symbol = item.instrumentId.symbol;
    const name = item.name.toLocaleUpperCase();
    if (symbol === s || name === s) {
      exacts.push(item);
    } else if (symbol.startsWith(s) || name.startsWith(s)) {
      startsWiths.push(item);
    } else if (s.length > 1 && name.includes(s)) {
      contains.push(item);
    }
  });
  return exacts.concat(startsWiths).concat(contains).slice(0, 50);
}
