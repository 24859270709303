import { AssetId, InstrumentId } from "../market/asset-id";
import { Holding } from "./holding";
import { Transaction } from "./transaction";

export class Instrument {
  holdings: Holding[];
  transactions: Transaction[];

  constructor(holdings: Holding[], transactions: Transaction[]) {
    this.holdings = holdings;
    this.transactions = transactions;
  }

  get id(): InstrumentId {
    return AssetId(this.symbol) as InstrumentId;
  }

  get symbol(): string {
    return this.holdings[0].symbol;
  }

  get quantity(): number {
    return this.holdings.reduce(
      (total, holding) => total + holding.quantity,
      0
    );
  }

  getValue(price: number) {
    return this.quantity * price;
  }
}
