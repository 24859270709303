import { usePortfolio } from "./use-portfolio";
import { AssetId } from "../market/asset-id";
import { usePortfolioValue } from "./use-portfolio-value";
import { useDebugValue } from "react";

export const useAssetValue = (assetId: AssetId): number => {
  const portfolio = usePortfolio();
  const assetPortfolio = portfolio.filterByAsset(assetId);
  useDebugValue(assetPortfolio);
  return usePortfolioValue(assetPortfolio);
};
