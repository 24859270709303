import { Holding } from "./holding";
import { partition, Dictionary } from "lodash";
import { Quote } from "../market/get-quote";
import { toNewIdString } from "../market/asset-id";

type TaxRate = {
  short: number;
  long: number;
};

const STATE_TAX_RATE: TaxRate = {
  short: 0.123,
  long: 0.123,
};

const FEDERAL_TAX_RATE: TaxRate = {
  short: 0.37,
  long: 0.2,
};

export const getTaxByTerm = (
  holdings: Holding[],
  prices: Dictionary<Quote>
): { shortTermTax: number; longTermTax: number } => {
  const [longTermGains, shortTermGains] = partition(
    holdings,
    (holding) => holding.isLongTerm()
  ).map((holdings) =>
    holdings.reduce(
      (total, holding) =>
        total +
        holding.getUnrealizedValue(
          prices[toNewIdString(holding.instrumentId)].latestPrice
        ) -
        holding.getUnrealizedValue(holding.buy.price),
      0
    )
  );

  const shortTermTax =
    shortTermGains * STATE_TAX_RATE.short +
    shortTermGains * FEDERAL_TAX_RATE.short;
  const longTermTax =
    longTermGains * STATE_TAX_RATE.long + longTermGains * FEDERAL_TAX_RATE.long;

  return { shortTermTax, longTermTax };
};
