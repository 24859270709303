import { css, cx } from "emotion";
import React, { ReactNode } from "react";

const styles = {
  list: css`
    display: flex;
    font-size: var(--font-size-small);
    flex-wrap: wrap;
    text-transform: uppercase;
    margin: calc(-1 * var(--padding-xsmall));
  `,
  button: css`
    border: 1px solid var(--color-6);
    border-radius: 5px;
    cursor: default;
    margin: var(--padding-xsmall);
    padding: 4px 8px;
    white-space: nowrap;
    transition: 0.2s all;
  `,
  activeButton: css`
    background: var(--color-brand);
    border-color: var(--color-brand);
    color: black;
  `,
};

export function ToggleList({ children }: { children: ReactNode }) {
  return <div className={styles.list}>{children}</div>;
}

export function ToggleButton({
  isActive,
  onClick,
  children,
}: {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
}) {
  return (
    <div
      className={cx(styles.button, isActive ? styles.activeButton : "")}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
