import { firestore } from "firebase/app";
import { Observable } from "rxjs";
import { Portfolio, PortfolioData } from "../portfolio/portfolio";
import { Transaction } from "../portfolio/transaction";
import { AccountData, AccountRecord } from "../portfolio/account";
import { Dictionary } from "lodash";

export const getPortfolio = (uid: string) => {
  const data: {
    portfolio?: Pick<PortfolioData, "id" | "roles">;
    transactions?: PortfolioData["transactions"];
    accounts?: PortfolioData["accounts"];
    accountRecords?: PortfolioData["accountRecords"];
  } = {};

  return new Observable<Portfolio>((sub) => {
    firestore()
      .collection("portfolios")
      .where(`roles.${uid}`, "==", "owner")
      .limit(1)
      .get()
      .then((portfolioSnapshots) => {
        if (portfolioSnapshots.empty) {
          throw new Error(`No portfolio found for user ${uid}`);
        }

        const portfolioSnapshot = portfolioSnapshots.docs[0];
        const portfolioData = portfolioSnapshot.data();
        data.portfolio = {
          id: portfolioSnapshot.id,
          roles: portfolioData.roles,
        };

        function nextPortfolio() {
          if (
            data.portfolio &&
            data.transactions &&
            data.accounts &&
            data.accountRecords
          ) {
            sub.next(
              new Portfolio({
                ...data.portfolio,
                transactions: data.transactions,
                accounts: data.accounts,
                accountRecords: data.accountRecords,
              })
            );
          }
        }

        portfolioSnapshot.ref
          .collection("transactions")
          .onSnapshot((transactionsSnapshot) => {
            data.transactions = transactionsSnapshot.docs.reduce(
              (obj: Dictionary<Transaction>, t) => {
                const transaction = { id: t.id, ...t.data() } as Transaction;
                if (!transaction.tags) {
                  transaction.tags = [];
                }
                obj[t.id] = transaction;
                return obj;
              },
              {}
            );
            nextPortfolio();
          });

        portfolioSnapshot.ref
          .collection("accounts")
          .onSnapshot((accountsSnapshot) => {
            data.accounts = accountsSnapshot.docs.reduce(
              (obj: Dictionary<AccountData>, snapshot) => {
                const accountInfo = {
                  id: snapshot.id,
                  tags: [] as string[],
                  ...snapshot.data(),
                } as AccountData;
                obj[accountInfo.id] = accountInfo;
                return obj;
              },
              {}
            );
            nextPortfolio();
          });

        portfolioSnapshot.ref
          .collection("accountRecords")
          .onSnapshot((accountRecordsSnapshot) => {
            data.accountRecords = accountRecordsSnapshot.docs.reduce(
              (obj: Dictionary<AccountRecord>, snapshot) => {
                const accountRecord = {
                  id: snapshot.id,
                  ...snapshot.data(),
                } as AccountRecord;
                obj[accountRecord.id] = accountRecord;
                return obj;
              },
              {}
            );
            nextPortfolio();
          });
      });
  });
};
