import React from "react";
import { Holding } from "../../../portfolio/holding";
import { BreakdownView } from "./breakdown";
import { SaleSummary } from "./sale-receipt";
import { getUsTax } from "./us-tax";

export function UsTaxBreakdown({
  saleSummaries,
  incomeHoldings,
}: {
  saleSummaries: SaleSummary[];
  incomeHoldings: Holding[];
}) {
  const usTax = getUsTax(saleSummaries, incomeHoldings);

  return (
    <BreakdownView
      breakdown={[
        { name: "Federal Tax", value: usTax.total },
        { name: "Income Tax", value: usTax.incomeTax },
        { name: "CGT", value: usTax.cgt },
        { name: "NIIT", value: usTax.niit },
        { name: "Foreign Tax Credits", value: usTax.foreignTaxCredits },
        { name: "Applied FTC", value: usTax.appliedForeignTaxCredits },
      ]}
    />
  );
}
