import React, { Component } from "react";

export class ErrorBoundary extends Component {
  state: { error: any | null } = { error: null };

  componentDidCatch(error: any) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return error ? (
      <div className="padding">
        <h1>Error</h1>
        <code>
          <pre
            style={{
              whiteSpace: "break-spaces",
              wordBreak: "break-all",
            }}
          >
            {error.stack ? error.stack : String(error)}
          </pre>
        </code>
      </div>
    ) : (
      children
    );
  }
}
