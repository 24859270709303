import { BuyTransaction, SellTransaction, Transaction } from "./transaction";
import differenceInYears from "date-fns/differenceInYears";
import { mapValues, Dictionary } from "lodash";
import { getSplitAdjusted } from "./get-split-adjusted";
import { AssetId, InstrumentId } from "../market/asset-id";

export class Holding {
  instrumentId: InstrumentId;
  symbol: string;
  buy: BuyTransaction;
  sells: SellTransaction[];
  tags: string[];

  constructor(buy: BuyTransaction, sells: SellTransaction[]) {
    this.instrumentId = AssetId(buy.symbol) as InstrumentId;
    this.symbol = buy.symbol;
    this.buy = getSplitAdjusted(buy);
    this.sells = sells.map(getSplitAdjusted);
    this.tags = buy.tags;
  }

  get id() {
    return this.buy.id;
  }

  isLongTerm(date = new Date()): boolean {
    return differenceInYears(date, new Date(this.buy.date)) >= 1;
  }

  get quantity(): number {
    return (
      this.buy.quantity -
      this.sells.reduce((sum, sell) => sum + sell.quantity, 0)
    );
  }

  getCost(): number {
    return this.isIncome() ? 0 : this.getCostBasis();
  }

  getCostBasis(): number {
    return this.buy.quantity * this.buy.price;
  }

  getRealizedValue(): number {
    return this.sells.reduce(
      (sum, sell) => sum + sell.price * sell.quantity,
      0
    );
  }

  getUnrealizedValue(price: number): number {
    return this.quantity * price;
  }

  isIncome(): boolean {
    return (
      this.tags.find(
        (tag) => tag === "staking" || tag === "dividend" || tag === "vest"
      ) != null
    );
  }
}

class HoldingBuilder {
  buy: BuyTransaction | undefined;
  sells: SellTransaction[] = [];

  toHolding(): Holding {
    if (!this.buy) {
      throw new Error(
        `Invalid holding. ${this.sells.length} sell transaction(s) encountered but no corresponding buy transaction was found with id ${this.sells[0].refId}`
      );
    }
    return new Holding(this.buy, this.sells);
  }
}

export const getHoldingMap = (
  transactions: Dictionary<Transaction>
): Dictionary<Holding> => {
  const builders: { [buyId: string]: HoldingBuilder } = {};

  Object.values(transactions).forEach((transaction) => {
    if (transaction.type === "buy") {
      if (!builders[transaction.id]) {
        builders[transaction.id] = new HoldingBuilder();
      }
      builders[transaction.id].buy = transaction;
    } else {
      if (!builders[transaction.refId]) {
        builders[transaction.refId] = new HoldingBuilder();
      }
      builders[transaction.refId].sells.push(transaction);
    }
  });

  return mapValues(builders, (builder) => builder.toHolding());
};
