"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Match = void 0;
const router_1 = require("./router");
function Match({ route, children, }) {
    const router = router_1.useRouter();
    const params = router.match(route);
    if (params) {
        if (typeof children === "function") {
            return children({ params });
        }
        return children !== null && children !== void 0 ? children : null;
    }
    return null;
}
exports.Match = Match;
