"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutation = void 0;
function mutation(run) {
    const mutation = ((params) => {
        return {
            mutation,
            params,
        };
    });
    Object.defineProperties(mutation, {
        run: {
            enumerable: false,
            value: run,
        },
    });
    return mutation;
}
exports.mutation = mutation;
