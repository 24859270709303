export type TaxCountry = "AU" | "US";

export type TaxPeriod = {
  currentDate: Date;
  taxCountry: TaxCountry;
  taxYearOffset: number; // offset from current tax year
};

export function getTaxYear({
  currentDate,
  taxCountry,
  taxYearOffset,
}: TaxPeriod): number {
  let offset = taxYearOffset;
  if (taxCountry === "AU" && currentDate.getMonth() >= 6) {
    offset++;
  }
  return currentDate.getFullYear() + offset;
}

export function getTaxPeriodDates(taxPeriod: TaxPeriod): [string, string] {
  const taxYear = getTaxYear(taxPeriod);
  const { taxCountry } = taxPeriod;
  if (taxCountry === "AU") {
    return [`${taxYear - 1}-07-01`, `${taxYear}-06-30`];
  }
  return [`${taxYear}-01-01`, `${taxYear}-12-31`];
}
