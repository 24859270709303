"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectModel = void 0;
const value_1 = require("./value");
const create_model_1 = require("./create-model");
const query_key_1 = require("./query-key");
const get_1 = require("./get");
const BLOCKER = {};
const LAZY_BLOCKER = new Proxy({}, {
    get(target, prop) {
        throw BLOCKER;
    },
});
function selectModel(selector, config = {}) {
    return create_model_1.createModel(Object.assign(Object.assign({}, config), { schema: undefined, read: (query, context) => {
            let updatedAt = Date.now();
            try {
                const data = get_1.runWithGet(() => selector(query.params), (subQuery) => {
                    const value = context.read(subQuery);
                    if (value_1.isLoadableValue(value) || value_1.isLoadingValue(value)) {
                        throw BLOCKER;
                    }
                    if (value_1.isDataValue(value)) {
                        // A select-model is only as up-to-date as its oldest sub-model
                        updatedAt = Math.min(updatedAt, value.updatedAt);
                        return value.data;
                    }
                    if (value_1.isErrorValue(value)) {
                        throw value.error;
                    }
                    const _ = value;
                    return _;
                });
                return value_1.DataValue(data, updatedAt);
            }
            catch (thrown) {
                if (thrown === BLOCKER) {
                    return undefined;
                }
                return value_1.ErrorValue(thrown, updatedAt);
            }
        }, load: (query, context) => {
            let aborted = false;
            let tryLoadCount = 0;
            function tryLoad() {
                tryLoadCount++;
                let loadingPromises = [];
                try {
                    get_1.runWithGet(() => selector(query.params), (subQuery) => {
                        var _a;
                        // TODO use info from this model's own read() instead?
                        const value = context.read(Object.assign({ maxAge: (_a = subQuery.maxAge) !== null && _a !== void 0 ? _a : query.maxAge }, subQuery));
                        if (value_1.isLoadableValue(value)) {
                            loadingPromises.push(value.load().loading);
                            return LAZY_BLOCKER;
                        }
                        if (value_1.isLoadingValue(value)) {
                            loadingPromises.push(value.loading);
                            return LAZY_BLOCKER;
                        }
                        if (value_1.isErrorValue(value)) {
                            throw value.error;
                        }
                        if (value_1.isDataValue(value)) {
                            return value.data;
                        }
                        const _ = value;
                        return _;
                    });
                    if (loadingPromises.length) {
                        throw BLOCKER;
                    }
                }
                catch (thrown) {
                    if (thrown === BLOCKER) {
                        Promise.race(loadingPromises)
                            .catch(() => { })
                            .then(() => {
                            if (aborted)
                                return;
                            if (tryLoadCount >= 10000) {
                                throw new Error(`Exceeded maximum call stack trying to load ${query_key_1.getQueryKey(query)}`);
                            }
                            tryLoad();
                        });
                    }
                }
            }
            tryLoad();
            return () => {
                aborted = true;
            };
        }, write(query, dataOrError, context) {
            if (typeof config.set !== "function") {
                throw new Error(`Can't write ${query_key_1.getQueryKey(query)} because the model doesn't implement set()`);
            }
            config.set(query.params, dataOrError, context);
        },
        invalidate(query, context) {
            try {
                get_1.runWithGet(() => selector(query.params), (subQuery) => {
                    const value = context.read(subQuery);
                    // Invalidate each subQuery
                    context.invalidate(subQuery);
                    if (value_1.isDataValue(value)) {
                        return value.data;
                    }
                    throw BLOCKER;
                });
            }
            catch (e) {
                // Thrown errors or promises don't matter
            }
        } }));
}
exports.selectModel = selectModel;
