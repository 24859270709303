import React from "react";
import { css } from "emotion";
import { SettingsButton } from "./settings-button";
import { SearchEquityButton } from "./search-equity-button";
import { AddAssetButton } from "./add-asset-button";

const styles = css`
  background: linear-gradient(90deg, var(--color-6), var(--color-7));
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: var(--padding);
  }
`;

export const AppFooter = () => {
  return (
    <footer className={styles}>
      <AddAssetButton />
      <SearchEquityButton />
      <SettingsButton />
    </footer>
  );
};
