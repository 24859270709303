import { usePortfolio } from "./use-portfolio";
import { useModel } from "cosmonaut";
import { ExchangeRatesModel } from "../market/exchange-rates-model";
import { Portfolio } from "../portfolio/portfolio";
import { useDebugValue } from "react";
import { useQuotes } from "./use-quotes";
import { toNewIdString } from "../market/asset-id";

export const usePortfolioValue = (_portfolio?: Portfolio): number => {
  const fullPortfolio = usePortfolio();
  const portfolio = _portfolio ?? fullPortfolio;
  const quotes = useQuotes(portfolio.instrumentIds);
  const exchangeRates = useModel(ExchangeRatesModel({ baseCurrency: "USD" }));
  const assetValue = portfolio.instrumentIds.reduce((total, instrumentId) => {
    const quote = quotes[toNewIdString(instrumentId)]?.latestPrice;
    const value = portfolio.getAsset(instrumentId)?.getValue(quote) ?? 0;
    return total + value;
  }, 0);
  const accountValue = Object.values(portfolio.accounts).reduce(
    (total, account) => total + account.value / exchangeRates[account.currency],
    0
  );
  const value = assetValue + accountValue;
  useDebugValue(value);
  return value;
};
