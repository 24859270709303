import React, { FC } from "react";
import { formatNumber } from "toolbelt";
import { CurrencyValue } from "./currency-value";

export const CurrencyAndPercentValue: FC<{
  value: number;
  total: number;
  plusSign?: boolean;
  maximumFractionDigits?: number;
}> = ({ value, total, plusSign, maximumFractionDigits }) => {
  return (
    <>
      <CurrencyValue value={value} plusSign={plusSign} /> (
      {formatNumber(total ? value / total : 0, {
        asPercent: true,
        significance: 3,
        plusSign,
      })}
      )
    </>
  );
};
