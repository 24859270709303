"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runWithGet = exports.get = void 0;
let getFn = null;
const get = (query) => {
    if (!getFn) {
        throw new Error("Invalid get() call – must be inside a useModel or selectModel");
    }
    return getFn(query);
};
exports.get = get;
function runWithGet(fn, get) {
    getFn = get;
    try {
        return fn();
    }
    finally {
        getFn = null;
    }
}
exports.runWithGet = runWithGet;
