import React, { ReactNode, useState, useCallback, useRef } from "react";
import { Dialog, DialogProps } from "./dialog";
import { ErrorBoundary } from "./error-boundary";

export function useDialog<P = void, R = void>(
  getDialogContent: (args: {
    onClose: (value?: R) => void;
    params: P;
  }) => ReactNode,
  dialogOptions: Omit<Omit<DialogProps, "isOpen">, "onClose"> = {}
): [ReactNode | null, (params: P) => Promise<R | undefined>] {
  const [openState, setOpenState] = useState<{ params: P } | null>(null);
  const resolvePromise = useRef<((value: R | undefined) => void) | null>(null);
  const open = useCallback((params: P) => {
    const promise = new Promise<R | undefined>((resolve) => {
      resolvePromise.current = resolve;
    });
    setOpenState({ params });
    return promise;
  }, []);
  const close = useCallback((value?: R) => {
    setOpenState(null);
    if (resolvePromise.current) {
      resolvePromise.current(value);
    }
  }, []);
  const dialog = (
    <Dialog isOpen={openState !== null} onClose={close} {...dialogOptions}>
      {openState && (
        <ErrorBoundary>
          {getDialogContent({ onClose: close, params: openState.params })}
        </ErrorBoundary>
      )}
    </Dialog>
  );
  return [dialog, open];
}
