import { model } from "cosmonaut";
import { jsonFetch } from "../common/json-fetch";
import { url } from "../common/url";
import { cryptoCompareApiKey } from "./api-tokens";

type ApiCoinInfo = {
  Data: Record<
    string,
    {
      Symbol: string;
      CoinName: string;
      Description: string;
      IsTrading: boolean;
      ImageUrl: string; // append to https://www.cryptocompare.com
      PlatformType?: "token" | "blockchain";
      BuiltOn?: string;
      SmartContractAddress?: string;
      TotalCoinsMined?: number;
      MaxSupply?: number; // -1 for no max
      DecimalPoints?: number;
      IsUsedInDefi?: number;
      ProofType?: string;
      Algorithm?: string;
      AssetLaunchDate?: string;
    }
  >;
};

export type CoinInfoItem = {
  symbol: string;
  name: string;
  description: string;
  imageUrl: string;
  platform?: string;
  totalCoinsMined?: number;
  maxSupply?: number;
};

export const CoinInfoModel = model<Record<string, CoinInfoItem>>(
  () =>
    jsonFetch<ApiCoinInfo>(
      url`https://min-api.cryptocompare.com/data/all/coinlist`,
      {
        headers: {
          authorization: `Apikey ${cryptoCompareApiKey}`,
        },
      }
    ).then((data) => {
      const coinInfo: Record<string, CoinInfoItem> = {};
      Object.values(data.Data).forEach((item) => {
        if (item.IsTrading) {
          coinInfo[item.Symbol] = {
            symbol: item.Symbol,
            name: item.CoinName,
            description: item.Description,
            imageUrl: `https://www.cryptocompare.com${item.ImageUrl}`,
            platform: item.BuiltOn,
            totalCoinsMined: item.TotalCoinsMined,
            maxSupply: item.MaxSupply,
          };
        }
      });
      return coinInfo;
    }),
  {
    maxAge: 0,
    refreshAge: "1w",
  }
);
