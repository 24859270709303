import { DependencyList, useMemo } from "react";
import { useDerivedState } from "../common/use-derived-state";

export type FormState<T extends {}> = {
  [key in keyof T]: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
};

export function useFormState<T extends {}>(
  initialState: T | (() => T),
  dependencies?: DependencyList
): FormState<T> {
  const [formValues, setFormValues] = useDerivedState<T>(
    initialState,
    dependencies
  );

  return useMemo(() => {
    const formState: Partial<FormState<T>> = {};
    for (const [name, value] of Object.entries(formValues)) {
      formState[name as keyof T] = {
        value: typeof value === "string" ? value : String(value),
        onChange: (e) => {
          setFormValues((values) => ({
            ...values,
            [name]: e.currentTarget.value,
          }));
        },
      };
    }
    return formState as FormState<T>;
  }, [formValues, setFormValues]);
}
