import { Transaction } from "./transaction";
import { SPLITS } from "../market/splits";
import { isAfter } from "date-fns";
import produce from "immer";

export function getSplitAdjusted<T extends Transaction>(transaction: T): T {
  const splits = SPLITS[transaction.symbol];
  if (!splits || !splits.length) {
    return transaction;
  }

  return produce(transaction, (draft) => {
    splits.forEach((split) => {
      if (isAfter(new Date(split.date), new Date(transaction.date))) {
        const ratio = split.from / split.to;
        draft.quantity /= ratio;
        draft.price *= ratio;
      }
    });
  });
}
