import React, { FC } from "react";
import { CurrencyValue } from "./currency-value";
import { Expandable } from "./expandable";
import { getTaxByTerm } from "../portfolio/get-tax-by-term";
import { css } from "emotion";
import { ProgressBar } from "./progress-bar";
import { Portfolio } from "../portfolio/portfolio";
import { SimulateTaxButton } from "./simulate-tax-button";
import { useQuotes } from "./use-quotes";

const termBarStyles = css`
  background: var(--color-brand);
  border-radius: 4px;
`;

export const TaxAnalysis: FC<{ portfolio: Portfolio }> = ({ portfolio }) => {
  const quotes = useQuotes(portfolio.instrumentIds);
  const { shortTermTax, longTermTax } = getTaxByTerm(
    portfolio.getHoldings(),
    quotes
  );

  return (
    <Expandable
      summary={
        <div className="row font-size-small color-1">
          <div className="color-2">Tax liability</div>
          <div>
            <CurrencyValue value={shortTermTax + longTermTax} />
          </div>
        </div>
      }
      detail={
        <>
          <div className="row margin-top-small font-size-small">
            <div>
              <div className="font-size-xsmall color-4">SHORT TERM TAX</div>
              <CurrencyValue value={shortTermTax} />
            </div>
            <div>
              <div className="font-size-xsmall color-4">LONG TERM TAX</div>
              <CurrencyValue value={longTermTax} />
            </div>
          </div>
          <div
            className="margin-top-small bg-4"
            style={{ height: 8, borderRadius: 4 }}
          >
            <ProgressBar
              percent={longTermTax / (longTermTax + shortTermTax)}
              barClassName={termBarStyles}
            />
          </div>
          <div className="row margin-top-small font-size-small">
            <SimulateTaxButton portfolio={portfolio} />
          </div>
        </>
      }
    />
  );
};
