import React, { Fragment, useState } from "react";
import { IncomeStatementsModel } from "../../market/income-statements-model";
import { get, selectModel, useModel } from "cosmonaut";
import { Chart } from "../../common/chart";
import { ToggleButton } from "./toggle-button";
import { formatNumber } from "toolbelt";

const colors = ["var(--color-brand)", "#4b86d0"];

const IncomeChartModel = selectModel(({ symbol }: { symbol: string }) => {
  const incomeStatements = get(IncomeStatementsModel({ symbol }));
  const revenues = incomeStatements
    .map((incomeStatement, i) => ({
      x: 3 - (i % 4),
      y: incomeStatement.totalRevenue,
      label: `Q${incomeStatement.fiscalQuarter}`,
      isPreviousYear: i > 3,
    }))
    .reverse();
  const earnings = incomeStatements
    .map((incomeStatement, i) => ({
      x: 3 - (i % 4),
      y: incomeStatement.netIncomeBasic,
      label: `Q${incomeStatement.fiscalQuarter}`,
      isPreviousYear: i > 3,
    }))
    .reverse();
  return [revenues, earnings];
});

export function IncomeChart({ symbol }: { symbol: string }) {
  const incomeChart = useModel(IncomeChartModel({ symbol }), {
    async: true,
  });
  const [activeSeries, setActiveSeries] = useState<number | null>(null);
  const data = incomeChart.data
    ? incomeChart.data.map((series, index) => {
        if (activeSeries != null && activeSeries !== index) {
          return [];
        }
        return series;
      })
    : [[], []];

  return (
    <div className="v-grid-small">
      <div style={{ height: 200, margin: "0 calc(-1 * var(--padding))" }}>
        <Chart
          data={data}
          spans={[{ y: 0 }]}
          padding={{ top: 6, bottom: 36, left: 30, right: 80 }}
        >
          {({ scaled, height, width, yScale }) => {
            return (
              <>
                {/* X-axis */}
                <line
                  x1={0}
                  x2={width}
                  y1={0}
                  y2={0}
                  stroke="#888"
                  strokeWidth={1}
                  strokeDasharray="4px 8px"
                  style={{ transform: `translate(0, ${yScale(0)}px)` }}
                  className="transition"
                />
                {scaled[activeSeries ?? 0].map((point, i) => (
                  <text
                    key={i}
                    fontSize="var(--font-size-xsmall)"
                    fill="var(--color-3)"
                    textAnchor="middle"
                    x={point.x}
                    y={height - 2}
                  >
                    {incomeChart.data?.[0][i].label}
                  </text>
                ))}
                {scaled.map((s, seriesIndex) => (
                  <g key={seriesIndex}>
                    {s.map((point, i) => (
                      <Fragment key={i}>
                        <circle
                          cx={point.x}
                          cy={point.y}
                          r={6}
                          fill={colors[seriesIndex]}
                          opacity={point.isPreviousYear ? 0.5 : 1}
                          className="transition"
                        />
                        {!point.isPreviousYear && (
                          <text
                            key={i}
                            fontSize="var(--font-size-xsmall)"
                            textAnchor="start"
                            dominantBaseline="central"
                            fill={"#fff"}
                            x={point.x + 12}
                            y={0}
                            style={{
                              transform: `translate(0, ${point.y}px)`,
                            }}
                            className="transition"
                          >
                            {formatNumber(data[seriesIndex][i].y, {
                              shorten: true,
                              significance: 2,
                            }).toLocaleLowerCase()}
                          </text>
                        )}
                      </Fragment>
                    ))}
                  </g>
                ))}
              </>
            );
          }}
        </Chart>
      </div>
      <div
        className="font-size-small margin-top-small"
        style={{
          display: "grid",
          gridTemplateColumns: "140px 140px",
          justifyContent: "center",
        }}
      >
        <ToggleButton
          color={colors[0]}
          active={activeSeries === 0}
          onActive={(isActive) => {
            setActiveSeries(isActive ? 0 : null);
          }}
        >
          Revenue
        </ToggleButton>
        <ToggleButton
          color={colors[1]}
          active={activeSeries === 1}
          onActive={(isActive) => {
            setActiveSeries(isActive ? 1 : null);
          }}
        >
          Net Income
        </ToggleButton>
      </div>
    </div>
  );
}
