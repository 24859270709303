import { Holding } from "../../../portfolio/holding";
import { isAuSource, getPercentAuSource } from "./au-source";
import { SaleSummary } from "./sale-receipt";
import { AU_TAX_RATE, FED_NIIT_RATE, FED_TAX_RATE } from "./tax-rates";

export function getCapitalGain(saleSummaries: SaleSummary[]) {
  const netShortTerm = saleSummaries.reduce((total, summary) => {
    return total + (summary.term === "SHORT" ? summary.capitalGainLoss : 0);
  }, 0);
  const netLongTerm = saleSummaries.reduce((total, summary) => {
    return total + (summary.term === "LONG" ? summary.capitalGainLoss : 0);
  }, 0);

  return {
    netCapitalGain: Math.max(0, netLongTerm + Math.min(0, netShortTerm)),
    netShortTerm,
    netLongTerm,
  };
}

export function getUsTax(summaries: SaleSummary[], incomeHoldings: Holding[]) {
  const capitalGain = getCapitalGain(summaries);
  let ordinaryIncome = 0;
  let investmentIncome = 0;

  incomeHoldings.forEach((holding) => {
    const income = holding.getCostBasis();
    if (holding.tags.includes("dividend") || holding.tags.includes("staking")) {
      investmentIncome += income;
    } else {
      ordinaryIncome += income;
    }
  });

  const incomeTax = (investmentIncome + ordinaryIncome) * FED_TAX_RATE.SHORT;
  const cgt = capitalGain.netCapitalGain * FED_TAX_RATE.LONG;
  const niit =
    (capitalGain.netCapitalGain +
      Math.max(0, capitalGain.netShortTerm) +
      investmentIncome) *
    FED_NIIT_RATE;

  const auTax = summaries.reduce(
    (total, summary) => total + getTaxForAU(summary),
    0
  );
  const auSourceCapitalGain = getCapitalGain(
    summaries.filter((summary) => isAuSource(summary.acquisitionDate))
  );
  // Not sure this is correct
  const appliedForeignTaxCredits = Math.min(
    cgt + Math.max(0, capitalGain.netShortTerm) * FED_TAX_RATE.SHORT,
    Math.min(
      auTax,
      auSourceCapitalGain.netCapitalGain * FED_TAX_RATE.LONG +
        Math.max(0, auSourceCapitalGain.netShortTerm) * FED_TAX_RATE.SHORT
    )
  );

  return {
    total: incomeTax + cgt + niit - appliedForeignTaxCredits,
    incomeTax,
    cgt,
    niit,
    foreignTaxCredits: auTax,
    appliedForeignTaxCredits,
  };
}

// Simplified AU tax calculation. Assumes AU tax is paid in US dollars,
// when in reality the proceeds are converted to AUD at sale, and then
// foreign tax credits are converted back to USD at tax payment/estimate date.
function getTaxForAU(summary: SaleSummary): number {
  const percentAu = getPercentAuSource(summary);
  if (!percentAu) {
    return 0;
  }

  const cgtDiscount = 0.5 * percentAu;
  return summary.capitalGainLoss * AU_TAX_RATE * (1 - cgtDiscount);
}
