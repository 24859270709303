import { css } from "emotion";
import React, { useState } from "react";
import { SuspenseBoundary } from "../../common/with-suspense";
import { InstrumentId } from "../../market/asset-id";
import { Card } from "../card";
import { QuoteChange } from "../quote-change";
import { QuoteView } from "../quote-view";
import { PriceChart, PriceChartRange } from "./price-chart";

const tabStyles = css({
  alignItems: "center",
  color: "rgba(255, 255, 255, 0.5)",
  display: "flex",
  flexDirection: "column",
  fontSize: 18,
  height: "100%",
  justifyContent: "center",
  width: "100%",

  "> :nth-child(2)": {
    fontSize: 16,
    marginTop: 4,
  },
});

const selectedTabStyles = css(tabStyles, {
  background: "#343c44",
  color: `rgba(255, 255, 255, 1)`,
});

export function InstrumentSummaryCard({
  instrumentId,
}: {
  instrumentId: InstrumentId;
}) {
  const [chartRange, setChartRange] = useState<PriceChartRange>("1m");

  return (
    <Card>
      <div
        style={{
          display: "flex",
          fontSize: 24,
          justifyContent: "space-between",
          padding: "24px 24px 0",
        }}
      >
        {instrumentId.symbol}
        <div style={{ fontWeight: 100 }}>
          <QuoteView instrumentId={instrumentId} displayLength="full" />
          <div
            style={{
              fontSize: 16,
              fontWeight: "normal",
              marginTop: 4,
              textAlign: "right",
              width: "100%",
            }}
          >
            <QuoteChange colorize id={instrumentId} />
          </div>
        </div>
      </div>
      <PriceChart id={instrumentId} range={chartRange} />
      <div
        style={{
          alignItems: "center",
          background: "#404854",
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          height: 88,
          justifyItems: "center",
          marginTop: 24,
          overflow: "hidden",
        }}
      >
        <div
          className={chartRange === "1m" ? selectedTabStyles : tabStyles}
          onClick={() => setChartRange("1m")}
        >
          <div>1M</div>
          <div>
            <SuspenseBoundary fallback="-">
              <QuoteChange id={instrumentId} range="1m" colorize />
            </SuspenseBoundary>
          </div>
        </div>
        <div
          className={chartRange === "1y" ? selectedTabStyles : tabStyles}
          onClick={() => setChartRange("1y")}
        >
          <div>1Y</div>
          <div>
            <SuspenseBoundary fallback="-">
              <QuoteChange id={instrumentId} range="1y" colorize />
            </SuspenseBoundary>
          </div>
        </div>
      </div>
    </Card>
  );
}
