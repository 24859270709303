import firebase from "firebase/app";
import {
  Transaction,
  BuyTransaction,
  SellTransaction,
} from "../portfolio/transaction";
import { omit } from "lodash";

export const addTransaction = async ({
  portfolioId,
  transaction,
}: {
  portfolioId: string;
  transaction: Omit<BuyTransaction, "id"> | Omit<SellTransaction, "id">;
}) => {
  const ref = await firebase
    .firestore()
    .collection("portfolios")
    .doc(portfolioId)
    .collection("transactions")
    .add(transaction);

  console.log("Added transaction with id", ref.id);

  return ref.id;
};

export const deleteTransaction = async ({
  portfolioId,
  transactionId,
}: {
  portfolioId: string;
  transactionId: string;
}) => {
  await firebase
    .firestore()
    .collection("portfolios")
    .doc(portfolioId)
    .collection("transactions")
    .doc(transactionId)
    .delete();

  console.log("Deleted transaction with id", transactionId);
};

export const updateTransaction = async ({
  portfolioId,
  transaction,
}: {
  portfolioId: string;
  transaction: Transaction;
}) => {
  await firebase
    .firestore()
    .collection("portfolios")
    .doc(portfolioId)
    .collection("transactions")
    .doc(transaction.id)
    .update(omit(transaction, "id"));

  console.log("Updated transaction with id", transaction.id);
};
