import { Holding } from "../../../portfolio/holding";
import { isAuSource } from "./au-source";
import {
  getSaleSummaryFromReceipt,
  SaleReceipt,
  SaleSummary,
} from "./sale-receipt";
import { getTaxPeriodDates, TaxPeriod } from "./tax-period";

export type Taxables = {
  saleSummaries: SaleSummary[];
  incomeHoldings: Holding[];
};

export function getTaxablesForPeriod(
  taxPeriod: TaxPeriod,
  holdings: Holding[]
) {
  const [taxPeriodStart, taxPeriodEnd] = getTaxPeriodDates(taxPeriod);

  const saleReceipts: SaleReceipt[] = [];
  holdings.forEach((holding) => {
    holding.sells.forEach((sell) => {
      if (sell.date >= taxPeriodStart && sell.date <= taxPeriodEnd) {
        saleReceipts.push({ sell, holding });
      }
    });
  });

  saleReceipts.sort((a, b) => {
    return b.sell.date.localeCompare(a.sell.date);
  });

  const _saleSummaries = saleReceipts.map(getSaleSummaryFromReceipt);
  const saleSummaries =
    taxPeriod.taxCountry === "AU"
      ? _saleSummaries.filter((summary) => isAuSource(summary.acquisitionDate))
      : _saleSummaries;

  const incomeHoldings: Holding[] = [];
  holdings.forEach((holding) => {
    const buyDate = holding.buy.date;
    if (
      holding.isIncome() &&
      buyDate >= taxPeriodStart &&
      buyDate <= taxPeriodEnd
    ) {
      incomeHoldings.push(holding);
    }
  });

  return {
    saleSummaries,
    incomeHoldings,
  };
}
