import { css } from "emotion";
import React, { useState } from "react";
import { isInstrument } from "../../../market/asset-id";
import { Instrument } from "../../../portfolio/instrument";
import { usePortfolio } from "../../use-portfolio";
import { PortfolioView } from "../portfolio-view";
import { TaxPeriodSelector } from "./tax-period-selector";
import { TaxPeriod } from "./tax-period";
import { CapitalGainBreakdown } from "./capital-gain-breakdown";
import { UsTaxBreakdown } from "./us-tax-breakdown";
import { IncomeAnalysis } from "./income-analysis";
import { SaleSummaryRow } from "./sale-summary-row";
import { DownloadIncomeReportButton } from "./download-income-report-button";
import { DownloadCapitalGainsButton } from "./download-capital-gains-button";
import { CaTaxBreakdown } from "./ca-tax-breakdown";
import { TaxUnderpaymentBreakdown } from "./tax-underpayment-breakdown";
import { AuTaxBreakdown } from "./au-tax-breakdown";
import { flatMap } from "lodash";
import { getTaxablesForPeriod } from "./taxables";
import { NetLiquidationValue } from "./net-liquidation-value";

const headerStyles = css`
  display: grid;
  grid-gap: 12px;
  justify-content: stretch;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 18px;
`;

const gridStyles = css`
  display: grid;
  font-size: 18px;
  font-weight: 100;
  grid-template-columns: 1fr;
  padding: 12px;

  > * {
    padding: 10px 12px;
  }
`;

export const PortfolioTaxView: PortfolioView = ({ assetIds }) => {
  const portfolio = usePortfolio();
  const [taxPeriod, setTaxPeriod] = useState<TaxPeriod>({
    currentDate: new Date(),
    taxCountry: "US",
    taxYearOffset: 0,
  });
  const instrumentIds = assetIds.filter(
    (assetId) => isInstrument(assetId) || assetId.type === "stockspot"
  );
  const holdings = flatMap(
    instrumentIds
      .map((instrumentId) => portfolio.getAsset(instrumentId))
      .filter((instrument): instrument is Instrument => instrument != null),
    (instrument) => instrument.holdings
  );
  const taxables = getTaxablesForPeriod(taxPeriod, holdings);
  const { saleSummaries, incomeHoldings } = taxables;

  return (
    <div>
      <NetLiquidationValue holdings={holdings} />
      <TaxPeriodSelector taxPeriod={taxPeriod} setTaxPeriod={setTaxPeriod} />
      <div
        className="padding"
        style={{ display: "flex", flexDirection: "column", gap: 12 }}
      >
        {taxPeriod.taxCountry === "US" ? (
          <>
            <CapitalGainBreakdown saleSummaries={saleSummaries} />
            <IncomeAnalysis incomeHoldings={incomeHoldings} />
            <UsTaxBreakdown
              saleSummaries={saleSummaries}
              incomeHoldings={incomeHoldings}
            />
            <CaTaxBreakdown
              saleSummaries={saleSummaries}
              incomeHoldings={incomeHoldings}
            />
            <TaxUnderpaymentBreakdown
              saleSummaries={saleSummaries}
              incomeHoldings={incomeHoldings}
            />
          </>
        ) : (
          <AuTaxBreakdown saleSummaries={saleSummaries} />
        )}
      </div>
      <div className={headerStyles}>
        <DownloadCapitalGainsButton saleSummaries={saleSummaries} />
        <DownloadIncomeReportButton incomeHoldings={incomeHoldings} />
      </div>
      <div className={gridStyles}>
        {saleSummaries.map((saleSummary) => (
          <SaleSummaryRow key={saleSummary.id} saleSummary={saleSummary} />
        ))}
      </div>
    </div>
  );
};
