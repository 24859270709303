import { groupBy, mapValues, sortBy } from "lodash";
import React from "react";
import { AssetId } from "../../../market/asset-id";
import { Holding } from "../../../portfolio/holding";
import { BreakdownItem, BreakdownView } from "./breakdown";

export function IncomeAnalysis({
  incomeHoldings,
}: {
  incomeHoldings: Holding[];
}) {
  const holdingsByAsset = groupBy(
    incomeHoldings,
    (holding) => holding.instrumentId.key
  );

  const incomeByAsset = mapValues(holdingsByAsset, (holdings) =>
    holdings.reduce((total, holding) => total + holding.getCostBasis(), 0)
  );

  const breakdown: BreakdownItem[] = sortBy(
    Object.keys(incomeByAsset).map((key) => ({
      name: AssetId(key).symbol,
      value: incomeByAsset[key],
    })),
    (breakdownItem) => -breakdownItem.value
  );

  const totalIncome = Object.values(incomeByAsset).reduce(
    (total, income) => total + income,
    0
  );

  breakdown.unshift({ name: "Income", value: totalIncome });

  return <BreakdownView breakdown={breakdown} />;
}
