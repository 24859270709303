import React from "react";
import { AssetId } from "../market/asset-id";
import { usePortfolio } from "./use-portfolio";

export function AssetName({ assetId }: { assetId: AssetId }) {
  const portfolio = usePortfolio();

  return (
    <span>
      {assetId.type === "account"
        ? portfolio.getAsset(assetId)?.name
        : assetId.symbol}
    </span>
  );
}
