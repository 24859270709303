import firebase from "firebase/app";
import { ReplaySubject } from "rxjs";

export type User = { uid: string };

export type Anonymous = "anonymous";

export const currentUser$ = new ReplaySubject<User | Anonymous>(1);

export const signIn = (): Promise<void> => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      if (!result.user) {
        throw new Error("No user after sign-in");
      }
    });
};

export const signOut = (): Promise<void> => {
  return firebase.auth().signOut();
};
