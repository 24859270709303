import { wait } from "./wait";

export const withRetries = <T>(
  producer: () => Promise<T>,
  retries = 3,
  retryDelay = 10000
): Promise<T> => {
  return producer().catch((err: Error) => {
    if (retries === 0) {
      throw err;
    }
    return wait(retryDelay).then(() =>
      withRetries(producer, retries - 1, retryDelay * 2)
    );
  });
};
