"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Observers = void 0;
const NOOP_NOTIFY_IN_BATCH = (fn) => {
    fn();
};
function getNotifyInBatch() {
    try {
        return require("react-dom").unstable_batchedUpdates;
    }
    catch (e) {
        return NOOP_NOTIFY_IN_BATCH;
    }
}
class Observers {
    constructor(notifyInBatch = getNotifyInBatch()) {
        this.observers = [];
        this.notifyInBatch = notifyInBatch;
    }
    notify(value) {
        this.notifyInBatch(() => {
            this.observers.forEach((subscriber) => {
                subscriber(value);
            });
        });
    }
    subscribe(observer) {
        const _observer = (value) => observer(value);
        this.observers.push(_observer);
        return () => {
            this.observers = this.observers.filter((s) => s !== _observer);
        };
    }
}
exports.Observers = Observers;
