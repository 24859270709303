import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";

type Dimensions = {
  height: number;
  width: number;
};

export function Size({
  children,
}: {
  children: (dimensions: Dimensions) => ReactNode;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  useLayoutEffect(() => {
    function measure() {
      window.requestAnimationFrame(() => {
        if (divRef.current) {
          const rect = divRef.current.getBoundingClientRect();
          if (rect.width === 0 && rect.height === 0) {
            setTimeout(() => {
              measure();
            }, 250);
          } else {
            setDimensions({ width: rect.width, height: rect.height });
          }
        }
      });
    }

    measure();
  }, []);

  return (
    <div ref={divRef} style={{ height: "100%", width: "100%" }}>
      {dimensions ? children(dimensions) : null}
    </div>
  );
}
