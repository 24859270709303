import React from "react";
import { usePortfolio } from "../use-portfolio";
import { AccountRecordRow } from "./account-record-row";
import { AccountId } from "../../market/asset-id";
import { Account } from "../../portfolio/account";
import { useModel } from "cosmonaut";
import { ExchangeRatesModel } from "../../market/exchange-rates-model";
import { CurrencyValue } from "../currency-value";
import { SetAllocationButton } from "../set-allocation-button";
import { AllocationDescription } from "../allocation-description";

export function AccountScreen({ id }: { id: AccountId }) {
  const portfolio = usePortfolio();
  const asset = portfolio.getAsset(id) as Account | null;
  const exchangeRates = useModel(ExchangeRatesModel({ baseCurrency: "USD" }));

  if (!asset) {
    return <div className="padding center">No account with id {id.symbol}</div>;
  }

  const value = asset.value / exchangeRates[asset.currency];

  return (
    <div className="sections" style={{ paddingTop: 0 }}>
      <div className="v-grid">
        <div className="v-grid-small margin-top-small color-1 center">
          <div className="font-size-large">{asset.name}</div>
          <div>
            <CurrencyValue value={value} />
          </div>
        </div>
        <div className="v-grid-small">
          <div className="row">
            <label>Allocation</label>
            <AllocationDescription assetId={id} />
          </div>
          <SetAllocationButton assetId={id} />
        </div>
      </div>
      <div>
        <h2>History</h2>
        <div className="v-grid margin-top ">
          {asset.records.map((record) => (
            <AccountRecordRow key={record.id} record={record} asset={asset} />
          ))}
          {asset.records.length === 0 && (
            <div className="font-size-small center">No history yet</div>
          )}
        </div>
      </div>
      <div style={{ height: 6 }}></div>
    </div>
  );
}
