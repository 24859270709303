import { model } from "cosmonaut";
import { url } from "../common/url";
import { getIex } from "./get-iex";

export type KeyStats = {
  companyName: string;
  sharesOutstanding: number;
  employees: number;
  marketcap: number;
  ttmEPS?: number;
  ttmDividendRate?: number;
  nextEarningsDate?: string; // YYYY-MM-DD
  nextDividendDate?: string; // YYYY-MM-DD
};

export const KeyStatsModel = model<KeyStats, { symbol: string }>(
  ({ symbol }) => getIex(url`/stock/${symbol}/stats`),
  {
    key: "KeyStats",
  }
);
