import React, { FC } from "react";
import { useModel } from "cosmonaut";
import { KeyStatsModel } from "../../market/key-stats-model";
import { IncomeStatementsModel } from "../../market/income-statements-model";
import { IncomeChart } from "./income-chart";
import { QuoteModel } from "../../model/quote-model";
import { StockId } from "../../market/asset-id";
import { formatNumber } from "toolbelt";

const placeholder = "-";

export const KeyStatsView: FC<{ id: StockId }> = ({ id }) => {
  const keyStats = useModel(KeyStatsModel({ symbol: id.symbol }), {
    async: true,
  });
  const incomeStatements = useModel(
    IncomeStatementsModel({ symbol: id.symbol }),
    { async: true }
  );
  const quote = useModel(QuoteModel({ id }));

  return (
    <div className="v-grid">
      <h2>Fundamentals</h2>
      <div className="v-grid font-size-small color-1">
        <div className="row">
          <div className="color-2">Market Cap</div>
          {keyStats.data
            ? formatNumber(keyStats.data.marketcap, {
                prefix: "$",
                shorten: true,
                decimals: 1,
              })
            : placeholder}
        </div>
        <div className="row">
          <div className="color-2">P/E Ratio</div>
          <span>
            {keyStats.data
              ? formatNumber(quote.latestPrice / (keyStats.data.ttmEPS ?? 0), {
                  decimals: 1,
                })
              : placeholder}
          </span>
        </div>
        <div className="row">
          <div className="color-2">P/S Ratio</div>
          <span>
            {keyStats.data && incomeStatements.data
              ? formatNumber(
                  keyStats.data.marketcap /
                    incomeStatements.data
                      .slice(0, 4)
                      .reduce((acc, x) => acc + x.totalRevenue, 0),
                  { decimals: 1 }
                )
              : placeholder}
          </span>
        </div>
        <div className="row">
          <div className="color-2">Div Yield</div>
          {keyStats.data
            ? formatNumber(
                (keyStats.data.ttmDividendRate ?? 0) / quote.latestPrice,
                {
                  asPercent: true,
                  decimals: 2,
                  nullifyZero: true,
                }
              )
            : placeholder}
        </div>
      </div>
      <div className="margin-top-small">
        <IncomeChart symbol={id.symbol} />
      </div>
    </div>
  );
};
