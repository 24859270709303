import { useModel } from "cosmonaut";
import React from "react";
import { formatNumber } from "toolbelt";
import { withSuspense } from "../../common/with-suspense";
import { CryptoId } from "../../market/asset-id";
import { CoinInfoModel } from "../../market/coin-info-model";
import { QuoteModel } from "../../model/quote-model";
import { Expandable } from "../expandable";

export const CoinInfoView = withSuspense(
  null,
  function ({ id }: { id: CryptoId }) {
    const allCoinInfo = useModel(CoinInfoModel());
    const quote = useModel(QuoteModel({ id }));
    const coinInfo = allCoinInfo[id.symbol];
    if (!coinInfo) {
      return null;
    }

    return (
      <div className="v-grid font-size-small">
        <h2>Fundamentals</h2>
        <div className="v-grid color-1">
          {coinInfo.totalCoinsMined ? (
            <div className="row">
              <div className="color-2">Market cap</div>
              {formatNumber(coinInfo.totalCoinsMined * quote.latestPrice, {
                prefix: "$",
                shorten: true,
                decimals: 1,
              })}
            </div>
          ) : null}
          {coinInfo.platform ? (
            <div className="row">
              <div className="color-2">Platform</div>
              {coinInfo.platform}
            </div>
          ) : null}
        </div>
        <Expandable
          summary="About"
          detail={
            <div className="font-size-small margin-top-small">
              {coinInfo.description}
            </div>
          }
        />
      </div>
    );
  }
);
