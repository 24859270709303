import React, { Component, ComponentType, ReactNode, Suspense } from "react";

export function withSuspense<P = {}>(
  fallback: NonNullable<ReactNode> | null,
  Component: ComponentType<P>
) {
  return (props: P) => (
    <SuspenseBoundary fallback={fallback}>
      <Component {...props} />
    </SuspenseBoundary>
  );
}

export function SuspenseBoundary({
  fallback,
  children,
}: {
  fallback?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <ErrorBoundary>
      <Suspense fallback={fallback ?? null}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export class ErrorBoundary extends Component {
  state: { error?: any } = {};

  componentDidCatch(error: any) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ color: "var(--color-error)" }}>
          Error: {this.state.error.message ?? ""}
        </div>
      );
    }
    return this.props.children;
  }
}
