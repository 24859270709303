import { useModel } from "cosmonaut";
import { css } from "emotion";
import React from "react";
import { formatNumber } from "toolbelt";
import { InstrumentId } from "../../market/asset-id";
import { QuoteModel } from "../../model/quote-model";
import { Card } from "../card";
import { MoneyView } from "../money-view";
import { usePortfolio } from "../use-portfolio";

const cardStyles = css({
  background: "#9f8465",
  fontSize: 24,
  fontWeight: 100,
  padding: 24,
});

export function InstrumentPositionCard({
  instrumentId,
}: {
  instrumentId: InstrumentId;
}) {
  const portfolio = usePortfolio();
  const asset = portfolio.getAsset(instrumentId);
  const quote = useModel(QuoteModel({ id: instrumentId }));

  if (!asset) {
    return null;
  }

  const value = asset.holdings.reduce((total, holding) => {
    return (
      total +
      holding.getRealizedValue() +
      holding.getUnrealizedValue(quote.latestPrice)
    );
  }, 0);
  const cost = asset.holdings.reduce((total, holding) => {
    return total + holding.getCost();
  }, 0);
  const returnValue = value - cost;

  return (
    <Card className={cardStyles}>
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <MoneyView
          className={css({ fontWeight: "normal" })}
          money={{
            value: String(asset.getValue(quote.latestPrice)),
            currency: "USD",
          }}
        />
        <MoneyView
          money={{
            value: String(returnValue),
            currency: "USD",
          }}
          plusSign
        />
      </div>
      <div
        className={css({
          display: "flex",
          fontSize: 18,
          justifyContent: "space-between",
          marginTop: 4,
          opacity: 0.8,
        })}
      >
        {formatNumber(asset.quantity, {
          shorten: true,
        })}{" "}
        {instrumentId.type === "stock" ? "shares" : instrumentId.symbol}
      </div>
    </Card>
  );
}
