import differenceInDays from "date-fns/differenceInDays";
import parseISO from "date-fns/parseISO";
import { SaleSummary } from "./sale-receipt";

export const AU_DEPARTURE_DATE_STR = "2017-08-31";

export const AU_DEPARTURE_DATE = parseISO(AU_DEPARTURE_DATE_STR);

export function isAuSource(buyDate: string): boolean {
  return buyDate <= AU_DEPARTURE_DATE_STR;
}

export function getPercentAuSource({
  acquisitionDate,
  disposalDate,
}: SaleSummary): number {
  const daysHeld =
    differenceInDays(parseISO(disposalDate), parseISO(acquisitionDate)) + 1;
  const daysHeldInAu = Math.max(
    0,
    differenceInDays(AU_DEPARTURE_DATE, parseISO(acquisitionDate)) + 1
  );
  return daysHeldInAu / daysHeld;
}
