import { url } from "../common/url";
import { cryptoCompareApiKey, iexCloudApiToken } from "./api-tokens";
import { jsonFetch } from "../common/json-fetch";
import { Money } from "./money";

type ApiQuote = {
  lastTradeTime: number;
  latestPrice: number;
  previousClose: number;
};

type BatchApiQuote = {
  [symbol: string]: {
    quote: ApiQuote;
  };
};

export type Quote = {
  latestPrice: number;
  previousClosePrice: number;
  latest: Money;
  previous: Money;
  percentChange: number;
};

const fromApiQuote = (apiQuote: ApiQuote): Quote => ({
  latestPrice: apiQuote.latestPrice,
  previousClosePrice: apiQuote.previousClose,
  latest: {
    value: String(apiQuote.latestPrice),
    currency: "USD",
  },
  previous: {
    value: String(apiQuote.previousClose),
    currency: "USD",
  },
  percentChange:
    (apiQuote.latestPrice - apiQuote.previousClose) / apiQuote.previousClose,
});

export const getQuote = (symbol: string): Promise<Quote> =>
  jsonFetch<ApiQuote>(
    url`https://cloud.iexapis.com/stable/stock/${symbol}/quote?token=${iexCloudApiToken}`
  ).then(fromApiQuote);

export const getQuotes = (
  symbols: string[]
): Promise<{ [symbol: string]: Quote }> =>
  jsonFetch<BatchApiQuote>(
    url`https://cloud.iexapis.com/stable/stock/market/batch?symbols=${symbols.join(
      ","
    )}&types=quote&token=${iexCloudApiToken}`
  ).then((json) => {
    const obj: { [symbol: string]: Quote } = {};
    symbols.forEach((symbol) => {
      obj[symbol] = fromApiQuote(json[symbol].quote);
    });
    return obj;
  });

type ApiCryptoQuote = {
  RAW: {
    [symbol: string]: {
      USD: {
        MKTCAP: number;
        OPEN24HOUR: number;
        OPENDAY: number;
        PRICE: number;
      };
    };
  };
};

export const getCryptoQuotes = (symbols: string[]): Promise<Quote[]> =>
  jsonFetch<ApiCryptoQuote>(
    url`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${symbols.join()}&tsyms=USD`,
    {
      headers: {
        authorization: `Apikey ${cryptoCompareApiKey}`,
      },
    }
  ).then((data) => {
    return symbols.map((symbol) => {
      const quote = data.RAW[symbol].USD;
      return {
        latestPrice: quote.PRICE,
        previousClosePrice: quote.OPEN24HOUR,
        latest: {
          value: String(quote.PRICE),
          currency: "USD",
        },
        previous: {
          value: String(quote.OPEN24HOUR),
          currency: "USD",
        },
        percentChange: (quote.PRICE - quote.OPEN24HOUR) / quote.OPEN24HOUR,
      };
    });
  });
