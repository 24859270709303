"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkInterceptor = void 0;
const react_1 = __importStar(require("react"));
const router_1 = require("./router");
const getInterceptableHref = (e) => {
    const target = e.target;
    // Allow others to handle the click instead
    if (e.defaultPrevented)
        return;
    // Did we click on a link?
    const a = target.closest("a");
    if (!a)
        return;
    // Ignore non-primary mouse buttons
    if (e.button !== 0)
        return;
    // Ignore modifier keys
    if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)
        return;
    // Does the link have an href?
    const href = a.getAttribute("href");
    if (href == null)
        return;
    // Ignore other targets
    if (a.target && a.target !== "_self")
        return;
    // Ignore other protocols
    const url = new URL(href, window.location.origin);
    if (url.protocol !== "http:" && url.protocol !== "https:")
        return;
    // Ignore external URLs
    if (url.origin !== window.location.origin)
        return;
    // Ignore contenteditables
    const contentEditable = target.closest("[contenteditable]");
    if (contentEditable && contentEditable.isContentEditable)
        return;
    return href;
};
/**
 * Intercepts link clicks and directs them through the router
 */
const LinkInterceptor = ({ children }) => {
    const { push } = router_1.useRouter();
    react_1.useEffect(() => {
        function onClick(e) {
            const href = getInterceptableHref(e);
            if (href != null) {
                e.preventDefault();
                push(href);
            }
        }
        document.addEventListener("click", onClick);
        return () => {
            document.removeEventListener("click", onClick);
        };
    }, [push]);
    return react_1.default.createElement(react_1.default.Fragment, null, children);
};
exports.LinkInterceptor = LinkInterceptor;
