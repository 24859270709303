import React, { Suspense } from "react";
import { Authenticate } from "./authenticate";
import { AppLayout } from "./app-layout";
import { SplashScreen } from "./splash-screen";
import { Router } from "router";
import { Screens } from "./screens";
import { FiltersContainer } from "./filters";
import { CosmonautClient, CosmonautProvider } from "cosmonaut";
import { PrivacyContextProvider } from "./privacy-context";

const cosmonaut = new CosmonautClient();

export const App = () => {
  return (
    <Router>
      <CosmonautProvider client={cosmonaut}>
        <Suspense fallback={<SplashScreen />}>
          <Authenticate>
            <PrivacyContextProvider>
              <FiltersContainer.Provider>
                <AppLayout>
                  <Screens />
                </AppLayout>
              </FiltersContainer.Provider>
            </PrivacyContextProvider>
          </Authenticate>
        </Suspense>
      </CosmonautProvider>
    </Router>
  );
};
