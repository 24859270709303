import { Observable } from "rxjs";
import { getLatestObservableValue } from "./get-latest-observable-value";
import { useState, useEffect } from "react";
import { mapValues, Dictionary } from "lodash";

function isObservable(observable: any): observable is Observable<unknown> {
  return typeof observable?.subscribe === "function";
}

export function useObservable<T>(observable: Observable<T>): T;
export function useObservable<T>(observables: Observable<T>[]): T[];
export function useObservable<T>(
  observables: Dictionary<Observable<T>>
): Dictionary<T>;
export function useObservable<T>(
  _observables: Observable<T> | Observable<T>[] | Dictionary<Observable<T>>
): any {
  // Turn single observable into an array of 1
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observables = isObservable(_observables)
    ? [_observables]
    : _observables;
  let values: T[] | Dictionary<T>;
  if (Array.isArray(observables)) {
    values = observables.map((o) => getLatestObservableValue(o));
  } else {
    values = mapValues(observables, (o) => getLatestObservableValue(o));
  }
  // State is only used to trigger a re-render; read from values instead.
  const [, setState] = useState<{}>();
  useEffect(() => {
    const observableArray = Array.isArray(observables)
      ? observables
      : Object.values(observables);
    const subscriptions = observableArray.map((o) => {
      let first = true;
      return o.subscribe((value) => {
        if (first) {
          first = false;
        } else {
          setState({});
        }
      });
    });
    return () => {
      subscriptions.map((s) => s.unsubscribe());
    };
  }, [observables]);
  return isObservable(_observables) ? (values as T[])[0] : values;
}
