"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAge = exports.NO_AGE = void 0;
const parse_age_1 = require("./parse-age");
exports.NO_AGE = Number.MAX_SAFE_INTEGER;
function getAge({ clientAge, modelAge, queryAge, }) {
    const defaultAge = parse_age_1.parseAge(clientAge) || exports.NO_AGE;
    const _modelAge = parse_age_1.parseAge(modelAge) || defaultAge;
    const _queryAge = parse_age_1.parseAge(queryAge) || defaultAge;
    return Math.min(_modelAge, _queryAge);
}
exports.getAge = getAge;
