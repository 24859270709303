"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CosmonautClient = void 0;
const cache_1 = require("./cache");
const value_1 = require("./value");
const memoize_by_1 = require("./memoize-by");
const query_key_1 = require("./query-key");
const age_1 = require("./age");
const scheduler_1 = require("./scheduler");
class CosmonautClient {
    constructor() {
        this.cache = new cache_1.Cache();
        this.scheduler = new scheduler_1.Scheduler(100);
        this.defaultRefreshAge = "1d";
        this.resume = () => { };
        this.loadingValues = {};
        /**
         * Read a query result from the cache only.
         *
         * Unlike get(), this won't automatically make a request if a result value
         * isn't in the cache. Instead, it returns a LoadableValue that can be used
         * to request it.
         */
        this.read = (query) => {
            var _a;
            const value = this.memoizedRead(query, this.cache.cache);
            if (value_1.isErrorValue(value) || value_1.isDataValue(value)) {
                const maxAge = this.getMaxAge(query);
                const age = Date.now() - value.updatedAt;
                if (age >= maxAge) {
                    const cacheContent = {
                        error: value_1.isErrorValue(value) ? {} : this.cache.cache.error,
                        data: {},
                    };
                    const rereadValue = this._read(query, cacheContent);
                    if (value_1.isErrorValue(rereadValue) || value_1.isDataValue(rereadValue)) {
                        return rereadValue;
                    }
                }
                else {
                    return value;
                }
            }
            return ((_a = this.loadingValues[query_key_1.getQueryKey(query)]) !== null && _a !== void 0 ? _a : value_1.LoadableValue(() => this.load(query)));
        };
        /**
         * Run a mutation action
         */
        this.run = (action) => {
            return action.mutation.run(action.params, this);
        };
        this._read = (query, cacheContent) => {
            var _a;
            const cache = new cache_1.Cache(cacheContent);
            return ((_a = query.model.read(query, {
                cache,
                read: (query) => this._read(query, cacheContent),
                write: this.write,
                invalidate: this.invalidate,
            })) !== null && _a !== void 0 ? _a : value_1.LoadableValue(() => this.load(query)));
        };
        this.memoizedRead = memoize_by_1.memoizeBy(query_key_1.getQueryKey, this._read);
        /**
         * Write a query result to the cache.
         */
        this.write = (query, dataOrError) => {
            this.cache.writeInBatch(() => {
                delete this.loadingValues[query_key_1.getQueryKey(query)];
                query.model.write(query, dataOrError, {
                    cache: this.cache,
                    read: (query) => this.read(query),
                    write: this.write,
                    invalidate: this.invalidate,
                });
            });
        };
        /**
         * Delete a query result from the cache.
         *
         * If there is a pending request for that query, it's also re-sent.
         */
        this.invalidate = (query) => {
            const previousValue = this.read(query);
            delete this.loadingValues[query_key_1.getQueryKey(query)];
            this.cache.writeInBatch(() => {
                this.cache.delete(query, "error");
                query.model.invalidate(query, {
                    cache: this.cache,
                    read: (query) => this.read(query),
                    write: this.write,
                    invalidate: this.invalidate,
                });
            });
            // Abort and re-initiate a load if it was previously loading
            if (value_1.isLoadingValue(previousValue)) {
                previousValue.abort();
                this.load(query);
            }
        };
        this.next = (query) => {
            let abort = () => { };
            return [
                new Promise((resolve, reject) => {
                    const unsubscribe = this.cache.subscribe(() => {
                        const value = this.read(query);
                        if (value_1.isDataValue(value) || value_1.isErrorValue(value)) {
                            unsubscribe();
                            if (value_1.isDataValue(value)) {
                                resolve(value.data);
                            }
                            else {
                                reject(value.error);
                            }
                        }
                    });
                    abort = () => {
                        unsubscribe();
                    };
                }),
                abort,
            ];
        };
        this.load = (query) => {
            const queryKey = query_key_1.getQueryKey(query);
            // Dedupe concurrent loads
            const existingLoadingValue = this.loadingValues[queryKey];
            if (existingLoadingValue) {
                return existingLoadingValue;
            }
            // Set loading value
            let resolve = (_) => { };
            let reject = (_) => { };
            let aborts = [];
            const promise = new Promise((_resolve, _reject) => {
                (resolve = _resolve), (reject = _reject);
            });
            const loadingValue = value_1.LoadingValue(promise, () => {
                aborts.forEach((abort) => abort());
            });
            this.loadingValues[queryKey] = loadingValue;
            // Load
            const doLoad = () => {
                aborts.push(query.model.load(query, {
                    cache: this.cache,
                    read: (query) => this.read(query),
                    write: this.write,
                    invalidate: this.invalidate,
                }));
            };
            if (this.pausePromise) {
                this.pausePromise.then(doLoad);
            }
            else {
                doLoad();
            }
            // Wait for query to be satisfied
            const [next] = this.next(query);
            next.then((data) => {
                resolve(data);
                delete this.loadingValues[queryKey];
            }, (error) => {
                reject(error);
                delete this.loadingValues[queryKey];
            });
            return loadingValue;
        };
        if (typeof window !== "undefined") {
            const win = window;
            if (typeof (win.__cosmonautClients === "undefined")) {
                win.__cosmonautClients = [];
            }
            win.__cosmonautClients.push(this);
        }
    }
    /**
     * Get a query result.
     *
     * Returns data from the cache if it's present, otherwise makes a
     * request for it.
     */
    get(query) {
        return value_1.toPromise(this.read(query));
    }
    /**
     * Subscribe to a query.
     *
     * Sends a request if the query is not already loaded, otherwise immediately
     * calls the supplied callback with the current value.
     */
    subscribe(query, onChange) {
        const initialValue = this.read(query);
        let value = value_1.isLoadableValue(initialValue)
            ? initialValue.load()
            : initialValue;
        let cancelExpiry = () => { };
        let cancelRefresh = () => { };
        const schedule = (value) => {
            if (value_1.isResultValue(value)) {
                cancelExpiry();
                cancelRefresh();
                const refreshAge = this.getRefreshAge(query);
                const maxAge = this.getMaxAge(query);
                const age = Date.now() - value.updatedAt;
                if (refreshAge !== age_1.NO_AGE) {
                    cancelRefresh = this.scheduler.setTimeout(() => {
                        this.get(Object.assign(Object.assign({}, query), { maxAge: refreshAge }));
                    }, refreshAge - age);
                }
                if (maxAge !== age_1.NO_AGE) {
                    cancelExpiry = this.scheduler.setTimeout(checkValue, maxAge - age);
                }
            }
        };
        const checkValue = () => {
            const newValue = this.read(query);
            if (newValue !== value) {
                if (value_1.isLoadableValue(newValue)) {
                    value = newValue.load();
                }
                else {
                    value = newValue;
                }
                onChange(value);
            }
            schedule(value);
        };
        onChange(value);
        schedule(value);
        const unsub = this.cache.subscribe(checkValue);
        return () => {
            unsub();
            cancelExpiry();
            cancelRefresh();
        };
    }
    /**
     * Logs the contents of the cache to the console.
     */
    debug(onEveryChange = false) {
        console.log("cache", JSON.stringify(this.cache.cache, undefined, 2));
        if (onEveryChange) {
            this.cache.subscribe(() => {
                this.debug();
            });
        }
    }
    /**
     * Prevents new requests from being made until the returned function is
     * called to resume requests.
     */
    pause() {
        if (!this.pausePromise) {
            this.pausePromise = new Promise((resolve) => {
                this.resume = () => {
                    delete this.pausePromise;
                    this.resume = () => { };
                    resolve();
                };
            });
        }
    }
    getMaxAge(query) {
        return age_1.getAge({
            clientAge: this.defaultMaxAge,
            modelAge: query.model.maxAge,
            queryAge: query.maxAge,
        });
    }
    getRefreshAge(query) {
        return age_1.getAge({
            clientAge: this.defaultRefreshAge,
            modelAge: query.model.refreshAge,
            queryAge: query.refreshAge,
        });
    }
}
exports.CosmonautClient = CosmonautClient;
