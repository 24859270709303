import React from "react";
import { AssetId } from "../../market/asset-id";
import { AccountScreen } from "./account-screen";
import { InstrumentScreen } from "./instrument-screen";

export function AssetScreen({ id }: { id: AssetId }) {
  return id.type === "account" ? (
    <AccountScreen id={id} />
  ) : id.type === "stockspot" ? null : (
    <InstrumentScreen id={id} />
  );
}
