import React, { CSSProperties, ReactNode } from "react";
import { FiChevronsUp, FiPieChart, FiTrendingUp } from "react-icons/fi";
import { IoIosDocument } from "react-icons/io";

export type PortfolioViewType = "allocation" | "performance" | "price" | "tax";

export type Props = {
  selected: PortfolioViewType;
  onSelect: (type: PortfolioViewType) => void;
};

export function PortfolioViewTabs(props: Props) {
  return (
    <div
      style={{
        background: "#404854",
        color: "rgba(255, 255, 255, 0.5)",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        fontSize: 18,
        fontWeight: 100,
      }}
    >
      <Tab type="price" {...props}>
        <FiTrendingUp />
      </Tab>
      <Tab type="allocation" {...props}>
        <FiPieChart />
      </Tab>
      <Tab type="performance" {...props}>
        <FiChevronsUp />
      </Tab>
      <Tab type="tax" {...props}>
        <IoIosDocument />
      </Tab>
    </div>
  );
}

function Tab({
  type,
  children,
  selected,
  onSelect,
}: { type: PortfolioViewType; children: ReactNode } & Props) {
  const baseStyles: CSSProperties = {
    lineHeight: 0,
    padding: 18,
    textAlign: "center",
  };
  const selectedStyles = {
    ...baseStyles,
    background: "#343c44",
    color: `rgba(255, 255, 255, 1)`,
  };

  return (
    <div
      onClick={() => onSelect(type)}
      style={selected === type ? selectedStyles : baseStyles}
    >
      {children}
    </div>
  );
}
