import React, { FC } from "react";
import { css } from "emotion";
import logo from "../logo.svg";

const styles = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr 120px;
  height: 100%;
  place-items: center;

  img {
    max-height: 80%;
    max-width: 80%;
  }
`;

export const SplashScreen: FC = ({ children }) => {
  return (
    <div className={styles}>
      <img src={logo} alt="Stronghold logo" />
      <div className="fade-in">{children}</div>
    </div>
  );
};
