import { useCosmonaut, useModel } from "cosmonaut";
import React from "react";
import { useState } from "react";
import { formatNumber } from "toolbelt";
import { AssetId, toNewIdString } from "../market/asset-id";
import { AllocationsModel, SetAllocation } from "../portfolio/allocations";
import { AssetName } from "./asset-name";
import { MoneyView } from "./money-view";
import { useAssetValue } from "./use-asset-value";
import { usePortfolio } from "./use-portfolio";
import { usePortfolioValue } from "./use-portfolio-value";

export function SetAllocationForm({
  onClose,
  assetId,
}: {
  onClose: () => void;
  assetId: AssetId;
}) {
  const client = useCosmonaut();
  const portfolio = usePortfolio();
  const allocations = useModel(AllocationsModel({ portfolioId: portfolio.id }));
  const currentTarget = allocations[toNewIdString(assetId)];
  const [target, setTarget] = useState(
    currentTarget ? String(currentTarget * 100) : ""
  );
  const targetPercent = Number(target) / 100;
  const isValidTargetPercent =
    !isNaN(targetPercent) && targetPercent < 100 && targetPercent >= 0;
  const portfolioValue = usePortfolioValue();
  const currentPercent = useAssetValue(assetId) / portfolioValue;
  const diffPercent = targetPercent - currentPercent;

  return (
    <form
      className="v-grid"
      onSubmit={(e) => {
        e.preventDefault();
        if (isValidTargetPercent) {
          client.run(
            SetAllocation({
              portfolioId: portfolio.id,
              assetId,
              targetPercent,
            })
          );
          onClose();
        }
      }}
    >
      <h1>
        <AssetName assetId={assetId} /> allocation
      </h1>
      <div className="center">
        {Math.abs(diffPercent / targetPercent) < 0.1 ? (
          "On target"
        ) : (
          <>
            {diffPercent > 0 ? "Buy" : "Sell"}{" "}
            <MoneyView
              money={{
                value: String(Math.abs(diffPercent) * portfolioValue),
                currency: "USD",
              }}
            />{" "}
            to reach target
          </>
        )}
      </div>
      <div className="row">
        <label>Current</label>
        <div>{formatNumber(currentPercent, { asPercent: true })}</div>
      </div>
      <div className="row">
        <label>Target</label>
        <input
          type="number"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          style={{ textAlign: "right", width: "100%" }}
        />
        <span>%</span>
      </div>
      <button type="submit" disabled={!isValidTargetPercent}>
        Update
      </button>
    </form>
  );
}
