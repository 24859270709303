import React from "react";
import { Holding } from "../../../portfolio/holding";
import { BreakdownView } from "./breakdown";
import { getCaTax } from "./ca-tax";
import { SaleSummary } from "./sale-receipt";

export function CaTaxBreakdown({
  saleSummaries,
  incomeHoldings,
}: {
  saleSummaries: SaleSummary[];
  incomeHoldings: Holding[];
}) {
  const caTax = getCaTax(saleSummaries, incomeHoldings);

  return (
    <BreakdownView
      breakdown={[
        { name: "CA Tax", value: caTax.total },
        { name: "Income Tax", value: caTax.incomeTax },
        { name: "Mental Health Tax", value: caTax.mentalHealthTax },
      ]}
    />
  );
}
