import React from "react";
import { Carousel } from "./carousel";
import { Match, useRouter } from "router";
import { PortfolioScreen } from "./portfolio-screen/portfolio-screen";
import { ROUTES } from "../routes";
import { AssetScreen } from "./asset-screen/asset-screen";
import { TransactionScreen } from "./transaction-screen/transaction-screen";
import { AssetId } from "../market/asset-id";

export const Screens = () => {
  const router = useRouter();
  const active = router.matchIn(ROUTES) ?? "HOME";

  return (
    <Carousel
      screens={[
        { key: "HOME", children: <PortfolioScreen /> },
        {
          key: "ASSET",
          children: (
            <Match route={ROUTES.ASSET}>
              {({ params }) => <AssetScreen id={AssetId(params.assetId)} />}
            </Match>
          ),
        },
        { key: "TRANSACTION", children: <TransactionScreen /> },
      ]}
      active={active}
    />
  );
};
