"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAge = void 0;
const suffixMultiplier = {
    s: 1000,
    m: 1000 * 60,
    h: 1000 * 60 * 60,
    d: 1000 * 60 * 60 * 24,
    w: 1000 * 60 * 60 * 24 * 7,
    y: 1000 * 60 * 60 * 24 * 365,
};
function parseAge(age) {
    if (age == null) {
        return undefined;
    }
    if (typeof age === "number") {
        return age;
    }
    const n = Number(age.substr(0, age.length - 1));
    const suffix = age[age.length - 1];
    if (suffix.length === age.length || isNaN(n) || !suffixMultiplier[suffix]) {
        if (process.env.NODE_ENV !== "production") {
            console.warn(`Invalid age string: "${age}"`);
        }
        return undefined;
    }
    return n * suffixMultiplier[suffix];
}
exports.parseAge = parseAge;
