import React, { FC, ReactNode } from "react";
import { css, cx } from "emotion";
import { MdCheck } from "react-icons/md";

const styles = css`
  display: flex;
  align-items: center;

  &.checked {
    color: var(--color-brand);
  }

  input[type="checkbox"] {
    position: absolute;
    left: -10000px;

    + span {
      display: inline-block;
      height: 1em;
      width: 1em;
      background: var(--color-4);
      margin-left: var(--padding-xsmall);
      border-radius: 5px;
      display: flex;
      text-align: center;
      justify-content: center;
      line-height: 1;

      svg {
        display: none;
      }
    }

    &:checked + span {
      background: var(--color-brand);

      svg {
        display: block;
        fill: black;
      }
    }

    span + &:checked {
      color: var(--color-brand);
    }
  }
`;

export const Checkbox: FC<{
  label: ReactNode;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ label, checked, onChange }) => {
  const [internalChecked, setInternalChecked] = React.useState(false);
  const _checked = checked !== undefined ? checked : internalChecked;
  const _onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (checked === undefined) {
        setInternalChecked(e.target.checked);
      }
      if (onChange) {
        onChange(e);
      }
    },
    [checked, onChange]
  );

  return (
    <label
      className={cx(styles, {
        checked: _checked,
      })}
    >
      <span>{label}</span>
      <input type="checkbox" checked={_checked} onChange={_onChange} />
      <span>
        <MdCheck />
      </span>
    </label>
  );
};
