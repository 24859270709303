import React from "react";
import { Holding } from "../../../portfolio/holding";
import { downloadCSV } from "./download-csv";

export function DownloadIncomeReportButton({
  incomeHoldings,
}: {
  incomeHoldings: Holding[];
}) {
  return (
    <button
      onClick={() => {
        downloadCSV(
          "income-report.csv",
          ["Date", "Asset", "Proceeds"],
          incomeHoldings.map((holding) => {
            return [
              holding.buy.date,
              holding.instrumentId.symbol,
              holding.getCostBasis().toFixed(2),
            ];
          })
        );
      }}
    >
      Download Income Report
    </button>
  );
}
