"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelError = void 0;
class ModelError extends Error {
    constructor({ message, status, body, } = {}) {
        super(message);
        this.status = status;
        this.body = body;
    }
    static from(value) {
        if (value == null) {
            return new ModelError();
        }
        if (typeof value === "string") {
            return new ModelError({ message: value });
        }
        if (value instanceof ModelError) {
            return value;
        }
        return new ModelError({
            message: value.message != null ? String(value.message) : undefined,
            status: !isNaN(Number(value.statusCode))
                ? Number(value.statusCode)
                : undefined,
            body: value.body != null ? value.body : undefined,
        });
    }
}
exports.ModelError = ModelError;
