import { get, model, selectModel } from "cosmonaut";
import { format, parseISO } from "date-fns";
import { jsonFetch } from "../common/json-fetch";
import { url } from "../common/url";
import { CryptoId, InstrumentId, StockId } from "./asset-id";
import { getIex } from "./get-iex";
import { QuoteModel } from "../model/quote-model";

export type HistoricalQuote = {
  date: Date;
  close: number;
};

export type HistoricalQuoteRange = "1y";

export const HistoricalQuotes = selectModel(
  (params: {
    id: InstrumentId;
    range: HistoricalQuoteRange;
  }): HistoricalQuote[] => {
    const { id, range } = params;
    const quote = get(QuoteModel({ id }));
    const historical = get(_HistoricalQuotes({ id, range }));
    return historical
      .map((value) => ({
        date: parseISO(value.date),
        close: value.close,
      }))
      .concat([
        {
          date: new Date(),
          close: Number(quote.latest.value),
        },
      ]);
  }
);

export const _HistoricalQuotes = model(
  (params: {
    id: InstrumentId;
    range: HistoricalQuoteRange;
  }): Promise<{ date: string; close: number }[]> => {
    const { id, range } = params;
    return id.type === "crypto"
      ? getCryptoHistoricalQuotes(id)
      : getStockHistoricalQuotes(id, range);
  }
);

function getCryptoHistoricalQuotes(
  id: CryptoId
): Promise<{ date: string; close: number }[]> {
  const _url = url`https://min-api.cryptocompare.com/data/v2/histoday?fsym=${id.symbol}&tsym=USD&limit=365`;
  return jsonFetch(_url).then((data: any) => {
    if (data.Response === "Error") {
      throw new Error(`Response error: ${data.Message}`);
    }
    const quotes = data.Data.Data;
    return quotes.map((quote: any) => {
      const date = new Date(quote.time * 1000);
      return {
        date: format(date, "yyyy-MM-dd"),
        close: quote.close,
      };
    });
  });
}

function getStockHistoricalQuotes(
  id: StockId,
  range: HistoricalQuoteRange
): Promise<{ date: string; close: number }[]> {
  return getIex(url`/stock/${id.symbol}/chart/${range}?chartCloseOnly=true`);
}
