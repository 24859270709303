import { css } from "emotion";
import React from "react";
import { ReactNode } from "react";

const styles = css`
  display: inline-flex;
  gap: 4px;

  > * {
    border: 1px solid var(--color-4);
    border-radius: 3px;
    color: var(--color-2);
    font-size: var(--font-size-tiny);
    padding: 2px 4px;
    text-transform: uppercase;
  }
`;

export function TagList({ children }: { children?: ReactNode }) {
  return <div className={styles}>{children}</div>;
}
