import React, { ReactNode, useState } from "react";
import { css } from "emotion";
import { ExpandIn } from "./expand-in";

const summaryStyles = css`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: var(--padding-small);
`;

export const Expandable = ({
  summary,
  detail,
}: {
  summary: ReactNode | ReactNode[];
  detail?: ReactNode | ReactNode[];
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className={summaryStyles}
        onClick={() => setIsOpen((state) => !state)}
      >
        {summary}
      </div>
      <ExpandIn isOpen={isOpen}>
        <div className={summaryStyles}>{detail}</div>
      </ExpandIn>
    </div>
  );
};
