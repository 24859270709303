import { css } from "emotion";
import React, { ReactNode } from "react";

const buttonStyles = (color: string, active: boolean) => css`
  background: ${active ? color : "transparent"};
  border: 1px solid ${active ? color : "var(--color-5)"};
  color: ${active ? "black" : color};
  padding: 4px 10px;
  text-align: center;

  --border-radius: 8px;

  &:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  & + & {
    border-left: none;
  }

  &:last-of-type {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
`;

export function ToggleButton({
  color = "var(--color-button)",
  active = false,
  onActive = () => {},
  children,
}: {
  color?: string;
  active?: boolean;
  onActive?: (active: boolean) => void;
  children: ReactNode;
}) {
  return (
    <div
      className={buttonStyles(color, active)}
      onClick={() => onActive(!active)}
    >
      {children}
    </div>
  );
}
