import React, { FC, useState } from "react";
import { SubmitButton } from "./submit-button";
import { addAccountRecord } from "../firebase/accounts";
import { usePortfolio } from "./use-portfolio";
import { format } from "date-fns";

export const AddAccountRecordForm: FC<{
  accountId: string;
  onClose: () => void;
}> = ({ accountId, onClose }) => {
  const portfolio = usePortfolio();
  const account = portfolio.getAccount(accountId);
  const [state, setState] = useState(() => ({
    date: format(new Date(), "yyyy-MM-dd"),
    value: "",
  }));
  const getOnChange = (
    name: string,
    transform: (value: string) => string = (x) => x
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = transform(e.currentTarget.value);
    setState((state) => ({ ...state, [name]: value }));
  };

  return (
    <form className="v-grid">
      <h1>Update {account.name}</h1>
      <div className="v-grid-small">
        <label>
          Date
          <input
            type="date"
            value={state.date}
            onChange={getOnChange("date")}
          />
        </label>
        <label>
          Value
          <input
            type="number"
            value={state.value}
            onChange={getOnChange("value")}
          />
        </label>
      </div>
      <SubmitButton
        onClick={(e) => {
          e.preventDefault();
          return addAccountRecord({
            portfolioId: portfolio.id,
            accountRecord: {
              accountId,
              date: state.date,
              value: Number(state.value),
            },
          }).then(onClose);
        }}
      >
        Submit
      </SubmitButton>
    </form>
  );
};
