import firebase from "firebase/app";
import { AccountData, AccountRecord } from "../portfolio/account";

export const addAccount = async ({
  portfolioId,
  account,
}: {
  portfolioId: string;
  account: Omit<AccountData, "id">;
}) => {
  const ref = await firebase
    .firestore()
    .collection("portfolios")
    .doc(portfolioId)
    .collection("accounts")
    .add(account);

  console.log("Added account with id", ref.id);

  return ref.id;
};

export const addAccountRecord = async ({
  portfolioId,
  accountRecord,
}: {
  portfolioId: string;
  accountRecord: Omit<AccountRecord, "id">;
}) => {
  const ref = await firebase
    .firestore()
    .collection("portfolios")
    .doc(portfolioId)
    .collection("accountRecords")
    .add(accountRecord);

  console.log("Added account record with id", ref.id);

  return ref.id;
};
