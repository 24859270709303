import React, { useState } from "react";
import { Card } from "../card";
import { AssetTypeTabs, AssetType } from "./asset-type-tabs";
import { PortfolioPriceView } from "./portfolio-price-view";
import { PortfolioViewTabs, PortfolioViewType } from "./portfolio-view-tabs";
import { usePortfolioAssets } from "./use-portfolio-assets";
import { PortfolioView } from "./portfolio-view";
import { PortfolioPerformanceView } from "./portfolio-performance-view";
import { PortfolioAllocationView } from "./portfolio-allocation-view";
import { PortfolioTaxView } from "./tax/portfolio-tax-view";

const views: Record<PortfolioViewType, PortfolioView> = {
  allocation: PortfolioAllocationView,
  performance: PortfolioPerformanceView,
  price: PortfolioPriceView,
  tax: PortfolioTaxView,
};

export const PortfolioScreen = () => {
  const [assetType, setAssetType] = useState<AssetType>(null);
  const [viewType, setViewType] = useState<PortfolioViewType>("price");
  const assets = usePortfolioAssets(
    viewType === "performance" || viewType === "tax"
  );
  const filteredAssets = assets.filter(
    (assetId) => assetType == null || assetType === assetId.type
  );
  const View = views[viewType];

  return (
    <div style={{ padding: 24, paddingTop: 0 }}>
      <AssetTypeTabs selected={assetType} onSelect={setAssetType} />
      <Card>
        <PortfolioViewTabs selected={viewType} onSelect={setViewType} />
        <View assetIds={filteredAssets} />
      </Card>
    </div>
  );
};
