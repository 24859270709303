import { sumBy } from "lodash";
import { formatNumber } from "toolbelt";
import { Quote } from "../market/get-quote";
import { Holding } from "./holding";

const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365;

export function getRateOfReturnForDisplay(
  holdings: Holding[],
  quotes: Record<string, Quote>
): string {
  const { rate, hasHeldFor1Year } = getRateOfReturn(holdings, quotes);
  const formattedRate = formatNumber(rate, {
    asPercent: true,
    plusSign: true,
    significance: 3,
  });
  return hasHeldFor1Year ? `${formattedRate} p.a.` : formattedRate;
}

export function getRateOfReturn(
  holdings: Holding[],
  quotes: Record<string, Quote>
): { rate: number; hasHeldFor1Year: boolean } {
  let hasHeldFor1Year = false;

  // Calculate weighted CAGR
  const values: { weight: number; cagr: number }[] = [];
  holdings.forEach((holding) => {
    const buyDate = new Date(holding.buy.date);
    holding.sells.forEach((sell) => {
      const cost = holding.buy.price * sell.quantity;
      const years = durationInYears(buyDate, new Date(sell.date));
      if (years >= 1) {
        hasHeldFor1Year = true;
      }
      values.push({
        weight: cost * years,
        cagr: getCAGR(cost, sell.price * sell.quantity, years),
      });
    });
    if (holding.quantity) {
      const cost = holding.buy.price * holding.quantity;
      const years = durationInYears(buyDate, new Date());
      if (years >= 1) {
        hasHeldFor1Year = true;
      }
      values.push({
        weight: cost * years,
        cagr: getCAGR(
          cost,
          holding.quantity * quotes[holding.instrumentId.key].latestPrice,
          years
        ),
      });
    }
  });

  if (!hasHeldFor1Year) {
    // Calculate a simple return %
    const value = sumBy(
      holdings,
      (holding) =>
        holding.getRealizedValue() +
        holding.getUnrealizedValue(quotes[holding.instrumentId.key].latestPrice)
    );
    const cost = sumBy(holdings, (holding) => holding.getCost());
    return {
      rate: value / cost - 1,
      hasHeldFor1Year,
    };
  }

  const totalWeight = values.reduce((total, value) => total + value.weight, 0);
  const weightedCAGR =
    values.reduce((total, item) => total + item.cagr * item.weight, 0) /
    totalWeight;
  return { rate: weightedCAGR, hasHeldFor1Year };
}

function getCAGR(startValue: number, endValue: number, years: number): number {
  if (years < 1) {
    return (endValue - startValue) / startValue;
  }
  return Math.pow(endValue / startValue, 1 / years) - 1;
}

function durationInYears(startDate: Date, endDate: Date): number {
  return (endDate.getTime() - startDate.getTime()) / YEAR_IN_MS;
}
