import { useRef, useEffect, RefObject } from "react";

export const useIsMounted = (): RefObject<boolean> => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
