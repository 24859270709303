import { useEffect, useState } from "react";

export function useDelay(id: string, ms: number) {
  const [isDelayed, setIsDelayed] = useState(true);
  useEffect(() => {
    let isActive = true;
    setTimeout(() => {
      if (isActive) {
        setIsDelayed(false);
      }
    }, ms);
    return () => {
      isActive = false;
    };
  }, [ms]);
  return isDelayed;
}
