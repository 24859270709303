import { Observable } from "rxjs";
import { take } from "rxjs/operators";

/**
 * Gets the last value emitted synchronously by subscribing to the observable.
 * If no values are synchronously emitted, a Promise is thrown that resolves
 * with the next emitted value.
 */
export const getLatestObservableValue = <T>(observable: Observable<T>): T => {
  let value: T | undefined;
  const subscription = observable.subscribe((_value) => {
    value = _value;
  });
  subscription.unsubscribe();
  if (value === undefined) {
    console.log("Suspending");
    throw observable.pipe(take(1)).toPromise();
  }
  return value;
};
