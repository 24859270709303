import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

export type PrivacyContextValue = {
  isShielded: boolean;
  shield: (isShielded: boolean) => void;
};

const PrivacyContext = createContext<PrivacyContextValue>({
  isShielded: false,
  shield: () => {
    throw new Error("Call to shield() must be wrapped in a <PrivacyContext/>");
  },
});

export const PrivacyContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isShielded, shield] = useState(false);
  const contextValue = useMemo(
    () => ({
      isShielded,
      shield,
    }),
    [isShielded, shield]
  );

  return (
    <PrivacyContext.Provider value={contextValue}>
      {children}
    </PrivacyContext.Provider>
  );
};

export function usePrivacy() {
  return useContext(PrivacyContext);
}
