import { get, selectModel, useModel } from "cosmonaut";
import { sub } from "date-fns";
import { css } from "emotion";
import React, { FC } from "react";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { formatNumber } from "toolbelt";
import { InstrumentId } from "../market/asset-id";
import { HistoricalQuotes } from "../market/historical-quotes";
import { QuoteModel } from "../model/quote-model";

const styles = css`
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 2px;
    width: 0.8em;
  }
`;

export type QuoteChangeRange = "1d" | "1m" | "1y";

const QuoteChangeModel = selectModel(
  ({ id, range }: { id: InstrumentId; range: QuoteChangeRange }) => {
    if (range === "1d") {
      return get(QuoteModel({ id })).percentChange;
    }
    const quotes = get(HistoricalQuotes({ id, range: "1y" }));
    const latestQuote = quotes[quotes.length - 1];
    const endDate = latestQuote.date;
    const startDate =
      range === "1m" ? sub(endDate, { months: 1 }) : quotes[0].date;
    const startQuote =
      quotes.find((q) => q.date >= startDate && q.close > 0) ?? latestQuote;
    return (latestQuote.close - startQuote.close) / startQuote.close;
  }
);

export const QuoteChange: FC<{
  id: InstrumentId;
  range?: QuoteChangeRange;
  colorize?: boolean;
}> = ({ id, range = "1d", colorize }) => {
  const percentChange = useModel(QuoteChangeModel({ id, range }));
  const color = percentChange >= 0 ? "var(--color-up)" : "var(--color-down)";

  return (
    <div className={styles}>
      {percentChange >= 0 ? (
        <AiFillCaretUp style={{ color }} />
      ) : (
        <AiFillCaretDown style={{ color }} />
      )}
      <span style={colorize ? { color } : undefined}>
        {formatNumber(Math.abs(percentChange), {
          asPercent: true,
          decimals: Math.abs(percentChange) >= 0.095 ? 0 : 1,
          shorten: true,
        })}
      </span>
    </div>
  );
};
