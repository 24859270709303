import React from "react";
import { useDialog } from "../use-dialog";
import { deleteTransaction } from "../../firebase/transactions";

export const DeleteTransactionButton = ({
  portfolioId,
  transactionId,
}: {
  portfolioId: string;
  transactionId: string;
}) => {
  const [dialog, openDialog] = useDialog<void, boolean>(({ onClose }) => (
    <div className="v-grid">
      <p>Are you sure you want to delete this transaction?</p>
      <div className="v-grid-small">
        <button onClick={() => onClose(true)}>Yes</button>
        <button onClick={() => onClose(false)}>No</button>
      </div>
    </div>
  ));

  return (
    <>
      <button
        onClick={() => {
          openDialog().then((confirm) => {
            if (confirm) {
              deleteTransaction({ portfolioId, transactionId });
            }
          });
        }}
      >
        Delete
      </button>
      {dialog}
    </>
  );
};
