import React from "react";
import ReactDOM from "react-dom";
import { App } from "./view/app";
import { firebaseInit } from "./firebase/firebase-init";
import "./index.css";
import "./classes.css";
import { enableMapSet } from "immer";
import { install } from "./installer/installer";

enableMapSet();
firebaseInit();

ReactDOM.render(<App />, document.getElementById("root"));

install();
