import React, { FC, ReactNode } from "react";
import { css } from "emotion";
import { ErrorBoundary } from "./error-boundary";

const styles = css`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.2s all;
    overflow: auto;
  }
`;

const offscreenLeftStyles = css`
  transform: translate3d(max(-100%, -250px), 0, 0);
  opacity: 0;
  pointer-events: none;
`;

const offscreenRightStyles = css`
  transform: translate3d(min(100%, 250px), 0, 0);
  opacity: 0;
  pointer-events: none;
`;

const onscreenStyles = css`
  transform: translate3d(0, 0, 0);
  opacity: 1;
  pointer-events: auto;
`;

export type Screen = {
  key: string;
  children: ReactNode;
};

export const Carousel: FC<{
  screens: Screen[];
  active: string;
}> = ({ screens, active }) => {
  const activeIndex = screens.findIndex((screen) => screen.key === active);

  return (
    <div className={styles}>
      {screens.map((screen, index) => (
        <div
          key={index}
          className={
            index < activeIndex
              ? offscreenLeftStyles
              : index > activeIndex
              ? offscreenRightStyles
              : onscreenStyles
          }
          data-testid={index === activeIndex ? "active-screen" : ""}
        >
          <ErrorBoundary key={index}>{screen.children}</ErrorBoundary>
        </div>
      ))}
    </div>
  );
};
