import React, { FC, ReactElement } from "react";
import { css, cx } from "emotion";
import { UserModel } from "../firebase/user";
import { useModel } from "cosmonaut";
import {
  exchangeMoney,
  ExchangeRatesModel,
} from "../market/exchange-rates-model";
import { Money } from "../market/money";
import { usePrivacy } from "./privacy-context";
import { formatNumber } from "toolbelt";

const styles = css`
  white-space: nowrap;
`;

export type DisplayLength = "full" | "short" | "mini";

export const MoneyView: FC<{
  money: Money;
  displayOriginalCurrency?: boolean;
  plusSign?: boolean;
  displayLength?: DisplayLength;
  whenPrivate?: ReactElement | null;
  className?: string;
}> = ({
  money,
  displayOriginalCurrency,
  plusSign,
  displayLength = "mini",
  whenPrivate,
  className,
}) => {
  const { isShielded } = usePrivacy();
  const {
    user: { displayCurrency: _displayCurrency },
    exchangeRates,
  } = useModel({
    user: UserModel(),
    exchangeRates: ExchangeRatesModel({ baseCurrency: "USD" }),
  });

  if (whenPrivate !== undefined && isShielded) {
    return whenPrivate;
  }

  const displayCurrency = _displayCurrency ?? "USD";
  const exchangedMoney = displayOriginalCurrency
    ? money
    : exchangeMoney(exchangeRates, money, displayCurrency);
  const value = Number(exchangedMoney.value);
  const integerDigits = Math.floor(Math.log10(Math.abs(value))) + 1;
  const numberString = formatNumber(Math.abs(value), {
    significance:
      displayLength === "mini"
        ? // E.g. 1.21K
          3
        : displayLength === "short"
        ? // E.g. 1,240
          Math.max(integerDigits, value < 100 && value >= 10 ? 4 : 3)
        : // E.g. 1,240.95
          Math.max(3, integerDigits + 2),
    shorten: displayLength === "mini",
  });
  const sign = value < 0 ? "−" : plusSign ? "+" : "";

  return (
    <span className={cx(styles, className)}>
      <span>{`${sign}$${numberString}`}</span>
      {displayOriginalCurrency && displayCurrency !== money.currency
        ? ` ${money.currency}`
        : null}
    </span>
  );
};
